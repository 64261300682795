<template>
  <InputFieldForm
    :id="id"
    :label="label"
    :icon="icon"
    :label-hidded="labelHidden"
  >
    <AutoComplete
      v-if="options?.length && !disabled"
      :id="id"
      v-model="model"
      :options="filteredOptions"
      :option-label="'name'"
      :placeholder="placeholder"
      :disabled="disabled"
      @search="search"
      @item-select="onChange"
    >
      <template
        #option="{ option }"
      >
        <span>
          <b v-if="option.isNew">Add</b>
          {{ option.name }}
        </span>
      </template>
    </AutoComplete>
    <input
      v-else
      v-model="model"
      type="text"
      class="form-control"
      :placeholder="placeholder"
      :disabled="disabled"
    >
  </InputFieldForm>
</template>

<script lang="ts" setup>
import InputFieldForm from '@/VueComponents/SharedComponents/InputFields/InputFieldForm.vue';
import { computed, ref, watch } from 'vue';
import AutoComplete from '@/VueComponents/SharedComponents/AutoComplete.vue';

const props = defineProps<{
  id: string,
  label?: string,
  labelHidden?: boolean,
  options?: string[],
  placeholder?: string,
  icon?: string,
  disabled?: boolean
}>();

const emit = defineEmits(['change']);

const model = defineModel<string>();

if (!props.id) {
  console.error(`Unique 'id' should be provided.`);
}

const autoCompleteOptions = computed(() => {
  if (!props.options?.length) {
    return [];
  }

  return props.options.map(o => {
    return {
      name: o,
      value: o,
      isNew: false
    };
  });
});

const filteredOptions = ref(autoCompleteOptions.value);
const selectedOption = ref(setSelectedOption(model.value));

watch(model, newValue => {
  selectedOption.value = setSelectedOption(newValue);
});


function search(event) {
  filteredOptions.value = [];

  if (!event.query.trim().length) {
    filteredOptions.value = [...autoCompleteOptions.value];
  } else {
    const foundOption = autoCompleteOptions.value.find(o => o.value.toLowerCase() === event.query.toLowerCase());
    if (!foundOption) {
      filteredOptions.value = [{ name: event.query, value: event.query, isNew: true }];
    }

    filteredOptions.value.push(...autoCompleteOptions.value.filter(a => {
      return a.name.toLowerCase().includes(event.query.toLowerCase());
    }));
  }
}

function onChange(newSelectedOption) {
  model.value = newSelectedOption.value.value;
  emit('change', newSelectedOption.value.value);
}

function setSelectedOption(value: string | undefined) {
  if (!value) {
    return null;
  }

  return filteredOptions.value.find(o => o.value === model.value);
}
</script>
<style lang="scss" scoped>
@import "sass/site/_colours.scss";

:deep(.p-autocomplete input.p-inputtext) {
  color: $input-text-color;
}

:deep(.has-icon .p-autocomplete input.p-inputtext) {
  padding-left: 0;
}
</style>