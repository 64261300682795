<template>
  <span
    v-if="showLocalisedAccountLabel"
    class="logo-caption"
  >{{ $localize(accountTypeStringKey) }}</span>
  <span
    v-if="showDefaultLabel"
    class="logo-caption"
  >Portal</span>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import AccountSpecificLabelsProvider from '@/Utils/accountSpecificLabelsProvider';
import PortalSettingsProvider from '@/Utils/portalSettingsProvider';
import { PortalStartupErrorType } from '@/Types/Enums/portalStartupErrorType';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';

const showLocalisedAccountLabel = ref(false);
const showDefaultLabel = ref(false);
const accountTypeStringKey = ref('');
const contextData = useContextDataStore();

onMounted(() => {
  let accountLabel = 'AccountType';
  const accountType = PortalSettingsProvider.getAccountType();
  if (accountType === undefined || accountType === null) {
    accountLabel = 'Portal';
  }
  accountTypeStringKey.value = AccountSpecificLabelsProvider.getAccountLabelKey(accountLabel);

  // In case if the page loading was interrupted by an error
  if (contextData.portalStartupError === PortalStartupErrorType.None) {
    showLocalisedAccountLabel.value = true;
  } else {
    showDefaultLabel.value = true;
  }
});
</script>