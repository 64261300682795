import { Component } from 'vue';

export class MyAccountPageTabManager {
  private readonly tabComponents: any;
  private readonly validations: any;

  constructor(options: MyAccountPageTabManagerOption[]) {
    this.tabComponents = {};
    this.validations = {};

    for (const opt of options) {
      this.tabComponents[opt.tabName] = opt.component;
      this.validations[opt.tabName] = opt.validationRules;
    }
  }

  public resolveComponent(tabName: string) {
    return this.tabComponents[tabName];
  }

  public resolveValidationRules(tabName: string) {
    return this.validations[tabName];
  }
}

export interface MyAccountPageTabManagerOption {
  tabName: string;
  component: Component;
  validationRules: any;
}