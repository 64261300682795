<template>
  <div
    v-sticky-element
    class="request-header"
  >
    <div class="request-header__item request-header__item--title">
      <h2
        class="request-header__title"
      >
        {{ request.referenceNumber }}
      </h2>
      <div>{{ request.requestStatusForDisplay }}</div>
    </div>

    <div
      v-if="request.requestPriorityForDisplay"
      class="request-header__item request-header__item--secondary"
    >
      <div
        class="request-header__label"
      >
        {{ $localize('Priority') }}
      </div>
      <div>{{ request.requestPriorityForDisplay }}</div>
    </div>

    <div
      v-if="request.allowedFeatures.discussionMessaging"
      class="request-header__item request-header__item--secondary"
    >
      <div class="request-header__label">
        {{ $localize('Messages') }}
      </div>
      <div>{{ discussionMessageCount }}</div>
    </div>

    <div
      class="request-header__item--buttons"
      :class="{ 'request-header__item': !request.hasResponse }"
    >
      <div
        :class="{ 'request-details__divider-button-container' : !request.hasResponse }"
      >
        <VueButton
          :type="VueButtonTypes.default"
          :disabled="disableButtons"
          @click="emit('createReport')"
        >
          <span
            class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block"
            aria-hidden="true"
          >{{ $localize('CreateReportShort') }}</span>
          <span
            class="hidden-xs hidden-sm hidden-md"
            aria-hidden="true"
          >{{ $localize('CreateReport') }}</span>
          <span class="sr-only">{{ $localize('CreateReport') }}</span>
        </VueButton>
      </div>
      <div
        v-if="!request.hasResponse"
        class="request-header__divider-button-container"
      >
        <VueButton
          :type="VueButtonTypes.default"
          :aria-label="$localize('RejectRequest')"
          :loading="rejecting"
          :disabled="disableButtons"
          @click="emit('rejectRequest')"
        >
          <span
            class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block"
            aria-hidden="true"
          >{{ $localize('RejectRequestShort') }}</span>
          <span
            class="hidden-xs hidden-sm hidden-md"
            aria-hidden="true"
          >{{ $localize('RejectRequest') }}</span>
          <span
            class="sr-only"
          >{{ $localize('RejectRequest') }}</span>
        </VueButton>
      </div>
      <div v-if="!request.hasResponse">
        <VueButton
          :aria-label="$localize('SaveRequest')"
          :type="VueButtonTypes.primary"
          :loading="saving"
          class="btn-outline"
          aria-describedby="errorValidationMessages"
          :disabled="disableButtons"
          @click="emit('saveRequest')"
        >
          <span
            class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block"
            aria-hidden="true"
          >{{ $localize('SaveRequestShort') }}</span>
          <span
            class="hidden-xs hidden-sm hidden-md"
          >{{ $localize('SaveRequest') }}</span>
          <span
            class="sr-only"
          >{{ $localize('SaveRequest') }}</span>
        </VueButton>
        <VueButton
          :loading="submitting"
          class="ml-3"
          :type="VueButtonTypes.primary"
          aria-describedby="errorValidationMessages"
          :aria-label="$localize('SubmitResponse')"
          :disabled="disableButtons"
          @click="emit('submitResponse')"
        >
          <span
            class="visible-xs-inline-block visible-sm-inline-block visible-md-inline-block"
            aria-hidden="true"
          >{{ $localize('SubmitResponseShort') }}</span>
          <span
            class="hidden-xs hidden-sm hidden-md"
            aria-hidden="true"
          >{{ $localize('SubmitResponse') }}</span>
          <span
            class="sr-only"
          >{{ $localize('SubmitResponse') }}</span>
        </VueButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Request from '@/Types/request';
import VueButton from '@/VueComponents/SharedComponents/Buttons/VueButton.vue';
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';
import { computed } from 'vue';

const emit = defineEmits(['createReport', 'rejectRequest', 'saveRequest', 'submitResponse']);

const props = defineProps<{
  request: Request,
  discussionMessageCount: number,
  rejecting?: boolean,
  saving?: boolean,
  submitting?: boolean,
  disabled?: boolean
}>();

const disableButtons = computed(() => props.disabled || props.saving || props.submitting || props.rejecting);
</script>
<style scoped>
</style>