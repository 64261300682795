<template>
  <div
    id="preloader"
    class="col-md-6 col-md-offset-3 text-center"
  >
    <br>
    <br>
    <br>
    <div class="cbp-loader" />
    <h3>{{ $localize('RetrievingData') }}</h3>
  </div>
</template>

<script setup lang="ts">
</script>
