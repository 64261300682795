<script setup lang="ts">
import { reactive, ref } from 'vue';
import { InitialFilterCriteriaDto } from '@/VueComponents/Filter/Dtos/initialFilterCriteriaDto';
import { FilterItemDto } from '@/VueComponents/Filter/Dtos/filterItemDto';
import { FilterItemValueDto } from '@/VueComponents/Filter/Dtos/filterItemValueDto';
import { FilterItem } from '@/VueComponents/Filter/Models/filterItem';
import localStorageHelper from '@/Utils/localStorageHelper';
import { LocalStorageKeyType } from '@/Types/Enums/localStorageKeyType';
import { FilterContentType } from '@/Types/Enums/filterContentType';
import { FilterItemValue } from '@/VueComponents/Filter/Models/filterItemValue';
import VueCheckBoxFilter from '@/VueComponents/Filter/VueCheckBoxFilter';
import VueTextFilter from '@/VueComponents/Filter/VueTextFilter';

const props = defineProps<{
    initialFilter: InitialFilterCriteriaDto,
    refineByTooltip: string,
    localStorageKey: LocalStorageKeyType
}>();

const emit = defineEmits(['filterChangeHandler']);

const filterItems = reactive([]);
let selectedFilterIsAvailable = false;
const isFilterReady = ref(false);

const filterValueChangeHandler = (): void => {
  saveToLocalStorage();
  // calls the filter host's handler
  emit('filterChangeHandler');
};

const defaultFilterCriteriaItems = mapItemsToFilterCriteria(props.initialFilter.defaultFilterCriteria, filterValueChangeHandler);
const initialFilterItems = mapItemsToFilterCriteria(props.initialFilter.initialFilterCriteria, filterValueChangeHandler);
const persistedFilterCriteriaItems = getPersistedFilterCriteriaFromStorage(filterValueChangeHandler);
if (persistedFilterCriteriaItems.length) {
  syncItemValues(initialFilterItems, persistedFilterCriteriaItems);
  selectedFilterIsAvailable = true;
}
Object.assign(filterItems, initialFilterItems);

const updateFilterCriteria = (filterCriteriaItems: FilterItemDto[]): void => {
  mergeReceivedItems(filterItems, filterCriteriaItems);
  selectedFilterIsAvailable = true;
  isFilterReady.value = true;
};

const applyDefaultFilterCriteria = (): void => {
  resetFilter();
  syncItemValues(filterItems, defaultFilterCriteriaItems);
  saveToLocalStorage();
  selectedFilterIsAvailable = true;
};

const getSelectedFilterCriteria = (): FilterItemDto[] => {
  if (!selectedFilterIsAvailable) {
    applyDefaultFilterCriteria();
  }

  const selectedFilterCriteriaItems = filterItems.reduce((accFilterItems, filterItem) => {
    if (filterItem.type === FilterContentType.text) {
      const filterItemDto = new FilterItemDto({
        name: filterItem.name,
        values: filterItem.values
      } as unknown as FilterItemDto);
      accFilterItems.push(filterItemDto);
    } else {
      const selectedItemValues = getSelectedItemValues(filterItem);
      if (selectedItemValues.length > 0) {
        const filterItemDto = new FilterItemDto({
          name: filterItem.name,
          values: selectedItemValues
        } as FilterItemDto);
        accFilterItems.push(filterItemDto);
      }
    }
    return accFilterItems;
  }, []);
  return selectedFilterCriteriaItems;
};

const resetFilter = (): void => {
  filterItems.forEach(item => item.resetCheckedValues());
};

defineExpose({
  getSelectedFilterCriteria,
  updateFilterCriteria,
  applyDefaultFilterCriteria
});

function saveToLocalStorage(): void {
  if (props.localStorageKey) {
    const serialisedfilter = JSON.stringify(filterItems);
    localStorageHelper.setAuthUserSessionValue(props.localStorageKey, serialisedfilter);
  }
}
function getPersistedFilterCriteriaFromStorage(filterValueChangeHandler: () => void): FilterItem[] {
  const persistedFilterCriteria = props.localStorageKey ? localStorageHelper.getAuthUserSessionValue(props.localStorageKey) : null;

  if (!persistedFilterCriteria) {
    return [];
  }
  const serializedItems = JSON.parse(persistedFilterCriteria);
  return mapItemsToFilterCriteria(serializedItems, filterValueChangeHandler);
}

function mapItemsToFilterCriteria(filterItems: FilterItemDto[], filterValueChangeHandler: () => void): FilterItem[] {
  const items = filterItems.map((item: FilterItemDto): FilterItem => {
    const isExpandedFilter = true;
    return new FilterItem(item, isExpandedFilter, filterValueChangeHandler);
  });
  items.sort((a, b) => (a.position - b.position));
  return items;
}

function syncItemValues(itemsToUpdate: FilterItem[], selectedItems: FilterItem[]): void {
  itemsToUpdate.forEach(itemToUpdate => {
    const selectedItem = selectedItems.find(selectedItem => {
      return itemToUpdate.name === selectedItem.name;
    });
    if (selectedItem) {
      if (selectedItem.type === FilterContentType.text) {
        itemToUpdate.syncTextValues(selectedItem.values);
      } else {
        itemToUpdate.syncCheckedValues(selectedItem.values);
      }
    }
  });
}

function mergeReceivedItems(existedItems: FilterItem[], receivedItems: FilterItemDto[]): void {
  existedItems.forEach((existedItem: FilterItem) => {
    const receivedItem = receivedItems.find(receivedItem => {
      return receivedItem.name === existedItem.name;
    });
    if (receivedItem) {
      existedItem.mergeValues(existedItem.values, receivedItem.values, filterValueChangeHandler);
    }
  });
}

function getSelectedItemValues(filterItem: FilterItem): FilterItemValueDto[] {

  const selectedItemValues = filterItem.values.reduce(
      (accFilterItemValues: FilterItemValueDto[], filterItemValue: FilterItemValue): FilterItemValueDto[] => {
        if (filterItemValue.getLastCheckedValue()) {
          const filterItemValueDto = new FilterItemValueDto(
              filterItemValue.value,
              filterItemValue.title,
              filterItemValue.titleKey,
              filterItemValue.type,
              filterItemValue.getLastCheckedValue(),
              filterItemValue.counter.value,
              filterItemValue.position);
          accFilterItemValues.push(filterItemValueDto);
        }
        return accFilterItemValues;
      }, []);

  return selectedItemValues;
}
</script>

<template>
  <div class="filter-panel">
    <ul
      v-if="isFilterReady"
      class="filter-panel__list--no-bullet"
    >
      <li
        v-for="filter in filterItems"
        :key="filter.name"
        class="mt-3"
      >
        <a
          class="filter-panel__item"
          :class="{'collapsed': !filter.isExpanded}"
          :href="'#filter-collapse-' + filter.name"
          :aria-expanded="String(filter.isExpanded)"
          @click.prevent.stop="filter.toggleIsExpanded"
        >
          <span>{{ filter.title }}</span>
          <span
            v-if="filter.name === 'RefineBy'"
            class="ml-1"
          >
            <i
              v-if="refineByTooltip"
              v-tooltip.top="{
                class: 'filter-panel__tooltip',
                value: refineByTooltip,
                pt: {
                  text: 'filter-panel__tooltip-text',
                  arrow: 'filter-panel__tooltip-arrow'
                },
                showDelay: 600
              }"
              class="fas fa-question-circle filter-panel__item-help"
            />
          </span>
        </a>
        <vue-text-filter
          v-if="filter.type ==='Text'"
          :filter-item-values="filter.values"
          :name="filter.name"
          :is-expanded="filter.isExpanded"
        />

        <vue-check-box-filter
          v-if="filter.type!=='Text'"
          :filter-item-values="filter.values"
          :name="filter.name"
          :is-expanded="filter.isExpanded"
        />
      </li>
    </ul>
  </div>
</template>
<style>
</style>