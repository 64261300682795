import FileModel from '@/VueComponents/FileUploadSelector/models/fileModel';
import FolderModel from '@/VueComponents/FileUploadSelector/models/folderModel';

export default class FileSelectorMapper {

  public static mapFileToFileModel(file: File): FileModel {
    return FileModel.fromFile(file);
  }

  public static mapFilesToFolderModel(files: File[]): FolderModel {
    return FolderModel.fromFiles(files);
  }

  public static async mapFileEntryToFileModel(file: FileSystemFileEntry): Promise<FileModel> {
    return new FileModel(
      file.name,
      file.fullPath.substring(1, file.fullPath.length),
      await this.getFileFromFileEntry(file));
  }

  public static async mapDirectoryEntryToFolderModel(directory: FileSystemDirectoryEntry): Promise<FolderModel> {
    const fileEntries = await this.getFileEntriesFromDirectoryEntry(directory);
    const fileModels = await Promise.all(fileEntries.map(f => this.mapFileEntryToFileModel(f)));

    return new FolderModel(directory.name, fileModels);
  }

  public static getFileFromFileEntry(file: FileSystemFileEntry): Promise<File> {
    return new Promise<File>((resolve, reject) => file.file(resolve, reject));
  }

  public static async getFileEntriesFromDirectoryEntry(directory: FileSystemDirectoryEntry): Promise<FileSystemFileEntry[]> {
    const dirReader = directory.createReader();
    const result: FileSystemFileEntry[] = [];

    let entries: FileSystemEntry[] = [];
    do {
      entries = await new Promise((resolve, reject) => {
        dirReader.readEntries(resolve, reject);
      });

      for (const entry of entries) {
        if (entry.isDirectory) {
          result.push(...await this.getFileEntriesFromDirectoryEntry(entry as FileSystemDirectoryEntry));
        } else {
          result.push(entry as FileSystemFileEntry);
        }
      }
    } while (entries.length);

    return result;
  }
}