<template>
  <div>
    <h2>{{ $localize('RegistrationUnavailableTitle') }}</h2>
    <p class="lead">
      {{ $localize('RegistrationUnavailableLeadText') }}
    </p>
    <p>{{ $localize('RegistrationUnavailableParagraph1') }}</p>
    <a
      href="/"
      class="btn btn-primary"
      role="button"
    >{{ $localize('Login') }}</a>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>