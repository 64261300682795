import DualListBoxIconDetails from '@/VueComponents/DualListBox/types/dualListBoxIconDetails';

export default class DualListBoxItem {
  constructor(
    public id: string,
    public name: string,
    public isChecked: boolean,
    public isPreRegistered: boolean,
    public iconDetails: DualListBoxIconDetails | null = null
  ) {}
}