<template>
  <div class="form-container">
    <div class="action-buttons-header action-buttons-header--edit-screen">
      <a
        href="#users/new"
        class="btn btn-primary btn-outline"
        role="button"
      >
        {{ $localize("AddNewUserButton") }}
      </a>
    </div>

    <h2>
      {{ $localize("UsersTitle") }}
      <span class="font-size-smaller">({{ state.users.length }})</span>
    </h2>
    <p class="lead">
      {{ $localize(AccountSpecificLabelsProvider.getAccountLabelKey("UsersTabInfoRowText")) }}
    </p>

    <div class="row">
      <div class="col-sm-4">
        <UserFilterArea
          v-model="state.selectedUserId"
          :users="state.users"
        />
      </div>
      <div class="col-sm-8 no-sm-x-padding">
        <user-details
          v-if="state.selectedUserId !== null"
          :page-mode="pageMode"
          :selected-user-id="state.selectedUserId"
          :validation-rules="validationRules"
          @updated="userUpdated"
          @deleted="userDeleted"
        />
        <div
          v-else
          class="blank-area blank-area-height-admin-users"
        >
          <i
            class="fas fa-user"
            aria-hidden="true"
          />
          <h4>{{ $localize("UsersTabBlankAreaInfo") }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { reactive } from 'vue';
import UserDetailsServerModel from '@/VueComponents/PageComponents/Users/Models/userDetailsServerModel';
import UserSummaryModel from '@/Models/userSummaryModel';
import UserDetailsValidationRules from '@/VueComponents/AccountDetails/Models/userDetailsValidationRules';
import AccountSpecificLabelsProvider from '@/Utils/accountSpecificLabelsProvider';

import UserFilterArea from '@/VueComponents/UserFilterArea/UserFilterArea.vue';
import UserDetails from '@/VueComponents/PageComponents/Users/UserDetails.vue';

const props = defineProps<{
  pageMode: string,
  allUsers: UserSummaryModel[],
  validationRules: UserDetailsValidationRules
}>();

const state: { selectedUserId: string | null; users: UserSummaryModel[] } = reactive({
  selectedUserId: null,
  users: props.allUsers
});

function userUpdated(updatedUser: UserDetailsServerModel) {
  const updateIndex = state.users.findIndex(x => x.personaId === updatedUser.personaId);
  const updatedUserSummary = buildUserSummary(updatedUser, state.users[updateIndex]);
  state.users.splice(updateIndex, 1, updatedUserSummary);
}

function userDeleted(deletedUserId: string) {
  const deleteIndex = state.users.findIndex(x => x.personaId === deletedUserId);
  state.users.splice(deleteIndex, 1);
  state.selectedUserId = null;
}

function buildUserSummary(userDetailsDto: UserDetailsServerModel, oldUserSummary: UserSummaryModel): UserSummaryModel {
  return new UserSummaryModel(userDetailsDto.personaId, userDetailsDto.givenName, userDetailsDto.familyName,
      userDetailsDto.emailAddress, oldUserSummary.isPreregistered,
      userDetailsDto.isBusinessAdminUser, userDetailsDto.isBusinessPrimaryContact
  );
}
</script>

