<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import PortalSettingsProvider from '@/Utils/portalSettingsProvider';
import { AccountType } from '@/Types/Enums/accountType';

const contextDataStore = useContextDataStore();
const route = useRoute();

const requestsItemActive = computed(() =>{
  const path = route.path;
  return path === '/' || route.path.startsWith('/request');
});
const camerasItemActive = computed(() => route.path.startsWith('/cameras'));
const groupsItemActive = computed(() => route.path.startsWith('/groups'));
const usersItemActive = computed(() => route.path.startsWith('/users'));
const uploadFoldersActive = computed(() => route.path.startsWith('/uploadfolder'));

const isBusinessAccountType = computed(() => PortalSettingsProvider.getAccountType() === AccountType.Business);
const isPartnerAccountType = computed(() => PortalSettingsProvider.getAccountType() === AccountType.Partner);
const isSelfResponsesAvailable = computed(() => PortalSettingsProvider.getSelfResponseEnabled());
const isBusinessAdminUser = computed(() => contextDataStore.userData?.isBusinessAdminUser);

const collapseNavbar = () => {
  const navbarCollapse = document.querySelector('.navbar-collapse');
  if (navbarCollapse) {
    navbarCollapse.classList.remove('in');
  }
};
</script>

<template>
  <div class="collapse navbar-collapse">
    <ul class="nav navbar-nav">
      <li :class="{ active: requestsItemActive }">
        <router-link
          to="/requests"
          @click="collapseNavbar"
        >
          <span>{{ $localize('Requests') }}</span>
          <span
            v-if="requestsItemActive"
            class="sr-only"
          >{{ $localize('CurrentSectionSummary') }}</span>
        </router-link>
      </li>

      <li
        v-if="isBusinessAccountType"
        :class="{ active: camerasItemActive }"
      >
        <router-link
          to="/cameras"
          @click="collapseNavbar"
        >
          <span>{{ $localize('Cameras') }}</span>
          <span
            v-if="camerasItemActive"
            class="sr-only"
          >{{ $localize('CurrentSectionSummary') }}</span>
        </router-link>
      </li>

      <li
        v-if="isPartnerAccountType && isSelfResponsesAvailable"
        :class="{ active: uploadFoldersActive }"
      >
        <router-link
          to="/uploadfolders"
          @click="collapseNavbar"
        >
          <span>{{ $localize('UploadFolders') }}</span>
          <span
            v-if="uploadFoldersActive"
            class="sr-only"
          >{{ $localize('CurrentSectionSummary') }}</span>
        </router-link>
      </li>

      <li :class="{ active: route.path === '/my-account' }">
        <router-link
          to="/my-account"
          @click="collapseNavbar"
        >
          <span>{{ $localize('MyAccount') }}</span>
          <span
            v-if="route.path === '/my-account'"
            class="sr-only"
          >{{ $localize('CurrentSectionSummary') }}</span>
        </router-link>
      </li>

      <li
        v-if="isBusinessAdminUser"
        :class="{ active: usersItemActive }"
      >
        <router-link
          to="/users"
          @click="collapseNavbar"
        >
          <span>{{ $localize('UsersTitle') }}</span>
          <span
            v-if="usersItemActive"
            class="sr-only"
          >{{ $localize('CurrentSectionSummary') }}</span>
        </router-link>
      </li>

      <li
        v-if="isBusinessAdminUser"
        :class="{ active: groupsItemActive }"
      >
        <router-link
          to="/groups"
          @click="collapseNavbar"
        >
          <span>{{ $localize('GroupsTitle') }}</span>
          <span
            v-if="groupsItemActive"
            class="sr-only"
          >{{ $localize('CurrentSectionSummary') }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>