<template>
  <ModalDialog
    :title="$localize('BusinessUserDeleteModalTitle')"
    :is-danger="true"
    :is-wide="false"
    @close="emit('close')"
  >
    <template #default>
      <div class="text-pre-wrap">
        {{ $localize(isAuthenticatedUserSelected ? 'BusinessUserSelfDeleteModalMessage' : 'BusinessUserDeleteModalMessage') }}
      </div>
      <br>
      <div>
        {{ $localize('BusinessUserModalQuestion') }}
      </div>
    </template>
    <template #footer>
      <button
        ref="closeBtn"
        class="btn btn-default"
        @click="emit('close');"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        ref="deleteBtn"
        class="btn btn-danger"
        @click="emit('delete')"
      >
        {{ $localize('Continue') }}
      </button>
    </template>
  </ModalDialog>
</template>

<script setup lang="ts">
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
const emit = defineEmits < { 'close', 'delete' }>();
defineProps<{
  isAuthenticatedUserSelected: boolean
}>();

</script>
