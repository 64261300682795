<template>
  <PaginatedTable
    ref="pTable"
    :fields="uploadsTableConfiguration.tableFields"
    :items="tableData"
    :default-page-size="uploadsTableConfiguration.pageSize"
    :caption="$localize('Uploads')"
    @page-size-changed="onPageSizeChanged"
  >
    <template #header>
      <div class="table-header">
        <div class="table-header-summary">
          <h5 class="uploads-table-label">
            <span v-if="summary.foldersCount === 1">{{ $localize('OneFolder') }}, </span>
            <span v-if="summary.foldersCount > 1">{{ $localize('NumberOfFolders', { number: summary.foldersCount }) }}, </span>
            <span>
              {{ localizedStrings.uploadsTableHeaderTotal }}
            </span>
            <span v-if="localizedStrings.uploadsTableHeaderUploading || localizedStrings.uploadsTableHeaderFailed">
              (<span>{{ localizedStrings.uploadsTableHeaderUploading }}</span>
              <span v-if="localizedStrings.uploadsTableHeaderUploading && localizedStrings.uploadsTableHeaderFailed">, </span>
              <a
                href="#"
                @click="onFailedClick"
              >{{ localizedStrings.uploadsTableHeaderFailed }}</a>)
            </span>
          </h5>
          <span class="small">
            {{
              $localize('UploadsTableHeaderUploadedSize', {
                uploaded: FileSizeFormatter.getFormattedFileSize(summary.uploadedSize),
                total: FileSizeFormatter.getFormattedFileSize(summary.uploadsTotalSize) })
            }}
          </span>
        </div>
        <div class="table-header-selector">
          <SelectUploadsDropdown
            class="table-header-button mr-2"
            :overlay-z-index="constants.dropdownZIndex"
            :failed-count="summary.failedUploadItemsCount"
            :available-for-selection-count="summary.availableForSelection"
            :selected-count="summary.selectedCount"
            @select-all="selectAll"
            @select-failed="selectFailed"
            @deselect-all="deselectAll"
          />
          <button
            v-if="allowToManageMetadata"
            class="btn btn-default btn-sm table-header-button mr-2"
            :disabled="isRemovingAny()"
            :title="$localize(uploadParentItemIsCompleted ? 'ViewMetadata' : 'EditMetadata')"
            @click="editMetadataClick"
          >
            <i
              class="far fa-edit"
              aria-hidden="true"
            />
            <span class="small-screen-hidden ml-1">
              {{ $localize(uploadParentItemIsCompleted ? 'ViewMetadata' : 'EditMetadata') }}
            </span>
          </button>
          <button
            v-if="!uploadParentItemIsCompleted"
            class="btn btn-default btn-sm table-header-button"
            :disabled="isRemovingAny() || summary.selectedCount === 0"
            :title="$localize('RemoveSelected')"
            @click="removeClick"
          >
            <i
              v-if="!isRemovingAny()"
              class="far fa-trash-can"
            />
            <i
              v-else
              class="far fa-circle-notch fa-spin"
            />
            <span class="small-screen-hidden ml-1">
              {{ $localize('RemoveSelected') }}
            </span>
          </button>
        </div>
        <div class="table-header-sort">
          <SortDropdown
            :sorting-options="uploadsTableConfiguration.sortOptions"
            :default-value="uploadsTableConfiguration.sortOptions[0].field"
            @sort="onSort"
          />
        </div>
      </div>
    </template>
    <template #fileName="prop">
      {{ prop.data.fileName }}
      <div>
        <small>
          {{ getUploadDetails(prop.data) }}
        </small>
      </div>
    </template>
    <template #select="prop">
      <input
        type="checkbox"
        :disabled="prop.data.status === constants.uploadStatuses.authorising ||
          prop.data.status === constants.uploadStatuses.initializing"
        :checked="isSelected(prop.data.uploadModel)"
        @change="event => onItemSelectionChanged(prop.data.uploadModel, event.target.checked)"
      >
    </template>
    <template #status="prop">
      <i
        v-if="prop.data.status === constants.uploadStatuses.authorising ||
          prop.data.status === constants.uploadStatuses.initializing"
        class="far fa-circle-notch fa-spin"
      />
      {{ $localize(prop.data.status) }}
      <div v-if="prop.data.status === constants.uploadStatuses.uploaded && prop.data.isFolder">
        <small>
          {{ $localize('FilesCount', { number: prop.data.numberOfUploads }) }}
        </small>
      </div>
      <span v-if="prop.data.status === constants.uploadStatuses.uploading">
        ({{ getUploadedPercent(prop.data.bytesUploaded, prop.data.bytesTotal) }}%)
      </span>
      <ProgressBar
        v-if="prop.data.status === constants.uploadStatuses.uploading"
        :progress="getUploadedPercent(prop.data.bytesUploaded, prop.data.bytesTotal)"
      />
    </template>
  </PaginatedTable>
</template>
<script lang="ts" setup>
import PaginatedTable from '@/VueComponents/PaginatedTable/PaginatedTable.vue';
import SelectUploadsDropdown from '@/VueComponents/Uploads/SelectUploadsDropdown.vue';
import SortDropdown from '@/VueComponents/SharedComponents/SortDropdown/SortDropdown.vue';
import ProgressBar from '@/VueComponents/SharedComponents/ProgressBar/ProgressBar.vue';
import UploadsTableConfiguration from '@/VueComponents/Uploads/utils/uploadsTableConfiguration';
import { computed, ComputedRef, ref } from 'vue';
import UploadsTableDataModel from '@/VueComponents/Uploads/models/uploadsTableDataModel';
import FileSizeFormatter from '@/Utils/fileSizeFormatter';
import moment from 'moment/moment';
import PartnerUploadModel from '@/Models/partnerUploadModel';
import FilePathFormatter from '@/Utils/filePathFormatter';
import localStorageHelper from '@/Utils/localStorageHelper';
import { LocalStorageKeyType } from '@/Types/Enums/localStorageKeyType';
import constants from '@/constants';
import resourceHelper from '@/Utils/resourceHelper';
import UploadTypeResolver from '@/Utils/uploadTypeResolver';
import useUploadRemoving from '@/VueComponents/Uploads/utils/useUploadRemoving';
import useUploadSelection from '@/VueCore/utils/useSelection';
import useSorting from '@/VueCore/utils/useSorting';
import BasePartnerUploadDataModel from '@/Models/basePartnerUploadDataModel';

const props = defineProps<{
  localStoragePageSizeKey: LocalStorageKeyType,
  allowToManageMetadata: boolean,
  uploadParentItemIsCompleted: boolean,
}>();
const model = defineModel<BasePartnerUploadDataModel[]>({ default: () => [] });

const { remove, isRemovingAny } = useUploadRemoving(model.value);
const { select, isSelected, deselect, deselectAll, selectedItems } =
  useUploadSelection<BasePartnerUploadDataModel>(model.value);
const { sort } = useSorting<BasePartnerUploadDataModel>(model.value);

const emit = defineEmits(['editMetadataClick', 'removeClick', 'sort']);
defineExpose({
  selectPage
});

const uploadsTableConfiguration = new UploadsTableConfiguration(props.localStoragePageSizeKey);

const pTable = ref(null);

const localizedStrings = computed(() => {
  return {
    uploadsTableHeaderTotal: resourceHelper.getString('UploadsTableHeaderTotal',
        { total: summary.value.totalUploadsCount.toString() }),
    uploadsTableHeaderFailed: summary.value.failedCount ?
      resourceHelper.getString('UploadsTableHeaderFailed', { failed: summary.value.failedCount.toString() }) : '',
    uploadsTableHeaderUploading: summary.value.uploadingCount ?
      resourceHelper.getString('UploadsTableHeaderUploading', { uploading: summary.value.uploadingCount.toString() }) : ''
  };
});

const tableData: ComputedRef<UploadsTableDataModel[]> = computed(() => {
  return model.value?.map((u: BasePartnerUploadDataModel) => {
    return {
      id: u.uploadId,
      fileName: u.fileName,
      status: u.statusName,
      size: FileSizeFormatter.getFormattedFileSize(u.size),
      dateAdded: moment(u.addedTimestamp).format('L'),
      addedBy: u.personaDisplayName,
      path: getFilePathWithoutFileName(u),
      bytesUploaded: u.bytesUploaded,
      bytesTotal: u.size,
      numberOfUploads: u.getUploadsLength(),
      isFolder: u.isFolder,
      type: u.type,
      uploadModel: u
    };
  });
});

function getFilePathWithoutFileName(upload: BasePartnerUploadDataModel) {
  return upload instanceof PartnerUploadModel ?
    FilePathFormatter.getFilePathWithoutFileName(upload.fileRelativePath) :
    upload.rootFolderName;
}

function onPageSizeChanged(newSize: number) {
  localStorageHelper.setSessionValue(props.localStoragePageSizeKey, newSize.toString());
}

const summary = computed(() => {
  let uploadingCount = 0;
  let failedCount = 0;
  let failedUploadItemsCount = 0;
  let uploadsTotalSize = 0;
  let uploadedSize = 0;
  let totalUploadsCount = 0;
  const foldersCount = model.value.filter(u => u.isFolder).length;
  const availableForSelection = model.value.filter(u => u.statusName !== constants.uploadStatuses.authorising).length;
  const selectedCount = selectedItems().length;

  for (const upload of model.value) {
    totalUploadsCount += upload.getUploadsLength();
    uploadingCount += upload.statusName === constants.uploadStatuses.uploading ? upload.getUploadsLength() : 0;
    failedCount += upload.statusName === constants.uploadStatuses.failed ||
    upload.statusName === constants.uploadStatuses.failedAuthorisation ? upload.getUploadsLength() : 0;
    failedUploadItemsCount += upload.statusName === constants.uploadStatuses.failed ||
    upload.statusName === constants.uploadStatuses.failedAuthorisation ? 1 : 0;
    uploadsTotalSize += upload.size as number;
    uploadedSize += upload.bytesUploaded as number;
  }

  return {
    uploadingCount,
    failedCount,
    failedUploadItemsCount,
    uploadsTotalSize,
    uploadedSize,
    foldersCount,
    totalUploadsCount,
    selectedCount,
    availableForSelection
  };
});

function onFailedClick(event: Event) {
  event?.preventDefault();
  selectFailed();
}

function selectFailed() {
  model.value!.filter(u => u.statusName === constants.uploadStatuses.failed ||
    u.statusName === constants.uploadStatuses.failedAuthorisation)
      .forEach(u => select(u));
}

function selectAll() {
  model.value!.filter(u => u.statusName !== constants.uploadStatuses.authorising)
      .forEach(u => select(u));
}

function editMetadataClick() {
  emit('editMetadataClick', selectedItems());
}

function removeClick() {
  const uploadsToDelete = model.value!.filter(m => isSelected(m));
  remove(uploadsToDelete).then(() => {
    deselectAll();
  });
}

function onSort(options: { field: string, asc: boolean }) {
  sort(options.field, options.asc);
  (pTable.value as any)?.selectPage(1);
}

function getUploadDetails(upload: any): string {
  return UploadTypeResolver.localizeUploadType(upload.type, upload.isFolder);
}

function getUploadedPercent(uploaded: number, totalSize: number): number {
  return Math.floor(uploaded / totalSize * 100);
}

function onItemSelectionChanged(upload: BasePartnerUploadDataModel, value: boolean) {
  if (!value) {
    deselect(upload);
  } else {
    select(upload);
  }
}

function selectPage(page: number) {
  (pTable.value as any)?.selectPage(page);
}
</script>
<style scoped>
.table-header-summary {
  flex-basis: fit-content;
}

.table-header-selector {
  flex-grow: 1;
}

.uploads-table-label {
  margin-bottom: 0;
  margin-top: 0;
}

.table-header {
  display: flex;
  justify-content: space-between;
}

.table-header-button {
  margin-left: 1em;
}

.table-header-sort {
  padding-top: 5px;
}

@media screen and (max-width: 700px) {
  .table-header {
    flex-wrap: wrap;
  }

  .table-header-summary {
    flex-basis: 100%;
  }

  .table-header-button {
    margin-left: 0;
  }
}

@media screen and (max-width: 1500px) {
  .small-screen-hidden {
    display: none;
  }
}
</style>