import SortOption from '@/VueComponents/SharedComponents/SortDropdown/types/sortOption';
import localStorageHelper from '@/Utils/localStorageHelper';
import { LocalStorageKeyType } from '@/Types/Enums/localStorageKeyType';
import SortModel from '@/VueComponents/SharedComponents/SortDropdown/types/sortModel';

export default class SortOptionsManager {
  public options: SortOption[];
  public sortField: string;
  public sortOrder: string;

  onSortCallback: (() => void) | undefined;

  private readonly localStorageKey: LocalStorageKeyType | undefined;

  constructor(options: SortOption[],
    localStorageKey: LocalStorageKeyType | undefined,
    onSortCallback: (() => void) | undefined,
    defaultSortingOrder?: string) {
    this.localStorageKey = localStorageKey;
    this.options = options;
    this.onSortCallback = onSortCallback;

    const defaultSort = this.getDefaultSortingOptions(options, defaultSortingOrder);
    this.sortField = defaultSort.field;
    this.sortOrder = defaultSort.order;
  }

  private getDefaultSortingOptions(options: SortOption[], defaultOrder?: string): SortModel {
    const savedSortingJson = this.localStorageKey ? localStorageHelper.getSessionValue(this.localStorageKey) : undefined;
    if (!savedSortingJson) {
      return {
        field: options[0].field,
        order: defaultOrder ? defaultOrder : 'Asc'
      };
    }

    const savedSorting = JSON.parse(savedSortingJson);
    return savedSorting;
  }

  public onSort(data) {
    this.sortField = data.field;
    this.sortOrder = data.asc ? 'Asc' : 'Desc';

    if (this.localStorageKey) {
      localStorageHelper.setSessionValue(this.localStorageKey, JSON.stringify({
        field: this.sortField,
        order: this.sortOrder
      }));
    }

    if (this.onSortCallback) {
      this.onSortCallback();
    }
  }
}