<template>
  <ErrorList
    id="clientSideValidationErrorMessage"
    :title="title ?? $localize('ClientSideValidationFailed')"
    :errors="errors"
  />
</template>

<script lang="ts" setup>
import ErrorList from '@/VueComponents/SharedComponents/ErrorList/ErrorList.vue';
import { computed } from 'vue';
import resourceHelper from '@/Utils/resourceHelper';

const props = defineProps<{
  errorKeys?: string[],
  errorStrings?: string[],
  title?: string
}>();
const errors = computed(() => {
  const result: string[] = [];
  if (props.errorStrings && props.errorStrings.length) {
    result.push(...props.errorStrings);
  }
  if (props.errorKeys && props.errorKeys.length) {
    result.push(...props.errorKeys.map(errorKey => resourceHelper.getString(errorKey)));
  }
  return result;
});
</script>
