<template>
  <div>
    <div>
      <RequestDetailsHeader
        :request="request"
        :discussion-message-count="discussionMessagesCount"
        :saving="saving"
        :rejecting="rejecting"
        :submitting="submitting"
        @submit-response="onSubmitClicked"
        @reject-request="onRejectClicked"
        @save-request="save"
        @create-report="onCreateReportClicked"
      />
      <RequestDisclaimer
        v-if="disclaimerParameters.show"
        :main-label="disclaimerParameters.mainLabel"
        :additional-options="disclaimerParameters.additionalOptions"
      />

      <div class="request-details__container">
        <div
          :class="{ 'request-details__main-content--discussion-allowed': request.allowedFeatures.discussionMessaging }"
          class="request-details__main-content"
        >
          <div class="content-container">
            <h3>{{ $localize('RequestDetails') }}</h3>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label
                    for="assignee"
                    class="control-label"
                  >{{ $localize('RequestAssignee') }}</label>
                  <AutoComplete
                    id="assignee"
                    v-model="selectedAssignee"
                    :options="filteredAssignees"
                    option-label="name"
                    :disabled="request.hasResponse"
                    :option-disabled="'isDisabled'"
                    :dropdown="true"
                    :placeholder="$localize('SelectRequestAssignee')"
                    :overlay-z-index="constants.dropdownZIndex"
                    @search="searchAssignee"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="isBusiness"
              class="row"
            >
              <div class="col-md-6">
                <dl>
                  <dt><span>{{ $localize('RequestedCameras') }}</span></dt>
                  <dd>
                    <span>
                      {{ request.dataRequestFields.requestedCameraNames }}
                    </span>
                  </dd>
                </dl>
              </div>
            </div>

            <div class="row">
              <div
                v-for="(claim, index) in request.claims"
                :key="index"
              >
                <div class="col-md-6">
                  <dl>
                    <dt v-if="claim.displayName">
                      <span>{{ claim.displayName }}</span>
                    </dt>
                    <dt v-else>
                      <span>{{ claim.key }}</span>
                    </dt>
                    <dd>
                      <span>{{ claim.value }}</span>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <!--Dynamic request details part-->
            <div class="row">
              <div class="col-sm-12">
                <DynamicForm
                  :model-value="requestFormData"
                  :form-definition="requestFormDefinition"
                  :is-read-only="true"
                />
              </div>
            </div>

            <div v-if="associatedFiles?.length">
              <h4>{{ $localize('AttachedFiles') }}</h4>
              <p>
                <span>
                  {{ $localize('FilesAttachedBy', { requestorFullName: request.investigatorDisplayName }) }}
                </span>
              </p>
              <AssociatedFileList
                :files="associatedFiles"
                :readonly="request.hasResponse"
              />
              <br>
            </div>

            <!--Response form-->
            <h3>{{ $localize('ResponseDetails') }}</h3>

            <div v-if="request.requestStatus === RequestStatus.rejected && request.rejectionReason">
              <b>{{ $localize('RejectComment') }}</b>
              <div>{{ request.rejectionReason }}</div>
              <br>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <DynamicForm
                  ref="responseForm"
                  v-model="responseFormData"
                  :form-definition="responseFormDefinition"
                  :is-read-only="request.hasResponse || request.requestStatus === RequestStatus.rejected"
                />
              </div>
            </div>

            <!--Form file uploads-->
            <div>
              <RequestUploads
                v-if="request.requestStatus !== constants.requestStatuses.rejected"
                v-model="uploads"
                :allow-to-manage-metadata="allowMetadata"
                :upload-parent-item-is-completed="isRequestCompleted"
                :upload-guidance="request.dynamicCctvFolderExportExplanatoryText"
                :upload-parent-item-is-completing="submitting"
                :upload-parent-item-type="uploadParentItemType"
                :upload-parent-item-is-discarding="rejecting"
                :upload-parent-item-has-response="request.hasResponse"
                :upload-parent-item-includes-camera-selection="request.dynamicResponseUploadsIncludeCameraSelection"
                :hide-upload-selector="request.hasResponse ||
                  !request.dynamicResponseAllowUploads"
                :hide-default-explanatory-text="!!request.dynamicCctvFolderExportExplanatoryText && isBusiness"
                @upload-failed-to-add="onUploadFailedToAdd"
                @before-upload-started="onBeforeUploadStarted"
                @server-error="onServerError"
              />
            </div>

            <!--Errors block-->
            <div>
              <ClientErrorList
                :title="$localize('UploadValidationFailed')"
                :error-strings="clientErrors"
              />

              <ServerErrorList
                :title="$localize('UploadValidationFailed')"
                :errors="serverErrors"
              />
            </div>
          </div>
        </div>

        <div
          class="request-details__vertical-divider"
          :class="{ 'request-details__vertical-divider--discussion-allowed': request.allowedFeatures.discussionMessaging }"
        />

        <aside
          class="request-details__discussion"
          :class="{ 'request-details__discussion--discussion-allowed': request.allowedFeatures.discussionMessaging }"
        >
          <div class="content-container">
            <DiscussionMessaging
              :discussion-messages="discussionMessages"
              :discussion-messaging-allowed="request.allowedFeatures.discussionMessaging"
              :item-id="request.requestId"
              :item-type="request.requestItemType"
              local-message-icon-class-names="fas fa-building"
              external-message-icon-class-names="fas fa-user-shield"
              @message-sent="onMessageSent"
            />
          </div>
        </aside>
      </div>
    </div>
    <SubmitRequestConfirmationModal
      v-if="showSubmitModal"
      :investigator-name="request.investigatorDisplayName"
      :uploads-count="uploads.length"
      :confirmation-text="request.dynamicResponseConfirmationText"
      @continue="submit"
      @close="onSubmitModalClose"
    />
    <RejectRequestConfirmationModal
      v-if="showRejectModal"
      @continue="reject"
      @close="onRejectModalClose"
    />
    <CreateReportModal
      v-if="showCreateReportModal"
      :item-type="CreateReportModalTypes.request"
      :report-item-id="request.requestId"
      :report-item-reference-number="request.referenceNumber"
      @close="onCreateReportClose"
    />
  </div>
</template>


<script lang="ts" setup>
import RequestDisclaimer from '@/VueComponents/RequestDisclaimer/RequestDisclaimer.vue';
import Request from '@/Types/request';
import { RequestStatus } from '@/Types/Enums/requestStatus';
import { computed, reactive, Ref, ref } from 'vue';
import { useRouter } from 'vue-router';
import RequestUploads from '@/VueComponents/Uploads/RequestUploads.vue';
import constants from '@/constants';
import ServerErrorList from '@/VueComponents/SharedComponents/ErrorList/ServerErrorList.vue';
import ClientErrorList from '@/VueComponents/SharedComponents/ErrorList/ClientErrorList.vue';
import DynamicForm from '@/VueComponents/DynamicForm/DynamicForm.vue';
import AutoComplete from '@/VueComponents/SharedComponents/AutoComplete.vue';
import RequestDetailsHeader from '@/VueComponents/Requests/RequestDetails/RequestDetailsHeader.vue';
import { RequestItemType } from '@/Types/Enums/requestItemType';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import requestRepository from '@/Repositories/requestRepository';
import configurableFieldRepository from '@/Repositories/configurableFieldRepository';
import resourceHelper from '@/Utils/resourceHelper';
import AssociatedFileList from '@/VueComponents/Requests/RequestDetails/AssociatedFileList.vue';
import logger from '@/Utils/logger';
import UploadRepository from '@/Repositories/uploadRepository';
import SubmitRequestConfirmationModal
  from '@/VueComponents/Requests/RequestDetails/Modals/SubmitRequestConfirmationModal.vue';
import RejectRequestConfirmationModal
  from '@/VueComponents/Requests/RequestDetails/Modals/RejectRequestConfirmationModal.vue';
import requestUploadValidation from '@/Validation/requestUploadValidation';
import DiscussionMessaging from '@/VueComponents/DiscussionMessaging/DiscussionMessaging.vue';
import DiscussionMessageModel from '@/Models/discussionMessageModel';
import CreateReportModal from '@/VueComponents/Modals/CreateReportModal.vue';
import { CreateReportModalTypes } from '@/Types/Enums/createReportModalTypes';
import BasePartnerUploadDataModel from '@/Models/basePartnerUploadDataModel';

defineOptions({
  inheritAttrs: false
});

const props = defineProps<{
  request: Request,
  associatedFiles: any[],
  requestAssignees: any[],
  discussionMessages: DiscussionMessageModel[]
}>();

const router = useRouter();

const disclaimerParameters = reactive({
  show: false,
  mainLabel: '',
  additionalOptions: []
});

const isRequestCompleted = computed(() => {
  return props.request.requestStatus === constants.requestStatuses.completed ||
    props.request.requestStatus === constants.requestStatuses.rejected ||
    props.request.requestStatus === constants.requestStatuses.cancelled ||
    props.request.requestStatus === constants.requestStatuses.expired ||
    props.request.requestStatus === constants.requestStatuses.failed;
});

const responseForm = ref();

const saving = ref(false);
const rejecting = ref(false);
const submitting = ref(false);

const showSubmitModal = ref(false);
const showRejectModal = ref(false);
const showCreateReportModal = ref(false);

const allowMetadata = ref(false);

const uploads: Ref<BasePartnerUploadDataModel[]> = ref([]);

const clientErrors = ref([]);
const serverErrors = ref([]);

const responseFormDefinition = props.request.dynamicResponseFormDefinition ?
  JSON.parse(props.request.dynamicResponseFormDefinition) : {};
const requestFormDefinition = props.request.dynamicRequestFormDefinition ?
  JSON.parse(props.request.dynamicRequestFormDefinition) : {};

const responseFormData = ref(props.request.dynamicResponseFormData ?
  JSON.parse(props.request.dynamicResponseFormData) : {});
const requestFormData = ref(props.request.dynamicRequestFormData ?
  JSON.parse(props.request.dynamicRequestFormData) : {});

const discussionMessagesCount = ref(props.discussionMessages.length);

const isBusiness = props.request.requestItemType === RequestItemType.business;
const isPartner = props.request.requestItemType === RequestItemType.partner;
const uploadParentItemType = props.request.requestItemType === RequestItemType.business ?
  UploadParentItemType.businessRequest : UploadParentItemType.partnerRequest;

const filteredAssignees = ref([]);
const selectedAssignee = ref(getSelectedRequestAssignee());

if (isPartner) {
  // If completed in any way: manually, rejected, failed, etc
  if (isRequestCompleted.value) {
    requestRepository.requestHasUploadMetadata(props.request.requestId)
        .then(response => {
          allowMetadata.value = response;
        });
  } else {
    configurableFieldRepository.getUploadParentConfigurableFields(props.request.requestId, uploadParentItemType)
        .then(fields => {
          allowMetadata.value = fields.availableConfigurableFieldIds.length > 0;
          setRequestDisclaimerParameters(fields.newlyAddedConfigurableFieldNames, fields.deletedConfigurableFieldNames);
        });
  }
}

if (props.request.statusJustChangedToInProgress) {
  logger.info('RequestStatusChangedToInProgress');
}

function searchAssignee(event) {
  const query = event.query.trim();
  filteredAssignees.value = props.requestAssignees.filter(s => s.name.toLowerCase().includes(query.toLowerCase()));
}

function onMessageSent() {
  discussionMessagesCount.value += 1;
}

function onUploadFailedToAdd(error) {
  clientErrors.value = [error];
}

function onBeforeUploadStarted() {
  serverErrors.value = [];
  clientErrors.value = [];
}

function onServerError(messages: string[]) {
  serverErrors.value = messages;
}

function onSubmitModalClose() {
  showSubmitModal.value = false;
}

function onRejectModalClose() {
  showRejectModal.value = false;
}

function onSubmitClicked() {
  if (selectedAssignee.value?.assigneeType === constants.requestAssigneeTypes.group &&
    !selectedAssignee.value?.isGroupContactActive) {
    logger.warning('GroupNeedsRegisteredContact');
    return;
  }

  serverErrors.value = [];
  clientErrors.value = [];

  const statusValidationFailureResourceInfo = requestUploadValidation.getStatusValidationResourceInfo(uploads.value, true);

  if (statusValidationFailureResourceInfo) {
    clientErrors.value = [statusValidationFailureResourceInfo];
    return;
  }

  responseForm.value.validate().then(function (isValid) {
    if (!isValid) {
      clientErrors.value = [resourceHelper.getString('DynamicFormHasErrors')];
      return;
    }

    clientErrors.value = [];
    showSubmitModal.value = true;
  });
}

async function submit() {

  showSubmitModal.value = false;
  submitting.value = true;

  const requestData = getRequestDataWithUploadIds(uploads.value, props.request);
  setSavedDynamicFields(requestData);
  setAssignee(requestData);

  const uploadsToUpdate = uploads.value.filter(u => u.hasUploadInfoChanged).map(u => {
    return {
      uploadId: u.uploadId,
      cameraName: u.cameraName,
      comments: u.comments
    };
  });

  try {
    await UploadRepository.updateUploads(uploadsToUpdate);
    await requestRepository.updateRequestAndSubmitResponse(requestData);
    logger.success('ResponseSubmittedSummary');
    router.push('/requests');
  } catch (jqXhr) {
    handleSubmitResponseError(jqXhr);
  }

  submitting.value = false;
}

function handleSubmitResponseError(jqXhr: any) {
  if (jqXhr.responseJSON?.errorMessages && jqXhr.responseJSON.errorMessages.length && jqXhr.responseJSON.isUserRecoverable) {
    clientErrors.value = [jqXhr.responseJSON.errorMessages[0]];
    return;
  }

  if (jqXhr.serverErrorMessages) {
    serverErrors.value = jqXhr.serverErrorMessages;
    return;
  }

  if (!jqXhr.errorHasBeenLogged) {
    logger.error('UnexpectedErrorWhileSubmittingRequest', null, jqXhr);
    return;
  }
}

function onRejectClicked() {
  const statusValidationFailureResourceInfo = requestUploadValidation
      .getStatusValidationResourceInfo(uploads.value, true);

  if (statusValidationFailureResourceInfo === 'UploadsStillInProgress') {
    clientErrors.value = [resourceHelper.getString('UploadStillInProgressReject')];
    return;
  }

  showRejectModal.value = true;
}

function reject(reason: string) {

  showRejectModal.value = false;
  rejecting.value = true;

  const requestData = { ...props.request };
  requestData.requestStatus = constants.requestStatuses.rejected;
  requestData.rejectionReason = reason;
  setSavedDynamicFields(requestData);
  setAssignee(requestData);

  requestRepository.updateRequestAndSubmitResponse(requestData)
      .then(() => {
        logger.success('ResponseSubmittedSummary');
        router.push('/requests');
      }).catch(jqXhr => {

        rejecting.value = false;

        if (jqXhr.serverErrorMessages) {
          serverErrors.value = jqXhr.serverErrorMessages;
        } else if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnexpectedErrorWhileSubmittingRequest', null, jqXhr);
        }
        return Promise.reject(jqXhr);
      });
}

function onCreateReportClicked() {
  showCreateReportModal.value = true;
}

function onCreateReportClose() {
  showCreateReportModal.value = false;
}

function setRequestDisclaimerParameters(newFields, deletedFields) {
  const options = newFields.map(fieldName => {
    return resourceHelper.getString('ConfigurableFieldWasAdded', { '0': fieldName });
  });
  options.push(...deletedFields.map(fieldName => {
    return resourceHelper.getString('ConfigurableFieldWasRemoved', { '0': fieldName });
  }));

  if (options.length > 0) {
    disclaimerParameters.show = true;
    disclaimerParameters.mainLabel = resourceHelper.getString('RequestMetadataDisclaimer', { '0': options.length });
    disclaimerParameters.additionalOptions = options;
  }
}

function save() {
  if (selectedAssignee.value?.assigneeType === constants.requestAssigneeTypes.group &&
    !selectedAssignee.value?.isGroupContactActive) {
    logger.warning('GroupNeedsRegisteredContact');
    return;
  }

  serverErrors.value = [];

  const changedUploads = uploads.value.filter(upload => upload.hasUploadInfoChanged && upload.uploadId === null);

  if (changedUploads && changedUploads.length &&
    !changedUploads.every(upload => upload.statusName === constants.uploadStatuses.failedAuthorisation)) {
    // Some items can be changed while authorising but without upload id yet to prevent the failure of Save operation a user is warned instead
    if (changedUploads.some(upload => upload.statusName !== constants.uploadStatuses.authorising ||
      upload.statusName !== constants.uploadStatuses.failedAuthorisation)) {
      // Such situation is not expected. It means that in some reason upload id was completely lost.
      logger.error('UnhandledError');
    } else if (changedUploads.some(upload => upload.statusName === constants.uploadStatuses.authorising)) {
      logger.warning('SaveWhileAuthorising');
    }
    return Promise.resolve(null);
  }

  saving.value = true;

  const requestData = { ...props.request };
  setSavedDynamicFields(requestData);
  setAssignee(requestData);

  const uploadsToUpdateData = uploads.value.filter(u => u.hasUploadInfoChanged &&
    u.statusName !== constants.uploadStatuses.failedAuthorisation.isFailedAuthorisation)
      .map(u => {
        return {
          cameraName: u.cameraName as string,
          comments: u.comments as string,
          uploadId: u.uploadId
        };
      });

  return Promise.all([
    requestRepository.updateRequest(requestData),
    // failed authorisation uploads don't need to be saved
    UploadRepository.updateUploads(uploadsToUpdateData)
  ])
      .then(function () {
        logger.success('RequestUpdatedSummary');
      })
      .catch(function (jqXhr) {
        if (jqXhr.serverErrorMessages) {
          serverErrors.value = (jqXhr.serverErrorMessages);
        } else if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnexpectedErrorWhileUpdatingRequest', null, jqXhr);
        }

        return Promise.reject(jqXhr);
      })
      .finally(() => {
        saving.value = false;
      });
}

function setSavedDynamicFields(requestDataModel) {
  // Remove data that doesn't need to be submitted
  requestDataModel.dynamicRequestFormDefinition = null;
  requestDataModel.dynamicRequestFormData = null;
  requestDataModel.dynamicResponseFormDefinition = null;
  requestDataModel.dynamicResponseAllowUploads = null;
  requestDataModel.dynamicResponseUploadsIncludeCameraSelection = null;
  requestDataModel.dynamicResponseConfirmationText = null;

  requestDataModel.dynamicResponseFormData = JSON.stringify(responseFormData.value);
}

function setAssignee(requestDataModel) {
  requestDataModel.assignedPersonaId = selectedAssignee.value &&
  selectedAssignee.value.assigneeType === constants.requestAssigneeTypes.user ? selectedAssignee.value.id : null;
  requestDataModel.assignedGroupId = selectedAssignee.value &&
  selectedAssignee.value.assigneeType === constants.requestAssigneeTypes.group ? selectedAssignee.value.id : null;
}

function getRequestDataWithUploadIds(uploadsArray: BasePartnerUploadDataModel[], request: Request) {

  let uploadIds = uploadsArray.flatMap(u => u.getIds());

  // Remove the upload Ids where they are null due to an upload failing authorisation
  uploadIds = uploadIds.filter(function (uploadId) {
    return uploadId !== null;
  });

  const requestData = { ...request };
  requestData.submittedUploadIds = uploadIds;
  return requestData;
}

function getSelectedRequestAssignee() {
  if (props.request.assignedPersonaId) {
    return props.requestAssignees.find(item => {
      return item.assigneeType === constants.requestAssigneeTypes.user && item.id === props.request.assignedPersonaId;
    }) || {};
  }

  if (props.request.assignedGroupId) {
    return props.requestAssignees.find(item => {
      return item.assigneeType === constants.requestAssigneeTypes.group && item.id === props.request.assignedGroupId;
    }) || {};
  }

  return {};
}
</script>
