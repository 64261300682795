<template>
  <div
    class="modal show"
    tabindex="-1"
    role="dialog"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <div v-if="isUploading && !uploadsFailed">
            <h3>{{ $localize('SessionOverlayTitleUploading') }}</h3>
            <h5>{{ $localize('SessionOverlaySubTitleUploading') }}</h5>
          </div>
          <div v-else-if="!isUploading && !uploadsFailed">
            <h3>{{ $localize('SessionOverlayTitleUploaded') }}</h3>
            <h5>{{ $localize('SessionOverlaySubTitleUploadedOrFailed') }}</h5>
          </div>
          <div v-else>
            <h3>{{ $localize('SessionOverlayTitleUploadFailed') }}</h3>
            <h5>{{ $localize('SessionOverlaySubTitleUploadedOrFailed') }}</h5>
          </div>
        </div>
        <div class="modal-body file-upload-data-container">
          <div class="row-data-container px-5">
            <div class="status-icon-container">
              <div class="status-icon">
                <button
                  class="status-icon"
                  :aria-describedby="iconAriaDescription"
                >
                  <span
                    id="iconAriaDescription"
                    class="sr-only"
                  >{{ iconAriaDescription }}</span>
                  <i
                    v-if="isUploading && !uploadsFailed"
                    class="far fa-long-arrow-up text-primary"
                    aria-hidden="true"
                  />
                  <i
                    v-else-if="!isUploading && !uploadsFailed"
                    class="far fa-check text-success"
                    aria-hidden="true"
                  />
                  <i
                    v-else
                    class="far fa-long-arrow-up text-not-uploaded"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
            <div class="file-info-container">
              <span
                v-if="isUploading"
                class="hidden-xs"
              >{{ $localize('UploadingItems', { itemCount: uploadsCount, percent: allUploadsProgress }) }}</span>

              <span
                v-else
                class="hidden-xs"
              >{{ $localize('UploadedItems', { itemCount: completedUploadsCount }) }}</span>

              <div
                class="progress"
                role="progressbar"
                aria-valuemin="0"
                aria-valuemax="100"
                :aria-valuenow="allUploadsProgress"
                :aria-label="progressLabel"
              >
                <div
                  class="progress-bar"
                  :style="{ width: uploadProgress }"
                  :class="{
                    'bg-success': allUploadsComplete && !uploadsFailed,
                    'bg-primary': isUploading,
                    'bg-danger': allUploadsComplete && uploadsFailed
                  }"
                />
              </div>
              <span
                v-if="uploadsFailed"
                class="hidden-xs"
              >{{ $localize('FailedToUploadItems', { itemCount: failedUploadsCount }) }}</span>
            </div>
          </div>
          <hr>
          <div>
            <p v-if="isUploading && !uploadsFailed">
              {{ $localize('SessionOverlayTextUploading') }}
            </p>
            <p v-else-if="!isUploading && !uploadsFailed">
              {{ $localize('SessionOverlayTextUploaded') }}
            </p>
            <p v-else>
              {{ $localize('SessionOverlayTextUploadFailed') }}
            </p>
            <p v-if="isUploading">
              <a
                ref="newTabLink"
                href="/"
                :aria-describedby="linkAriaDescription"
                @click.prevent="continueInNewTabClick"
              >
                <span>
                  <strong>{{ $localize('SessionOverlayLinkNewTab') }}</strong>
                  <i
                    class="far fa-external-link ml-3"
                    aria-hidden="true"
                  />
                </span>
              </a>
            </p>
            <p v-else>
              <span
                id="linkAriaDescription"
                class="sr-only"
              >{{ linkAriaDescription }}</span>
              <a
                ref="continueHereLink"
                href="/"
                :aria-describedby="linkAriaDescription"
                @click.prevent="continueHereClick"
              >
                <span>
                  <strong>{{ $localize('SessionOverlayLinkHere') }}</strong>
                </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, onUnmounted, onMounted, nextTick, watch } from 'vue';
import { useRouter } from 'vue-router';
import uploadManager from '@/Utils/uploadManager';
import redirectHelper from '@/Utils/redirectHelper';
import resourceHelper from '@/Utils/resourceHelper';

const router = useRouter();
const continueHereLink = ref<HTMLElement | null>(null);
const newTabLink = ref<HTMLElement | null>(null);

onUnmounted(() => {
  document.body.classList.remove('modal-opened');
});

onMounted(async () => {
  document.body.classList.add('modal-opened');
  await nextTick();
  if (newTabLink.value) {
    newTabLink.value.focus();
  }
});

const uploadsCount = ref(uploadManager.uploadsCount());
uploadManager.uploadsCount.subscribe(() => {
  uploadsCount.value = uploadManager.uploadsCount();
});
const allUploadsProgress = ref(uploadManager.allUploadsProgress());
uploadManager.allUploadsProgress.subscribe(() => {
  allUploadsProgress.value = uploadManager.allUploadsProgress();
});
const allUploadsComplete = ref(uploadManager.allUploadsComplete());
uploadManager.allUploadsComplete.subscribe(() => {
  allUploadsComplete.value = uploadManager.allUploadsComplete();
});

watch(allUploadsComplete, newValue => {
  if (newValue) {
    nextTick(() => {
      continueHereLink.value?.focus();
    });
  }
});
const uploadsFailed = ref(uploadManager.uploadsFailed());
uploadManager.uploadsFailed.subscribe(() => {
  uploadsFailed.value = uploadManager.uploadsFailed();
});
const failedUploadsCount = ref(uploadManager.failedUploadsCount());
uploadManager.failedUploadsCount.subscribe(() => {
  failedUploadsCount.value = uploadManager.failedUploadsCount();
});
const completedUploadsCount = ref(uploadManager.completedUploadsCount());
uploadManager.completedUploadsCount.subscribe(() => {
  completedUploadsCount.value = uploadManager.completedUploadsCount();
});
const progressLabel = ref(resourceHelper.getString('UploadProgress'));

const linkAriaDescription = computed(() => {
  return uploadsFailed.value ? [
    resourceHelper.getString('FailedToUploadItems', { itemCount: failedUploadsCount.value }),
    resourceHelper.getString('SessionOverlayLinkHere')
  ].join(' ') : resourceHelper.getString('SessionOverlayLinkHere');
});

const iconAriaDescription = computed(() => {
  return isUploading.value ?
    resourceHelper.getString('UploadingItems', { itemCount: uploadsCount.value, percent: allUploadsProgress.value }) :
    resourceHelper.getString('UploadedItems', { itemCount: completedUploadsCount.value });
});

const isUploading = computed(() => {
  return allUploadsProgress.value >= 0 && allUploadsProgress.value <= 100 && !allUploadsComplete.value;
});

const uploadProgress = computed(() => {
  if (allUploadsComplete.value) {
    return '100%';
  }
  return allUploadsProgress.value + '%';
});

const continueInNewTabClick = () => {
  redirectHelper.saveRouteHash();
  const url = router.resolve({ path: '/' }).href;
  window.open(url, '_blank');
};

const continueHereClick = redirectHelper.tokenLoginRedirect;
</script>
