<template>
  <div class="form-container">
    <h2>{{ $localize('EditCameraTitle') }}</h2>
    <CameraEditForm
      ref="editForm"
      v-model:camera-name="cameraModel.name"
      v-model:internal-name="cameraModel.internalName"
      v-model:camera-make="cameraModel.make"
      v-model:camera-model="cameraModel.model"
      v-model:camera-notes="cameraModel.notes"
      v-model:camera-coordinate="cameraModel.coordinate"
      :server-errors="serverErrors"
      :validation-rules="camera.validationRules"
    />

    <div class="row">
      <div class="col-xs-12 text-right col-xs-margin-top">
        <VueButton
          :type="VueButtonTypes.default"
          :disabled="saving"
          :loading="deleting"
          @click="showDeleteModalDialog"
        >
          {{ $localize('DeleteCamera') }}
        </VueButton>

        <VueButton
          :type="VueButtonTypes.primary"
          :loading="saving"
          :disabled="deleting"
          class="ml-1"
          aria-describedby="cameraNameValidationMessage internalNameValidationMessage cameraMakeValidationMessage
          cameraModelValidationMessage cameraDescriptionValidationMessage serverErrorValidationMessages"
          @click="saveCamera"
        >
          {{ $localize('SaveCamera') }}
        </VueButton>
      </div>
    </div>
  </div>

  <ModalDialog
    v-if="showDeleteWarningModal"
    :is-danger="true"
    :title="$localize('CameraDeleteModalTitle')"
    @close="closeDeleteModalDialog"
  >
    <template #default>
      <div>{{ $localize('CameraDeleteModalMessage') }}</div>
      <br>
      <div>{{ $localize('CameraDeleteModalQuestion') }}</div>
    </template>
    <template #footer>
      <VueButton
        :type="VueButtonTypes.default"
        @click="closeDeleteModalDialog"
      >
        {{ $localize('Cancel') }}
      </VueButton>
      <VueButton
        :type="VueButtonTypes.danger"
        @click="deleteCamera"
      >
        {{ $localize('Continue') }}
      </VueButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue';
import CameraEditForm from '@/VueComponents/Cameras/CameraDetails/CameraEditForm.vue';
import cameraRepository from '@/Repositories/cameraRepository';
import logger from '@/Utils/logger';
import { useRouter } from 'vue-router';
import VueButton from '@/VueComponents/SharedComponents/Buttons/VueButton.vue';
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
import CameraDetails from '@/Types/cameraDetails';

const router = useRouter();
const props = defineProps<{
  camera: CameraDetails
}>();

const cameraModel = reactive({
  name: props.camera.cameraName,
  internalName: props.camera.internalName,
  make: props.camera.cameraMake,
  model: props.camera.cameraModel,
  notes: props.camera.description,
  coordinate: {
    longitude: props.camera.coordinate.longitude,
    latitude: props.camera.coordinate.latitude
  }
});

const editForm = ref(null);
const saving = ref(false);
const deleting = ref(false);
const serverErrors = ref([]);

const showDeleteWarningModal = ref(false);

async function saveCamera() {
  if (!(await editForm.value.isValid())) {
    return;
  }

  saving.value = true;
  serverErrors.value = [];

  const request = {
    businessId: props.camera.businessId,
    cameraId: props.camera.cameraId,
    rowversion: props.camera.rowversion,
    cameraName: cameraModel.name,
    internalName: cameraModel.internalName,
    cameraMake: cameraModel.make,
    cameraModel: cameraModel.model,
    description: cameraModel.notes,
    coordinate: cameraModel.coordinate
  };

  cameraRepository.updateCamera(request)
      .then(function () {

        logger.success('CameraUpdatedSummary');

        router.push('/cameras');
      })
      .catch(function (jqXhr) {
        saving.value = false;

        if (jqXhr.serverErrorMessages) {
          serverErrors.value = jqXhr.serverErrorMessages;
          return;
        }

        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnhandledError', 'An unexpected error occurred while attempting to update the camera.', jqXhr);
        }
      });
}

function deleteCamera() {
  showDeleteWarningModal.value = false;

  deleting.value = true;
  serverErrors.value = [];

  cameraRepository.deleteCamera(props.camera.cameraId)
      .then(function () {
        logger.success('CameraDeletedSummary');
        router.push('/cameras');
      })
      .catch(function (jqXhr) {
        deleting.value = false;

        if (jqXhr.serverErrorMessages) {
          serverErrors.value = jqXhr.serverErrorMessages;
          return;
        }

        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnexpectedErrorWhileDeletingCamera', null, jqXhr);
        }
      });
}

function showDeleteModalDialog() {
  showDeleteWarningModal.value = true;
}

function closeDeleteModalDialog() {
  showDeleteWarningModal.value = false;
}
</script>
