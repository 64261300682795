export default class FileModel {
  name: string;
  path: string;
  file: File;

  get fileExtension(): string {
    return this.name.split('.')[1];
  }

  constructor(name: string, path: string, file: File) {
    this.name = name;
    this.file = file;
    this.path = path;
  }

  static fromFile(file: File): FileModel {
    const path = file.webkitRelativePath ? file.webkitRelativePath : file.name;
    return new FileModel(file.name, path, file);
  }
}