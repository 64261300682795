<template>
  <ModalDialog
    :title="$localize('ConfirmResponseModalTitle')"
    :is-danger="false"
    @close="emit('close')"
  >
    <template #default>
      <strong>
        {{ $localize('ConfirmResponseModalMessage', { investigatorName: investigatorName, uploadedFilesCount: uploadsCount }) }}
      </strong>

      <hr>

      <div v-if="confirmationText">
        {{ confirmationText }}
      </div>
      <div v-else>
        {{ $localize('ConfirmResponseModalTandCs') }}
      </div>
    </template>
    <template #footer>
      <VueButton
        :type="VueButtonTypes.default"
        @click="emit('close')"
      >
        {{ $localize('Cancel') }}
      </VueButton>
      <VueButton
        :type="VueButtonTypes.primary"
        @click="emit('continue')"
      >
        {{ $localize('Continue') }}
      </VueButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
import VueButton from '@/VueComponents/SharedComponents/Buttons/VueButton.vue';
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';

defineProps<{
  investigatorName: string,
  uploadsCount: number,
  confirmationText?: string
}>();

const emit = defineEmits(['close', 'continue']);
</script>

<style scoped>

</style>