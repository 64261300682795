<template>
  <div class="table-responsive">
    <table class="table table-hover table-investigate">
      <thead>
        <tr>
          <th
            scope="col"
            style="width: 25%;"
          >
            {{ $localize('FileName') }}
          </th>
          <th
            scope="col"
            style="width: 10%"
          >
            {{ $localize('Size') }}
          </th>
          <th
            scope="col"
            style="width: 55%"
          >
            {{ $localize('Comments') }}
          </th>
          <th
            v-if="!readonly"
            scope="col"
            style="width: 10%"
          >
            {{ $localize('Download') }}
          </th>
        </tr>
      </thead>
      <tbody
        v-for="(file, index) in files"
        :key="index"
      >
        <tr>
          <td class="wrap-text">
            {{ file.fileName }}
          </td>
          <td>{{ FileSizeFormatter.getFormattedFileSize(file.fileSize) }}</td>
          <td
            class="wrap-text"
          >
            {{ file.comments }}
          </td>
          <td
            v-if="!readonly"
          >
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="download(file)"
            >
              <span class="fas fa-download" />
              <span>{{ $localize('Download') }}</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts" setup>
import FileSizeFormatter from '@/Utils/fileSizeFormatter';
import associatedFileRepository from '@/Repositories/associatedFileRepository';

defineProps<{
  readonly?: boolean,
  files: any[]
}>();

function download(file) {
  associatedFileRepository.downloadAssociatedFileById(file.id);
}
</script>

<style scoped>

</style>