<template>
  <div>
    <div v-if="!hideUploadSelector">
      <h4>{{ $localize('Upload') }}</h4>
      <div v-if="!uploadParentItemIsCompleted">
        <p>{{ $localize('ChooseItemTypeToUpload') }}</p>
        <p>
          {{
            $localize('MaximumUploadsSizeExplanatoryText', {
              maximumUploadSize: FileSizeFormatter.getFormattedFileSize(PortalSettingsProvider.getMaxUploadSize()),
              maximumCctvFolderUploadSize: FileSizeFormatter.getFormattedFileSize(PortalSettingsProvider.getMaxCctvFolderUploadSize()),
              limitNumberOfUploads : uploadsLimit})
          }}
        </p>
        <p v-if="!hideDefaultExplanatoryText">
          {{ $localize('CCTVFolderExportExplanatoryDefaultText') }}
        </p>
        <p v-if="uploadGuidance">
          {{ uploadGuidance }}
        </p>
        <BusinessUploadSelectorSection
          v-if="isBusinessRequest"
          @add-files="onFilesAdded"
          @add-folders="onFoldersAdded"
        />
        <FileUploadSelector
          v-else
          :upload-parent-item-is-completing="uploadParentItemIsCompleting"
          :upload-parent-item-type="uploadParentItemType"
          :upload-parent-item-is-discarding="uploadParentItemIsDiscarding"
          @to-zip-uploads-added="addFolderAsZipUploads"
          @file-uploads-added="addFileUploads"
        />
      </div>
    </div>
    <h3
      v-else-if="uploads.length"
      class="mt-1"
    >
      {{ $localize('UploadedFiles') }}
    </h3>
    <ItemsLoadingSpinner
      v-if="loadingData"
    />
    <LoadingErrorDisclaimer
      v-else-if="loadingFailed"
    >
      {{ $localize('FailedToRetrieveUploads') }}
    </LoadingErrorDisclaimer>
    <div v-if="!loadingFailed && !loadingData && uploads.length > 0">
      <uploads-list
        v-if="isBusinessRequest"
        v-model="uploads"
        :include-camera-selection="uploadParentItemIncludesCameraSelection"
        :is-read-only="uploadParentItemHasResponse"
        :camera-names="cameraNames"
      />
      <uploads-table
        v-else
        v-model="uploads"
        :local-storage-page-size-key="pageSizeStorageKey"
        :upload-parent-item-is-completed="uploadParentItemIsCompleted"
        :allow-to-manage-metadata="allowToManageMetadata"
        @edit-metadata-click="editMetadataClick"
      />
    </div>
    <UploadLimitModal
      v-if="isShowingLimitsModal"
      v-bind="limitsModalProps"
      :limit-number-of-uploads="uploadsLimit"
      :upload-parent-item-type="uploadParentItemType"
      @close="closeLimitsModal"
    />
    <ListOfDuplicatedUploadsModal
      v-if="isShowingDuplicatesModal"
      v-bind="duplicatesModalProps"
      @close="closeDuplicatesModal"
      @continue="onContinueDuplicatesModal"
    />
    <ConfirmCctvExportUploadModal
      v-if="isShowingCctvConfirmModal"
      v-bind="cctvConfirmModalProps"
      @cancel="closeCctvConfirmModal"
      @confirm="onCctvConfirm"
    />
    <EditUploadsMetadataModal
      v-if="showEditMetadataModal"
      :uploads="uploadsForEditingMetadata"
      :upload-parent-id="getRequestId()"
      :upload-parent-item-type="uploadParentItemType"
      :upload-parent-item-is-completed="uploadParentItemIsCompleted"
      :is-danger="false"
      :is-wide="true"
      @close="showEditMetadataModal = false"
      @saved="showEditMetadataModal = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onUnmounted, ref } from 'vue';
import FileSizeFormatter from '@/Utils/fileSizeFormatter';
import FileUploadSelector from '@/VueComponents/FileUploadSelector/FileUploadSelector.vue';
import PortalSettingsProvider from '@/Utils/portalSettingsProvider';
import ListOfDuplicatedUploadsModal from '@/VueComponents/Modals/ListOfDuplicatedUploadsModal.vue';
import EditUploadsMetadataModal from '@/VueComponents/Modals/EditUploadsMetadataModal.vue';
import logger from '@/Utils/logger';
import uploadFileLimitResolver from '@/Utils/uploadFileLimitResolver';
import UploadLimitModal from '@/VueComponents/Modals/UploadLimitModal.vue';
import ItemsLoadingSpinner from '@/VueComponents/Loading/ItemsLoadingSpinner.vue';
import LoadingErrorDisclaimer from '@/VueComponents/Loading/LoadingErrorDisclaimer.vue';
import UploadSummaryModel from '@/VueComponents/Uploads/models/uploadSummaryModel';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import UploadModel from '@/Models/uploadModel';
import { LocalStorageKeyType } from '@/Types/Enums/localStorageKeyType';
import UploadsTable from '@/VueComponents/Uploads/UploadsTable.vue';
import UploadsList from '@/VueComponents/Uploads/UploadsList.vue';
import businessRepository from '@/Repositories/businessRepository';
import useUploadLoader from '@/VueComponents/Uploads/utils/useUploadLoader';
import constants from '@/constants';
import uploadManager from '@/Utils/uploadManager';
import { removeIndividualItemFromTheUploadsArrayById } from '@/VueComponents/Uploads/utils/useUploadRemoving';
import BasePartnerUploadDataModel from '@/Models/basePartnerUploadDataModel';
import { useUploadRemovingApi } from '@/VueComponents/Uploads/utils/useUploadRemovingApi';
import UploadRepository from '@/Repositories/uploadRepository';
import BusinessUploadSelectorSection from '@/VueComponents/FileUploadSelector/BusinessUploadSelectorSection.vue';
import FileUploadModel from '@/VueComponents/FileUploadSelector/models/fileUploadModel';
import useUploadValidator from '@/VueComponents/Uploads/utils/useUploadValidator';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import useModalController from '@/VueCore/utils/useModalController';
import FolderUploadModel from '@/VueComponents/FileUploadSelector/models/folderUploadModel';
import useFileUploader from '@/VueComponents/Uploads/utils/useFileUploader';
import requestUploadValidation from '@/Validation/requestUploadValidation';
import { FileUploadType } from '@/VueComponents/FileUploadSelector/enums/fileUploadType';
import ConfirmCctvExportUploadModal from '@/VueComponents/Modals/ConfirmCctvExportUploadModal.vue';
import { FolderUploadType } from '@/VueComponents/FileUploadSelector/enums/folderUploadType';

const contextData = useContextDataStore();
const emit = defineEmits<{(e: 'uploadFailedToAdd', errorMessage: string): void,
  (e: 'beforeUploadStarted'): void,
  (e: 'serverError', errors: string[]): void
}>();
const props = defineProps<{
    uploadParentItemType: UploadParentItemType,
    uploadParentItemIsCompleted: boolean,
    uploadParentItemIncludesCameraSelection?: boolean,
    uploadParentItemHasResponse?: boolean,
    uploadParentItemIsCompleting: boolean,
    uploadParentItemIsDiscarding: boolean,
    allowToManageMetadata: boolean,
    uploadGuidance?: string,
    hideUploadSelector?: boolean,
    hideDefaultExplanatoryText?: boolean
}>();

const camerasLoading = ref(false);
const cameraNames = ref([]);
const loadingData = computed(() => {
  return loading.value || camerasLoading.value;
});

const isBusinessRequest = props.uploadParentItemType === UploadParentItemType.businessRequest;

const model = defineModel<BasePartnerUploadDataModel[]>({ default: () => [] });

const { uploads, load, loading, loadingFailed } = useUploadLoader(getRequestId(),
    props.uploadParentItemType, model.value);
useUploadRemovingApi(UploadRepository.deleteUploads);

const uploadsLimit = uploadFileLimitResolver.getUploadFileLimitByRequestType(props.uploadParentItemType);
const { validateFiles, isUploadLimitExceeded,
  getTotalNumberOfUploads, getFileDuplicates, validateFolders } = useUploadValidator(uploads,
    contextData.portalSettings.maximumUploadSize,
    contextData.portalSettings.maximumCctvFolderUploadSize,
    uploadsLimit);

const { close: closeLimitsModal, show: showLimitsModal, isShowing: isShowingLimitsModal, props: limitsModalProps } =
  useModalController<{
  existedUploads: number,
  newUploads: number
}>();

const { close: closeDuplicatesModal, show: showDuplicatesModal,
  isShowing: isShowingDuplicatesModal, props: duplicatesModalProps } =
  useModalController<{
    duplicatedFiles: FileUploadModel[],
    nonDuplicatedFiles: FileUploadModel[]
  }>();

const { close: closeCctvConfirmModal, show: showCctvConfirmModal,
  isShowing: isShowingCctvConfirmModal, props: cctvConfirmModalProps } =
  useModalController<{
    files?: FileUploadModel[],
    folders?: FolderUploadModel[]
  }>();

const { uploadFiles, uploadFilesObsolete } = useFileUploader(uploads, getRequestId(), props.uploadParentItemType);

if (isBusinessRequest) {
  camerasLoading.value = true;
  businessRepository.getCameraNames()
      .then(cameras => {
        cameraNames.value = cameras.map(c => c.cameraName);
      })
      .finally(() => {
        camerasLoading.value = false;
      });
}

const pageSizeStorageKey = computed(() => {
  if (props.uploadParentItemType === UploadParentItemType.partnerRequest) {
    return LocalStorageKeyType.PaginationPartnerRequestUploadsPageSize;
  }
  if (props.uploadParentItemType === UploadParentItemType.selfResponse) {
    return LocalStorageKeyType.PaginationUploadFolderUploadsPageSize;
  }

  return null;
});

const pTable = ref(null);
const showEditMetadataModal = ref(false);

let uploadsForEditingMetadata: UploadSummaryModel[];

load();

const uploadCancelledSubscription = uploadManager.onUploadCancelled((uploadModel: UploadModel) => {
  if (!uploadModel?.uploadId) {
    return;
  }

  removeIndividualItemFromTheUploadsArrayById(uploadModel.uploadId, uploads);
});

onUnmounted(() => {
  uploadCancelledSubscription.dispose();
});

function editMetadataClick(selectedItems: BasePartnerUploadDataModel[]) {
  const invalidUploadsSelected = uploads.some(upload =>
    upload.statusName === constants.uploadStatuses.failed ||
    upload.statusName === constants.uploadStatuses.failedAuthorisation ||
    upload.statusName === constants.uploadStatuses.authorising ||
    upload.statusName === constants.uploadStatuses.initializing);

  if (invalidUploadsSelected) {
    logger.warning('EditMetadataProhibitedWarning');
    return;
  }

  uploadsForEditingMetadata = uploads
      .map(upload => new UploadSummaryModel(upload.uploadId, upload.fileName, upload.type,
          selectedItems.includes(upload)));

  showEditMetadataModal.value = true;
}

// TODO: This method should be retired when partner request file upload selector is migrated
//  to the new generic FileSelector
function addFileUploads(files: FileList) {
  emit('beforeUploadStarted');
  const isUploadValid = validateFileUploads(files);
  if (!isUploadValid) {
    return;
  }

  if (isUploadLimitExceeded(files)) {
    showLimitsModal({
      existedUploads: getTotalNumberOfUploads(),
      newUploads: files.length
    });
  } else {
    const duplicateInfo = requestUploadValidation.getFileDuplicates(files,
        uploads.flatMap(u => u.getUploadModels()),
        props.uploadParentItemType);

    if (duplicateInfo.duplicates.length) {

      // FileUploadType doesn't matter in this case, all the information about type
      // is stored in the native File
      showDuplicatesModal({
        duplicatedFiles: duplicateInfo.duplicates
            .map(f => FileUploadModel.fromFileWithType(f, FileUploadType.generalContentFile)),
        nonDuplicatedFiles: duplicateInfo.notDuplicates
            .map(f => FileUploadModel.fromFileWithType(f, FileUploadType.generalContentFile))
      });
    } else {
      uploadFilesObsolete(files, onServerError);
      (pTable.value as any)?.selectPage(1);
    }
  }
}

// TODO: This method should be retired when partner request file upload selector is migrated
//  to the new generic FileSelector
function addFolderAsZipUploads(fileList: FileList) {
  emit('beforeUploadStarted');
  const isCctvFolderUploadValid = validateFileUploads(fileList);
  if (!isCctvFolderUploadValid) {
    return;
  }

  if (isUploadLimitExceeded(fileList)) {
    showLimitsModal({
      existedUploads: getTotalNumberOfUploads(),
      newUploads: fileList.length
    });
  } else {
    uploadFilesObsolete(fileList, onServerError);
    (pTable.value as any)?.selectPage(1);
  }
}

function validateFileUploads(fileList: FileList) {
  const message =
            requestUploadValidation.getAddUploadsValidationResourceInfo(fileList, uploads);

  if (message) {
    emit('uploadFailedToAdd', message);
    return false;
  }
  return true;
}

function getRequestId() {
  const urlParts = window.location.href.split('/');
  const requestId = urlParts[urlParts.length - 1].split('?')[0];
  return requestId;
}

function onContinueDuplicatesModal(files: FileUploadModel[]) {
  closeDuplicatesModal();

  if (!files.length) {
    logger.info('UploadsAreDuplicatesAndSkipped');
    return;
  }

  emit('beforeUploadStarted');

  if (!isBusinessRequest) {
    // Non-business request files contain additional information about the file type
    // FileModelUpload type does not represent accurate value in this case.
    // To be deprecated when partner request file selector is migrated to the new approach
    uploadFilesObsolete(files.map(f => f.file), onServerError);
    (pTable.value as any)?.selectPage(1);
    return;
  }

  if (files.some(f => f.type === FileUploadType.zipCctv)) {
    showCctvConfirmModal({
      files: files
    });
    return;
  }

  uploadFiles(files, [], onServerError);
  (pTable.value as any)?.selectPage(1);
}

function onServerError(e) {
  if (e.serverErrorMessages) {
    emit('serverError', e.serverErrorMessages);
    return;
  }
  logger.error('FailedAttemptToAddFileUpload');
}


function onFilesAdded(files: FileUploadModel[]) {

  if (props.uploadParentItemIsCompleting) {
    logger.warning('UploadIsNotAllowedForSubmittedRequest');
    return;
  }

  if (props.uploadParentItemIsDiscarding) {
    logger.warning('UploadIsNotAllowedForRejectedRequest');
    return;
  }

  emit('beforeUploadStarted');

  const validationResult = validateFiles(files);
  if (!validationResult.isValid) {
    emit('uploadFailedToAdd', validationResult.errorMessage ?? '');
    return;
  }


  if (isUploadLimitExceeded(files)) {
    showLimitsModal({
      existedUploads: getTotalNumberOfUploads(),
      newUploads: files.length
    });
    return;
  }

  const duplicateInfo = getFileDuplicates(files);
  if (duplicateInfo.duplicates.length) {

    showDuplicatesModal({
      duplicatedFiles: duplicateInfo.duplicates,
      nonDuplicatedFiles: duplicateInfo.notDuplicates
    });
    return;
  }

  if (files.some(f => f.type === FileUploadType.zipCctv)) {
    showCctvConfirmModal({
      files: files
    });
    return;
  }

  uploadFiles(files, [], onServerError);
  (pTable.value as any)?.selectPage(1);
}

function onFoldersAdded(folders: FolderUploadModel[]) {

  if (isBusinessRequest && props.uploadParentItemIsCompleting) {
    logger.warning('UploadIsNotAllowedForSubmittedRequest');
    return;
  }

  if (isBusinessRequest && props.uploadParentItemIsDiscarding) {
    logger.warning('UploadIsNotAllowedForRejectedRequest');
    return;
  }

  emit('beforeUploadStarted');

  const validationResult = validateFolders(folders);
  if (!validationResult.isValid) {
    emit('uploadFailedToAdd', validationResult.errorMessage ?? '');
    return;
  }

  const files = folders.flatMap(f => f.generateFileUploadModels());
  if (isUploadLimitExceeded(files)) {
    showLimitsModal({
      existedUploads: getTotalNumberOfUploads(),
      newUploads: files.length
    });
    return;
  }

  if (folders.some(f => f.type === FolderUploadType.cctv)) {
    showCctvConfirmModal({
      folders: folders
    });
    return;
  }

  uploadFiles([], folders, onServerError);
  (pTable.value as any)?.selectPage(1);
}

function onCctvConfirm(files: FileUploadModel[], folders: FolderUploadModel[]) {
  closeCctvConfirmModal();

  if (!files?.length && !folders?.length) {
    return;
  }

  uploadFiles(files, folders, onServerError);
  (pTable.value as any)?.selectPage(1);
}
</script>
