import { ref, Ref, computed } from 'vue';
import { FilterItemValueDto } from '@/VueComponents/Filter/Dtos/filterItemValueDto';

export class FilterItemValue {

  public value: string;
  public title: string;
  public titleKey: string;
  public type: string;
  public isChecked: Ref<boolean>;
  private lastCheckedValue: boolean;
  public counter: Ref<number>;
  public position: number;
  public initialValue: Ref<string>;
  public currentValue: Ref<string>;
  public externalValueChangeHandler: () => void;

  constructor(dto: FilterItemValueDto, filterValueChangeHandler: () => void) {
    this.value = dto.value;
    this.title = dto.title;
    this.titleKey = dto.titleKey;
    this.type = dto.type;
    this.isChecked = ref(dto.isChecked || false);
    this.position = dto.position;
    this.lastCheckedValue = this.isChecked.value;
    this.counter = ref(dto.counter);
    this.initialValue = ref(this.value);
    this.currentValue = ref(this.value);
    this.externalValueChangeHandler = filterValueChangeHandler;
  }

  public onCheckBoxInputChanged = (): void => {
    this.lastCheckedValue = this.isChecked.value;
    this.externalValueChangeHandler();
  };

  public updateCheckedValueSilently = (value: boolean): void => {
    this.isChecked.value = value;
    this.lastCheckedValue = value;
  };

  public updateTextValueSilently = (value: string): void => {
    this.currentValue.value = value;
    this.initialValue.value = value;
    this.value = value;
  };

  public getLastCheckedValue = (): boolean => {
    return this.lastCheckedValue;
  };

  public resetChecked = (): void => {
    this.isChecked.value = false;
    this.lastCheckedValue = this.isChecked.value;
  };

  public resetCounter = (): void => {
    this.setCounter(0);
  };

  public setCounter = (counter: number): void => {
    this.counter.value = counter;
  };

  public formattedCounter = computed((): string => {
    return this.counter.value > 0 ? `(${this.counter.value})` : '';
  });

  public hasNoChanges = computed((): boolean => {
    if (this.value === undefined) {
      if (this.currentValue.value) {
        return false;
      }
      return true;
    }
    return this.initialValue.value === this.currentValue.value;
  });

  public applyFilter = (): void => {
    this.value = this.currentValue.value;
    this.externalValueChangeHandler();
    this.resetInitialValue();
  };

  public resetInitialValue = (): void => {
    this.initialValue.value = this.value;
  };
}