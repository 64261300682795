<template>
  <ModalDialog
    :title="$localize('BusinessUserAdminRightsSelfRemovalModalTitle')"
    :is-danger="true"
    @close="onModalClose"
  >
    <template #default>
      <div>
        {{ $localize('BusinessUserAdminRightsSelfRemovalModalMessage') }}
      </div>
      <br>
      <div>
        {{ $localize('BusinessUserModalQuestion') }}
      </div>
    </template>
    <template #footer>
      <button
        ref="closeBtn"
        class="btn btn-default"
        @click="emit('close');"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        ref="deleteBtn"
        class="btn btn-danger"
        @click="emit('update')"
      >
        {{ $localize('Continue') }}
      </button>
    </template>
  </ModalDialog>
</template>

<script setup lang="ts">
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
const emit = defineEmits < { 'close', 'update' }>();
</script>
