<template>
  <FilterArea
    v-model="selectedUserId"
    :entities="users"
    filter-area-class="filter-area-height-admin-users"
    :filter-fields="filterFields"
    :item-type="FilterAreaType.user"
    @change="selectUser"
  >
    <template #option-content="{ slotProps }">
      <div
        :id="slotProps.option.personaId"
        :user-id="slotProps.option.personaId"
        class="filter-area-list-item ellipsis"
        :class="{ 'active': slotProps.option.personaId === selectedUserId }"
      >
        <b :title="slotProps.option.givenName + ' ' + slotProps.option.familyName">
          {{ slotProps.option.givenName + ' ' + slotProps.option.familyName }}
        </b>
        <br>
        <div class="filter-item-with-icons">
          <small
            class="heading filter-area-item-heading ellipsis"
            :title="slotProps.option.emailAddress"
          >
            {{ slotProps.option.emailAddress }}
          </small>
          <div class="item-icons">
            <div v-if="slotProps.option.isBusinessPrimaryContact">
              <i
                v-tooltip.top="{
                  class: 'custom-tooltip-container',
                  value: $localize(AccountSpecificLabelsProvider.getAccountLabelKey('UserIsPrimaryBusinessExplanatoryText')),
                  pt: {
                    text: 'custom-tooltip-text',
                    arrow: 'custom-tooltip-arrow'
                  },
                  showDelay: 600
                }"
                class="far fa-envelope"
                aria-hidden="true"
              />
              <span class="sr-only">{{ $localize(AccountSpecificLabelsProvider.getAccountLabelKey('UserIsPrimaryBusinessExplanatoryText')) }}</span>
            </div>
            <div v-if="slotProps.option.isBusinessAdminUser">
              <i
                v-tooltip.top="{
                  class: 'custom-tooltip-container',
                  value: $localize('UserIsAdminExplanatoryText'),
                  pt: {
                    text: 'custom-tooltip-text',
                    arrow: 'custom-tooltip-arrow'
                  },
                  showDelay: 600
                }"
                class="fas fa-user-cog"
                aria-hidden="true"
              />
              <span class="sr-only">{{ $localize('UserIsAdminExplanatoryText') }}</span>
            </div>
            <div v-if="slotProps.option.isPreregistered">
              <i
                v-tooltip.top="{
                  class: 'custom-tooltip-container',
                  value: $localize('UserIsPreregisteredExplanatoryText'),
                  pt: {
                    text: 'custom-tooltip-text',
                    arrow: 'custom-tooltip-arrow'
                  },
                  showDelay: 600
                }"
                class="fas fa-user-slash"
                aria-hidden="true"
              />
              <span class="sr-only">{{ $localize('UserIsPreregisteredExplanatoryText') }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </FilterArea>
</template>

<script setup lang="ts">
import FilterArea from '@/VueComponents/SharedComponents/FilterArea/FilterArea.vue';
import { FilterAreaType } from '@/VueComponents/SharedComponents/FilterArea/Enums/filterAreaType';
import UserSummaryModel from '@/Models/userSummaryModel';
import AccountSpecificLabelsProvider from '@/Utils/accountSpecificLabelsProvider';

defineProps<{
    users: UserSummaryModel[]
  }>();

const selectedUserId = defineModel<string | null>();

const selectUser = (user: UserSummaryModel | null) => {
  selectedUserId.value = user?.personaId;
};

const filterFields = ['givenName', 'familyName', 'emailAddress'];
</script>

<style scoped lang="scss">
</style>