import UserGroupSelectionModel from '@/VueComponents/PageComponents/Users/Models/userGroupSelectionModel';

export default class UpdateUserDetailsModel {
  constructor(public givenName: string,
    public familyName: string,
    public phoneNumber: string,
    public isBusinessAdminUser: boolean,
    public isBusinessPrimaryContact: boolean,
    public businessUserGroupSelections: UserGroupSelectionModel[]
  ) { }
}