<template>
  <button
    :disabled="disabled || loading"
    type="button"
    :class="buttonClass"
    :aria-describedby="ariaDescribedby"
  >
    <span
      v-if="loading"
      class="far fa-circle-notch fa-spin"
      aria-hidden="true"
    />
    <i
      v-else-if="icon"
      :class="icon"
    />
    <span class="ml-1"><slot /></span>
  </button>
</template>

<script lang="ts" setup>
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';

const props = withDefaults(defineProps<{
  type?: VueButtonTypes,
  loading?: boolean,
  disabled?: boolean,
  icon?: string,
  ariaDescribedby?: string,
  additionalButtonClass?: string
}>(), { loading: false, disabled: false, icon: '', type: VueButtonTypes.default, ariaDescribedby: '', additionalButtonClass: '' });

let buttonClass = props.type;
if (props.additionalButtonClass) {
  buttonClass += ` ${props.additionalButtonClass}`;
}
</script>
