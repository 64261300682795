<template>
  <ModalDialog
    :title="$localize('SiteTandCsTitle')"
    :is-wide="false"
    :is-danger="false"
    @close="emit('close')"
  >
    <template
      #default
    >
      <div>
        {{ $localize('SiteTandCsText') }}
      </div>
    </template>
    <template #footer>
      <button
        ref="closeButton"
        class="btn btn-primary"
        @click="emit('close')"
      >
        {{ $localize('Close') }}
      </button>
    </template>
  </ModalDialog>
</template>

<script setup lang="ts">
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';

const emit = defineEmits<{ 'close' }>();

</script>