<template>
  <div>
    <h2> {{ $localize('RegistrationTitle') }}</h2>
    <p class="lead">
      {{ $localize('RegistrationLeadText') }}
    </p>
    <hr>
    <div class="row">
      <div class="col-xs-12 col-md-5">
        <TextInputField
          id="emailAddress"
          v-model="state.emailAddress"
          :label="$localize('EmailAddress')"
          :disabled="true"
        />
        <TextInputField
          id="givenName"
          v-model="state.givenName"
          :label="$localize('GivenName')"
          :validation-rules="validationRules.givenName"
          :required="true"
        />
        <TextInputField
          id="familyName"
          v-model="state.familyName"
          :label="$localize('FamilyName')"
          :validation-rules="validationRules.familyName"
          :required="true"
        />
        <TextInputField
          id="phoneNumber"
          v-model="state.phoneNumber"
          type="tel"
          :label="$localize('PhoneNumber')"
          :validation-rules="validationRules.phoneNumber"
        />
      </div>

      <div class="col-xs-12 col-md-5 col-md-offset-1">
        <TextInputField
          id="businessName"
          v-model="state.businessName"
          :label="$localize('BusinessName')"
          :validation-rules="validationRules.businessName"
          :disabled="state.businessIsFullyRegistered"
          :required="!state.businessIsFullyRegistered"
        />
        <TextAreaField
          id="businessAddress"
          v-model="state.businessAddress"
          rows="6"
          :label="$localize('BusinessAddress')"
          :validation-rules="validationRules.businessAddress"
          :disabled="state.businessIsFullyRegistered"
          :required="!state.businessIsFullyRegistered"
        />
        <span>{{ $localize('RequiredFieldInfo') }}</span>
        <CheckboxField
          id="acceptedTandCs"
          v-model="state.acceptedTandCs"
          :label="$localize('BusinessAddress')"
          :validation-rules="validationRules.businessAddress"
          class="mt-3"
        >
          <template #label>
            <span class="mr-1">{{ $localize('SiteTandCsLabelPart1') }}</span>
            <a
              href=""
              @click.prevent="state.showModal = true"
            >{{ $localize('SiteTandCsLabelPart2') }}</a>
          </template>
        </CheckboxField>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <ServerErrorList :errors="state.serverErrors" />
      </div>
      <div class="col-xs-12 col-md-11 text-right">
        <VueButton
          :type="VueButtonTypes.default"
          :loading="state.formSubmitted"
          class="mr-1"
          @click="redirectHelper.logoutRedirect()"
        >
          {{ $localize('Logout') }}
        </VueButton>

        <VueButton
          :type="VueButtonTypes.primary"
          :aria-describedby="buttonAriaDescribedby"
          @click="register"
        >
          {{ $localize('Register') }}
        </VueButton>
      </div>
    </div>
  </div>
  <SiteTandCsModal
    v-if="state.showModal"
    @close="state.showModal = false"
  />
</template>

<script setup lang="ts">
import { reactive } from 'vue';
import { useForm } from 'vee-validate';

import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import redirectHelper from '@/Utils/redirectHelper';
import registrationService from '@/VueCore/services/registrationService';

import SiteTandCsModal from '@/VueComponents/Modals/SiteTandCsModal.vue';
import TextAreaField from '@/VueComponents/SharedComponents/InputFields/TextAreaField.vue';
import TextInputField from '@/VueComponents/SharedComponents/InputFields/TextInputField.vue';
import VueButton from '@/VueComponents/SharedComponents/Buttons/VueButton.vue';
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';
import ServerErrorList from '@/VueComponents/SharedComponents/ErrorList/ServerErrorList.vue';
import { ContentSections } from '@/VueComponents/PageComponents/Registration/ContentSections/contentSections';
import CheckboxField from '@/VueComponents/SharedComponents/InputFields/CheckboxField.vue';
import { ApplicationError } from '@/VueCore/services/clients/applicationError';
import { PendingRegistration } from '@/Models/pendingRegistration';

const contextData = useContextDataStore();
const registrationData: PendingRegistration = contextData.registrationData;
const validationRules = registrationData.validationRules;
const contentToDisplay = defineModel<ContentSections>();

const state = reactive({
  registrationId: registrationData.registrationId,
  givenName: registrationData.givenName,
  familyName: registrationData.familyName,
  phoneNumber: registrationData.phoneNumber,
  businessId: registrationData.businessId,
  businessIsFullyRegistered: registrationData.businessIsFullyRegistered,
  businessName: registrationData.businessName,
  businessAddress: registrationData.businessAddress,
  emailAddress: registrationData.emailAddress,
  acceptedTandCs: false,
  showModal: false,
  formSubmitted: false,
  serverErrors: []
});

const form = useForm({
  initialValues: {
    givenName: state.givenName,
    familyName: state.familyName,
    phoneNumber: state.phoneNumber,
    businessAddress: state.businessAddress,
    businessName: state.businessName,
    acceptedTandCs: state.acceptedTandCs
  }
});

const buttonAriaDescribedby = 'givenNameValidationMessage familyNameValidationMessage phoneNumberValidationMessage businessNameValidationMessage' +
  'businessAddressValidationMessage acceptedTandCsValidationMessage serverErrorValidationMessages';

async function register() {
  registrationDataUpdate();
  await isValid();
  state.formSubmitted = true;
  state.serverErrors = [];

  registrationService.submitRegistration(state.registrationId, registrationData)
      .then(function () {
        state.formSubmitted = false;
        contentToDisplay.value = ContentSections.RegistrationComplete;
      })
      .catch(function (error: ApplicationError) {
        state.formSubmitted = false;

        if (error.errorData.errorMessages) {
          state.serverErrors = error.errorData.errorMessages;
          return;
        }

        contentToDisplay.value = ContentSections.RegistrationError;
      });
}

function registrationDataUpdate() {
  registrationData.givenName = state.givenName;
  registrationData.familyName = state.familyName;
  registrationData.phoneNumber = state.phoneNumber;
  registrationData.businessName = state.businessName;
  registrationData.businessAddress = state.businessAddress;
  registrationData.acceptedTandCs = state.acceptedTandCs;
}

defineExpose({
  isValid
});

async function isValid() {
  const validationResult = await form.validate();
  return validationResult.valid;
}
</script>

<style scoped lang="scss">
</style>