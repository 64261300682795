<template>
  <VueMap
    v-model:coordinate="coordinate"
    v-model:zoom="zoom"
    class="camera-map"
  >
    <VueMapDraggableMarker
      v-model:coordinate="cameraCoordinate"
      :marker-style="MapMarkerStyle.camera"
      @marker-move="onMarkerMoved"
    />
  </VueMap>
</template>
<script lang="ts" setup>
import MapCoordinate from '@/Types/mapCoordinate';
import { Ref } from 'vue';
import VueMap from '@/VueComponents/SharedComponents/Map/VueMap.vue';
import VueMapDraggableMarker from '@/VueComponents/SharedComponents/Map/VueMapDraggableMarker.vue';
import { MapMarkerStyle } from '@/VueComponents/SharedComponents/Map/Models/mapMarkerStyle';

const coordinate: Ref<MapCoordinate> = defineModel<MapCoordinate>('coordinate', { required: true });
const cameraCoordinate: Ref<MapCoordinate> = defineModel<MapCoordinate>('cameraCoordinate', { required: true });
const zoom: Ref<number> = defineModel<number>('zoom', { required: true });

const emit = defineEmits(['cameraMove']);

function onMarkerMoved(newCoords: MapCoordinate) {
  emit('cameraMove', newCoords);
}
</script>
<style scoped>
.camera-map {
  height: 375px;
}
</style>