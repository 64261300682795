<script setup lang="ts">
import { onBeforeMount } from 'vue';
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
import FileSizeFormatter from '@/Utils/fileSizeFormatter';
import resourceHelper from '@/Utils/resourceHelper';
import FilePathFormatter from '@/Utils/filePathFormatter';
import FileUploadModel from '@/VueComponents/FileUploadSelector/models/fileUploadModel';

const props = defineProps<{
  duplicatedFiles: FileUploadModel[],
  nonDuplicatedFiles: FileUploadModel[]
}>();

const emit = defineEmits<{'close', 'continue': [FileUploadModel[]]}>();
const maxRenderedItemsCount: number = 100;
const filesToRender: File[] = props.duplicatedFiles.slice(0, maxRenderedItemsCount).map(f => f.file);

let uploadDuplicatesDisclaimer = '';

function onModalClose() {
  emit('close');
}

function onContinue() {
  const files = [...props.duplicatedFiles, ...props.nonDuplicatedFiles];
  emit('continue', files);
}

function onSkipDuplicates() {
  emit('continue', props.nonDuplicatedFiles);
}

function formatFileSize(bytes: number): string {
  return FileSizeFormatter.getFormattedFileSize(bytes);
}

onBeforeMount(() => {
  uploadDuplicatesDisclaimer = resourceHelper.getString('UploadDuplicatesDisclaimer', { count: props.duplicatedFiles.length });
});
</script>

<template>
  <ModalDialog
    :title="$localize('ListOfDuplicatedFiles')"
    :is-danger="false"
    @close="onModalClose"
  >
    <template #default>
      <p>{{ uploadDuplicatesDisclaimer }}</p>
      <div
        class="row mx-3 vertical-align"
      >
        <div class="col col-xs-4">
          <strong>{{ $localize('FileName') }}</strong>
        </div>
        <div class="col col-xs-2">
          <strong>{{ $localize('FileSize') }}</strong>
        </div>
        <div class="col col-xs-6">
          <strong>{{ $localize('FileRelativePath') }}</strong>
        </div>
      </div>
      <div
        id="table-data"
        class="table-data"
      >
        <div
          v-for="file in filesToRender"
          :key="file.name"
          class="row mx-3 vertical-align table-data--row"
        >
          <div class="col col-xs-4 ellipsis">
            <span :title="file.name">
              {{ file.name }}
            </span>
          </div>
          <div class="col col-xs-2 ellipsis">
            <span :title="formatFileSize(file.size)">
              {{ formatFileSize(file.size) }}
            </span>
          </div>
          <div class="col col-xs-6 ellipsis">
            <span :title="FilePathFormatter.getFilePathWithoutFileName(file.webkitRelativePath)">
              {{ FilePathFormatter.getFilePathWithoutFileName(file.webkitRelativePath) }}</span>
          </div>
        </div>
      </div>
      <strong v-if="duplicatedFiles.length > maxRenderedItemsCount">
        {{ $localize('FirstHundredOfDuplicatesIsShown') }}
      </strong>
    </template>
    <template #footer>
      <button
        ref="closeBtn"
        class="btn btn-default"
        @click="onModalClose"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        class="btn btn-default"
        @click="onContinue"
      >
        {{ $localize('Continue') }}
      </button>
      <button
        class="btn btn-primary"
        @click="onSkipDuplicates"
      >
        {{ $localize('SkipDuplicates') }}
      </button>
    </template>
  </ModalDialog>
</template>

<style lang="scss" scoped>
@import "sass/site/_colours.scss";
@import "sass/site/_modal-sizes.scss";

    .table-data {
        max-height: $modal-dialog-main-control-common-height;
        overflow-y: auto;
        overflow-x: hidden;

        &--row:nth-child(even) {
            background-color: $background-light-grey;
        }
    }
</style>