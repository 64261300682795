<template>
  <label
    class="checkbox-inline"
  >
    <input
      v-bind="$attrs"
      :id="id"
      ref="inputElement"
      v-model="model"
      type="checkbox"
      :aria-invalid="errors[0] ? true : undefined"
      :disabled="disabled"
      :aria-describedby="validationMessageId"
    >
    <slot name="label" />
  </label>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useField } from 'vee-validate';
import ValidationRules from '@/Types/Validation/validationRules';
import InputFieldElementIdGenerator from '@/VueComponents/SharedComponents/InputFields/utils/inputFieldElementIdGenerator';

defineOptions({
  inheritAttrs: false
});

const props = withDefaults(defineProps<{
  id: string,
  label?: string,
  tooltip?: string,
  validationRules?: ValidationRules,
  disabled?: boolean,
}>(), {
  required: false,
  label: undefined,
  tooltip: undefined,
  validationRules: undefined,
  disabled: false
});

if (!props.id) {
  console.error(`Unique 'id' should be provided.`);
}

const checkboxElement = ref<HTMLElement>();

const validationMessageId = InputFieldElementIdGenerator.getValidationMessageId(props.id);

const model = defineModel<boolean>();
const { errors, validate } = useField(props.id, props.validationRules, { syncVModel: true });

async function isValid() {
  const result = await validate();
  return result.valid;
}

function focus() {
  checkboxElement.value?.focus();
}

defineExpose({
  isValid,
  focus
});
</script>
