import UserGroupSelectionShortModel from '@/VueComponents/PageComponents/Users/Models/userGroupSelectionShortModel';

export default class UserGroupSelectionModel extends UserGroupSelectionShortModel {
  constructor(public groupId: string,
    public name: string,
    public groupIsSelected: boolean,
    public groupIsEmpty: boolean
  ) {
    super(groupId, groupIsSelected);
  }

  public toShortModel(): UserGroupSelectionShortModel {
    return new UserGroupSelectionShortModel(this.groupId, this.groupIsSelected);
  }
}