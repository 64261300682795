<template>
  <div>
    <h2>{{ $localize('RegistrationErrorTitle') }}</h2>
    <p class="lead">
      {{ $localize('RegistrationErrorLeadText') }}
    </p>
  </div>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss">

</style>