<template>
  <div class="row">
    <div class="col-md-8">
      <ServerErrorList :errors="serverErrors" />

      <TextInputField
        id="businessName"
        ref="businessNameField"
        v-model="businessName"
        :label="$localize('BusinessName')"
        :validation-rules="validationRules?.businessName"
      />

      <TextAreaField
        id="businessAddress"
        v-model="businessAddress"
        :label="$localize('BusinessAddress')"
        rows="6"
        :validation-rules="validationRules?.businessAddress"
      />
    </div>
  </div>

  <div v-if="showMap">
    <a
      href="#"
      @click.prevent="updateMarkerFromAddress"
    >{{ $localize('BusinessUpdateMarkerFromAddress') }}</a>
    <label>&nbsp;{{ $localize('BusinessDragMarker') }}</label>

    <div
      v-disable-element="searching"
    >
      <BusinessMap
        v-model:coordinate="mapParams.coordinate"
        v-model:zoom="mapParams.zoom"
        v-model:businessCoordinate="businessCoordinate"
      />
    </div>
  </div>

  <span>{{ $localize('RequiredFieldInfo') }}</span>

  <div class="text-right">
    <VueButton
      :loading="saving"
      :type="VueButtonTypes.primary"
      aria-describedby="businessNameValidationMessage businessAddressValidationMessage serverErrorValidationMessages"
      @click="save"
    >
      {{ $localize('SaveChanges') }}
    </VueButton>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, reactive, ref } from 'vue';
import { useForm } from 'vee-validate';

import { AccountType } from '@/Types/Enums/accountType';
import addressLookupRepository from '@/Repositories/addressLookupRepository';
import { ApplicationError } from '@/VueCore/services/clients/applicationError';
import BusinessMap from '@/VueComponents/AccountDetails/BusinessMap.vue';
import businessRepository from '@/Repositories/businessRepository';
import logger from '@/Utils/loggerVue';
import PortalSettingsProvider from '@/Utils/portalSettingsProvider';
import ServerErrorList from '@/VueComponents/SharedComponents/ErrorList/ServerErrorList.vue';
import TextAreaField from '@/VueComponents/SharedComponents/InputFields/TextAreaField.vue';
import TextInputField from '@/VueComponents/SharedComponents/InputFields/TextInputField.vue';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import VueButton from '@/VueComponents/SharedComponents/Buttons/VueButton.vue';
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';

import AccountDetailsValidationRules from '@/VueComponents/AccountDetails/Models/accountDetailsValidationRules';


defineProps<{
  validationRules: AccountDetailsValidationRules
}>();

const contextData = useContextDataStore();
const businessNameField = ref();
onMounted(() => {
  businessNameField.value.focus();
});

let initialCoordinate = contextData.portalSettings.defaultMapView.coordinate;
const defaultZoomLevel = 17;
const businessData = contextData.userData.business;
if (businessData.coordinate) {
  initialCoordinate = businessData.coordinate;
}

const serverErrors = ref<string[]>([]);

const businessName = ref(businessData.businessName);
const businessAddress = ref(businessData.businessAddress);
const businessCoordinate = ref(initialCoordinate);

const showMap = ref(PortalSettingsProvider.getAccountType() === AccountType.Business);

const searching = ref(false);
const saving = ref(false);


const mapParams = reactive({
  coordinate: initialCoordinate,
  zoom: defaultZoomLevel
});

const form = useForm({
  initialValues: {
    businessName: businessName.value,
    businessAddress: businessAddress.value
  }
});

async function save() {
  const validationResult = await form.validate();
  if (!validationResult.valid) {
    return;
  }

  saving.value = true;
  serverErrors.value = [];

  const requestData = {
    businessId: contextData.userData.business.businessId,
    businessName: businessName.value,
    businessAddress: businessAddress.value,
    coordinate: businessCoordinate.value
  };

  businessRepository.updateBusinessData(requestData)
      .then(function (businessData) {

        contextData.userData.business.businessName = businessData.businessName;
        contextData.userData.business.businessAddress = businessData.businessAddress;
        contextData.userData.business.coordinate = businessData.coordinate;

        logger.success('CurrentAccountUpdatedSummary');
      })
      .catch((error: ApplicationError) => {

        if (error.errorData?.errorMessages?.length ) {
          serverErrors.value = error.errorData.errorMessages;
          return;
        }

        if (!error.isLogged) {
          logger.error('UnexpectedErrorWhileUpdatingAccountDetails', null, error);
        }
      })
      .finally(() => {
        saving.value = false;
      });
}

function updateMarkerFromAddress() {

  // lookup address
  if (!businessAddress.value.length) {
    return;
  }

  logger.debug('looking up address for \'%s\'', businessAddress.value);

  searching.value = true;

  addressLookupRepository.lookupAddress(businessAddress.value, false) // orderByDistanceToCenter is false
      .then(function (addressSearchResults) {

        logger.debug('received %d results', addressSearchResults.length);

        if (!addressSearchResults.length) {
          logger.info('AddressSearchNoResultsMessage');
          return;
        }

        mapParams.coordinate = addressSearchResults[0].coordinate;
        businessCoordinate.value = addressSearchResults[0].coordinate;
        mapParams.zoom = defaultZoomLevel;

      })
      .catch((error:ApplicationError) => {

        if (!error.isLogged) {
          logger.error('UnexpectedErrorWhileRetrievingAddressLookup', null, error);
        }
      })
      .finally(() => {
        searching.value = false;
      });
}
</script>
