<template>
  <div
    v-for="(upload, index) in model"
    :key="index"
  >
    <div class="file-upload-data-container">
      <div class="row">
        <div class="col-md-7">
          <UploadListItem
            :upload="upload"
            :is-read-only="isReadOnly"
            @remove="removeUpload"
          />
        </div>

        <!-- Only show the camera name and upload comment inputs if the uploads limit is not exceeded -->
        <div
          v-if="model?.length <= constants.maxUploadsForCameraNameAndCommentInputs"
          class="col-md-5"
        >
          <div class="row-data-container">
            <div class="status-icon-container hidden-xs hidden-md hidden-lg" />
            <div class="upload-info-container row-data-container">
              <SelectOrTextInputField
                v-if="includeCameraSelection"
                :id="'camera-' + upload.uploadId"
                v-model="upload.cameraName"
                :options="cameraNames"
                :label="$localize('CameraName')"
                :label-hidden="true"
                icon="fas fa-camera"
                :placeholder="isReadOnly ? '' : $localize('EnterCameraNamePlaceholder')"
                :disabled="isReadOnly"
                @change="uploadChanged(upload)"
              />

              <AdvancedTextAreaField
                :id="'comments-' + upload.uploadId"
                v-model="upload.comments"
                class="textarea-auto-resize"
                :label="$localize('Comments')"
                :label-hidden="true"
                :readonly="isReadOnly"
                :placeholder="$localize('EnterCommentsPlaceholder')"
                rows="1"
                maxlength="3000"
                icon="fas fa-message"
                :auto-resize="true"
                @change="uploadChanged(upload)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr>
  </div>
</template>
<script lang="ts" setup>
import constants from '@/constants';
import SelectOrTextInputField from '@/VueComponents/SharedComponents/InputFields/SelectOrTextInputField.vue';
import UploadListItem from '@/VueComponents/Uploads/UploadListItem.vue';
import useUploadRemoving from '@/VueComponents/Uploads/utils/useUploadRemoving';
import { provide } from 'vue';
import AdvancedTextAreaField from '@/VueComponents/SharedComponents/InputFields/AdvancedTextAreaField.vue';
import BasePartnerUploadDataModel from '@/Models/basePartnerUploadDataModel';

defineProps<{
  cameraNames?: string[],
  includeCameraSelection: boolean,
  isReadOnly: boolean
}>();

const model = defineModel<BasePartnerUploadDataModel[]>();
const { isRemoving, remove } = useUploadRemoving(model.value);

provide('isRemoving', isRemoving);

function uploadChanged(upload: BasePartnerUploadDataModel) {
  upload.hasUploadInfoChanged = true;
}

async function removeUpload(upload: BasePartnerUploadDataModel) {
  await remove([upload]);
}
</script>