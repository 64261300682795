<template>
  <div
    :class="state.inAddMode ? 'form-container' : 'group-details-form'"
    :disabled="state.isLoadingFormData"
  >
    <h2 v-if="state.inAddMode">
      {{ $localize('AddGroupTitle') }}
    </h2>
    <div class="row">
      <div class="col-xs-6">
        <div class="form-group">
          <component
            :is="state.inAddMode ? 'h3' : 'h4'"
            for="groupName"
            class="required"
          >
            {{ $localize('GroupName') }}
          </component>
          <TextInputField
            id="groupName"
            v-model="state.groupName"
            :validation-rules="props.validationRules.groupName"
            :error-id="'groupNameValidationMessage'"
          />
        </div>
      </div>
      <div
        v-if="state.inEditMode && state.showLocation"
        class="col-xs-6"
      >
        <div>
          <h4>{{ $localize('LocationTitle') }}</h4>
          <div class="location-text">
            <div
              v-if="state.address"
              class="ellipsis"
            >
              <i
                v-if="state.address"
                placement="bottom"
              >
                {{ state.address }}
              </i>
            </div>
            <span v-else>{{ $localize('NoLocationAssigned') }}</span>
          </div>
        </div>
      </div>
      <div class="col-xs-12">
        <TabMenu
          v-if="state.showLocation"
          v-model="state.selectedTabItem"
          :items="state.tabItems"
        />
        <h4 v-else>
          {{ $localize('Users') }}
        </h4>
      </div>
      <div class="col-xs-12">
        <div
          v-if="state.isSelectedTabUsers"
          class="listbox-container"
        >
          <DualListBox
            v-model:available-items="state.availableUsers"
            v-model:assigned-items="state.assignedUsers"
            :loading="state.isLoadingFormData"
            :selected-item-id="selectedItemId"
            @is-checked-updated="handleIsCheckedUpdated"
          />
        </div>
        <LocationTab
          v-else
          :address="state.address"
          :coordinate="state.coordinate"
          :is-location-assigned="state.isLocationAssigned"
          :initial-coordinate="state.initialCoordinate"
          :validation-rules="props.validationRules"
          @is-location-assigned-updated="handleIsLocationAssignedUpdate"
          @group-address-updated="handleGroupAddressUpdate"
          @map-details-saved="handleMapDetailsUpdate"
        />
      </div>
    </div>
    <ClientErrorList :error-keys="state.clientErrors" />
    <ServerErrorList :errors="state.serverErrors" />
    <div class="row">
      <div class="col-xs-12 text-right">
        <VueButton
          v-if="state.inAddMode"
          :type="VueButtonTypes.primary"
          :loading="state.formSubmitting"
          aria-describedby="groupNameValidationMessage clientSideValidationErrorMessage serverSideValidationErrorMessage"
          @click="initiateAddGroup"
        >
          {{ $localize('AddGroup') }}
        </VueButton>
        <VueButton
          v-if="state.inEditMode"
          :type="VueButtonTypes.default"
          :loading="state.groupDeleting"
          class="with-space"
          @click="initiateDeleteGroup"
        >
          {{ $localize('DeleteGroup') }}
        </VueButton>
        <VueButton
          v-if="state.inEditMode"
          :type="VueButtonTypes.primary"
          :loading="state.formSubmitting"
          aria-describedby="groupNameValidationMessage clientSideValidationErrorMessage serverSideValidationErrorMessage"
          @click="initiateUpdateGroup"
        >
          {{ $localize('SaveChanges') }}
        </VueButton>
      </div>
    </div>
  </div>
  <GroupDeleteConfirmationModal
    v-if="state.showGroupDeleteConfirmationModal"
    @cancel="handleCancel()"
    @continue="handleContinue()"
  />
  <GroupSaveWarningModal
    v-if="state.showGroupSaveWarningModal"
    :pre-registered-users-assigned-count="state.preRegisteredUsersAssignedCountComputed"
    @close="handleClose()"
    @save="handleSave()"
  />
</template>

<script setup lang="ts">
import { computed, watch, reactive, onMounted } from 'vue';
import GroupsRepository from '@/Repositories/groupsRepository';
import menuDataRepository from '@/Repositories/menuDataRepository';
import logger from '@/Utils/logger';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import { useRouter } from 'vue-router';
import constants from '@/constants';
import PortalSettingsProvider from '@/Utils/portalSettingsProvider';
import { AccountType } from '@/Types/Enums/accountType';
import GroupSaveWarningModal from '@/VueComponents/Modals/GroupSaveWarningModal.vue';
import GroupDeleteConfirmationModal from '@/VueComponents/Modals/GroupDeleteConfirmationModal.vue';
import DualListBox from '@/VueComponents/DualListBox/DualListBox.vue';
import TabMenu from './TabMenu.vue';
import DualListBoxItem from '@/VueComponents/DualListBox/types/DualListBoxItem';
import DualListBoxIconDetails from '@/VueComponents/DualListBox/types/DualListBoxIconDetails';
import { PersonaDisplayNameSummaryDto } from '@/Models/groupManagement/personaDisplayNameSummaryDto';
import { GroupMemberDto } from '@/Models/groupManagement/groupMemberDto';
import { GroupDto } from '@/Models/groupManagement/groupDto';
import LocationTab from '@/VueComponents/Groups/LocationTab.vue';
import { GroupSummaryDto } from '@/Models/groupManagement/groupSummaryDto';
import { GroupDetailsDto } from '@/Models/groupManagement/groupDetailsDto';
import MapCoordinate from '@/Types/mapCoordinate';
import VueButton from '@/VueComponents/SharedComponents/Buttons/VueButton.vue';
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';
import TextInputField from '@/VueComponents/SharedComponents/InputFields/TextInputField.vue';
import ServerErrorList from '@/VueComponents/SharedComponents/ErrorList/ServerErrorList.vue';
import ClientErrorList from '@/VueComponents/SharedComponents/ErrorList/ClientErrorList.vue';
import { useForm } from 'vee-validate';

const contextData = useContextDataStore();
const router = useRouter();

const props = defineProps<{
  allPersonas: PersonaDisplayNameSummaryDto[],
  allGroups?: GroupSummaryDto[],
  selectedItemId: string,
  pageMode: string,
  validationRules: object
}>();

const emit = defineEmits<{(e: 'updateItem', item: GroupDto): void;
  (e: 'deleteItem', itemId: string): void;
}>();

const state: {
  formSubmitting: boolean;
  groupDeleting: boolean;
  inAddMode: boolean;
  inEditMode: boolean;
  showGroupDeleteConfirmationModal: boolean;
  showGroupSaveWarningModal: boolean;
  showLocation: boolean;
  serverErrors: string[];
  clientErrors: string[];
  availableUsers: DualListBoxItem[];
  assignedUsers: DualListBoxItem[];
  selectedTabItem: any;
  tabItems: any;
  selectedTabComponentName: string;
  initialCoordinate: MapCoordinate;
  defaultCoordinate: MapCoordinate;
  businessId: string;
  isSelectedTabUsers: boolean;
  preRegisteredUsersAssignedCountComputed: number;
  groupName: string;
  address?: string;
  isLocationAssigned: boolean;
  coordinate?: MapCoordinate;
  isLoadingFormData: boolean
} = reactive({
  formSubmitting: false,
  groupDeleting: false,
  inAddMode: computed(() => props.pageMode === constants.pageMode.add),
  inEditMode: computed(() => props.pageMode === constants.pageMode.edit),
  showGroupDeleteConfirmationModal: false,
  showGroupSaveWarningModal: false,
  showLocation: computed(() => PortalSettingsProvider.getAccountType() === AccountType.Business),
  serverErrors: [],
  clientErrors: [],
  availableUsers: [],
  assignedUsers: [],
  selectedTabItem: null,
  tabItems: menuDataRepository.getGroupDetailsTabs(),
  selectedTabComponentName: 'group-users-tab',
  initialCoordinate: contextData.userData.business.coordinate || contextData.portalSettings.defaultMapView.coordinate,
  defaultCoordinate: contextData.userData.business.coordinate || contextData.portalSettings.defaultMapView.coordinate,
  businessId: contextData.userData.business.businessId,
  isSelectedTabUsers: true,
  preRegisteredUsersAssignedCountComputed: computed(() => state.assignedUsers.filter(user => user.isChecked && user.isPersonaPreRegistered).length),
  groupName: '',
  address: '',
  isLocationAssigned: false,
  coordinate: null,
  isLoadingFormData: false
});

onMounted(async () => {
  try {
    state.isLoadingFormData = true;
    setActiveTabItem();
    await loadGroupDetails(props.selectedItemId);

    if (state.inAddMode) {
      state.address = '';
      state.isLocationAssigned = false;
      state.coordinate = state.defaultCoordinate;
      setAvailableUsers(props.allPersonas);
    }
  } finally {
    state.isLoadingFormData = false;
  }
});

const form = useForm({
  initialValues: {
    'groupName': state.groupName
  }
});

defineExpose({
  isValid
});

async function isValid() {
  return (await form.validate()).valid;
}

function setActiveTabItem() {
  const activeItem = state.tabItems.find(item => item.data.pageComponentName === state.selectedTabComponentName);
  if (activeItem) {
    state.tabItems.forEach(item => item.isActive = item === activeItem);
    state.selectedTabItem = activeItem;
  }
}

function initiateAddGroup() {
  if (state.preRegisteredUsersAssignedCountComputed > 0) {
    state.showGroupSaveWarningModal = true;
    return;
  }
  addGroup();
}

function initiateUpdateGroup() {
  if (state.preRegisteredUsersAssignedCountComputed > 0) {
    state.showGroupSaveWarningModal = true;
    return;
  }

  updateGroup();
}

async function addGroup() {
  state.serverErrors = [];
  if (!(await isSavingAllowed())) {
    return;
  }
  if (!state.isLocationAssigned) {
    resetLocation();
  }

  state.formSubmitting = true;
  const groupDetails = createGroupDetails(state.groupName, state.address, state.isLocationAssigned, state.coordinate);

  GroupsRepository.addNewGroup(contextData.userData.business.businessId, groupDetails, state.assignedUsers)
      .then(() => {
        logger.success('GroupAddedSummary');
        router.push('/groups');
      })
      .catch(jqXhr => {
        if (jqXhr.serverErrorMessages) {
          state.serverErrors = jqXhr.serverErrorMessages;
          return;
        }
        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnexpectedErrorWhileAddingGroup', null, jqXhr);
        }
      })
      .finally(() => {
        state.formSubmitting = false;
      });
}

async function updateGroup() {
  state.serverErrors = [];
  if (!props.selectedItemId !== null && !(await isSavingAllowed())) {
    return;
  }
  if (!state.isLocationAssigned) {
    resetLocation();
  }

  state.formSubmitting = true;
  const groupDetails = createGroupDetails(state.groupName, state.address, state.isLocationAssigned, state.coordinate);

  GroupsRepository.updateGroup(props.selectedItemId, contextData.userData.business.businessId,
      groupDetails, state.assignedUsers)
      .then((groupDto : GroupDto) => {
        logger.success('GroupUpdatedSummary');
        emit('updateItem', groupDto);
        router.push('/groups');
      })
      .catch(jqXhr => {
        if (jqXhr.serverErrorMessages) {
          state.serverErrors = jqXhr.serverErrorMessages;
          return;
        }
        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnexpectedErrorWhileUpdatingGroup', null, jqXhr);
        }
      })
      .finally(() => {
        state.formSubmitting = false;
      });
}

function handleSave() {
  state.showGroupSaveWarningModal = false;
  state.inAddMode ? addGroup() : updateGroup();
}

function handleClose() {
  state.showGroupSaveWarningModal = false;
}

function initiateDeleteGroup() {
  state.showGroupDeleteConfirmationModal = true;
}

function deleteGroup() {
  state.serverErrors = [];
  state.groupDeleting = true;

  GroupsRepository.deleteGroup(contextData.userData.business.businessId, props.selectedItemId)
      .then(() => {
        logger.success('GroupDeletedSummary');
        emit('deleteItem', props.selectedItemId);
        router.push('/groups');
      })
      .catch(jqXhr => {
        if (jqXhr.serverErrorMessages) {
          state.serverErrors = jqXhr.serverErrorMessages;
          return;
        }
        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnexpectedErrorWhileDeletingGroup', null, jqXhr);
        }
      })
      .finally(() => {
        state.groupDeleting = false;
      });
}

function handleContinue() {
  state.showGroupDeleteConfirmationModal = false;
  deleteGroup();
}

function handleCancel() {
  state.showGroupDeleteConfirmationModal = false;
}

function handleIsCheckedUpdated(itemId: string, newValue: any) {
  const user = state.assignedUsers.find(user => user.id === itemId);
  if (user) {
    user.isChecked = newValue;
  }
}

function setAvailableUsers(availablePersonas: PersonaDisplayNameSummaryDto[]) {
  const availableUserArray: DualListBoxItem[] = availablePersonas.map(item =>
    new DualListBoxItem(
        item.personaId,
        item.personaDisplayName,
        false,
        item.isPersonaPreRegistered,
        getPersonaIconDetails(item)
    )
  );

  state.availableUsers = availableUserArray;
}

function setAssignedUsers(assignedGroupMembers: GroupMemberDto[]) {
  const assignedGroupMembersArray: DualListBoxItem[] = assignedGroupMembers.map(item =>
    new DualListBoxItem(
        item.memberPersona.personaId,
        item.memberPersona.personaDisplayName,
        item.isGroupPrimaryContact,
        item.memberPersona.isPersonaPreRegistered,
        getPersonaIconDetails(item.memberPersona)
    )
  );

  state.assignedUsers = assignedGroupMembersArray;
}

function getPersonaIconDetails(persona: any) {
  if (persona.isPersonaPreRegistered) {
    return new DualListBoxIconDetails(constants.preRegisteredUserIconClass, 'UnregisteredUserTitle');
  }
  return null;
}

async function loadGroupDetails(groupId: string) {
  if (props.pageMode !== constants.pageMode.edit) {
    return;
  }

  if (groupId !== null) {
    state.serverErrors = [];

    try {
      const groupDto = await GroupsRepository.getGroupDetails(state.businessId, groupId);
      if (props.selectedItemId !== groupDto.groupId) {
        return;
      }

      state.groupName = groupDto.groupDetails.groupName;
      if (groupDto.groupDetails.address) {
        state.address = groupDto.groupDetails.address;
        state.isLocationAssigned = true;
      } else {
        state.address = '';
        state.isLocationAssigned = false;
      }

      const initialCoord = groupDto.groupDetails.coordinate || state.defaultCoordinate;
      state.coordinate = initialCoord;
      state.initialCoordinate = initialCoord;
      setAvailableUsers(groupDto.availablePersonas);
      setAssignedUsers(groupDto.assignedGroupMembers);
    } catch (error: any) {
      if (error.serverErrorMessages) {
        state.serverErrors = error.serverErrorMessages;
      } else {
        logger.error('UnexpectedErrorWhileGettingGroup', null, error);
      }
    }
  }
}

async function isSavingAllowed() {
  state.clientErrors = [];
  const valid = await isValid();
  if (!valid) {
    return false;
  }
  const validationFailureInfo = getStatusValidationInfo();
  if (validationFailureInfo) {
    state.selectedTabComponentName = validationFailureInfo.tabName;
    state.clientErrors.push(validationFailureInfo.resourceKey);
    setActiveTabItem();
    return false;
  }
  return true;
}

function getStatusValidationInfo() {
  if (state.assignedUsers.length > 0) {
    const groupHasPrimaryContacts = state.assignedUsers.some(item => item.isChecked);
    if (!groupHasPrimaryContacts) {
      return {
        resourceKey: 'GroupNoPrimaryContact',
        tabName: 'group-users-tab'
      };
    }
  }
  if (state.isLocationAssigned && !state.address?.trim()) {
    return {
      resourceKey: 'GroupNoAddress',
      tabName: 'group-location-tab'
    };
  }
  return null;
}

function resetLocation() {
  state.address = '';
  state.coordinate = null;
}

function handleIsLocationAssignedUpdate(newValue: any) {
  state.isLocationAssigned = newValue;
}

function handleGroupAddressUpdate(newValue: any) {
  state.address = newValue;
}

function handleMapDetailsUpdate(newValue: MapCoordinate) {
  state.coordinate = newValue;
}

function createGroupDetails(groupName: string, address: string, isLocationAssigned: boolean, coordinate: number[]) {
  return new GroupDetailsDto(groupName, address, isLocationAssigned, coordinate);
}

watch(() => state.selectedTabItem, newValue => {
  state.isSelectedTabUsers = newValue.data.pageComponentName === 'group-users-tab';
});

watch(() => props.selectedItemId, async newValue => {
  if (state.inEditMode && newValue) {
    state.clientErrors = [];
    state.serverErrors = [];
    state.isLoadingFormData = true;
    await loadGroupDetails(newValue);
    state.isLoadingFormData = false;
  }
});
</script>

<style scoped lang="scss">
@import "sass/site/_variables.scss";

.with-space {
  margin-right: 5px;
}

div[disabled="true"] {
  pointer-events: none;
  opacity: 0.7
}

.listbox-container {
  margin-bottom: 19px;
}
</style>