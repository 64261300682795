<template>
  <div class="tabs-container container-fluid">
    <ul class="nav navbar-nav navbar-nav--always-inline">
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="{ active: item.isActive }"
      >
        <a
          href="#"
          :data-name="item.name"
          @click.prevent="changeSelectedItem(item, index)"
        >
          <span>{{ item.text }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const modelValue = defineModel<Object>('modelValue');

const props = defineProps<{
  items: [];
}>();

function changeSelectedItem(item, index) {
  props.items.forEach((tabItem, i) => {
    tabItem.isActive = i === index;
  });

  modelValue.value = item;
}
</script>