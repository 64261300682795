<template>
  <PaginatedList
    v-bind="$attrs"
    :columns="[
      { name: 'ReferenceNumber', title: resourceHelper.getString('RequestReferenceAndCounts') },
      { name: 'Status', title: resourceHelper.getString('StatusAndPriority') },
      { name: 'AssignedTo', title: resourceHelper.getString('AssignedToPersonaOrGroup'), cssClass: 'text-wrap-break-word' },
      { name: 'CreatedBy', title: resourceHelper.getString('CreatedBy'), cssClass: 'text-wrap-break-word' },
      { name: 'RequiredBy', title: resourceHelper.getString('RequiredByOrRespondedOn'), cssClass: 'text-wrap-break-word' },
      { name: 'SentTo', title: resourceHelper.getString('SentToRecipientList'), cssClass: 'text-wrap-break-word' }]"
    :items="requests"
    :highlighted-index="highlightedIndex"
    :actions="[
      { title: resourceHelper.getString('ViewRequest'), icon: 'far fa-eye',
        eventName: RequestListActionTypes.viewRequest },
      { title: resourceHelper.getString('CreateReport'), icon: 'far fa-file request-list__ellipsis-dropdown-icon',
        eventName: RequestListActionTypes.createReport }]"
    @action-clicked="onActionClicked"
  >
    <template #ReferenceNumber="opts">
      <div class="request-list__reference-container">
        <PaginatedListLinkField
          :text="opts.item.referenceNumber"
          :href="'/request/' + opts.item.requestId"
        />
        <div
          v-if="opts.item.discussionMessagesCount"
          class="request-list__counts-container mt-3"
        >
          <i
            v-tooltip.top="{
              class: 'request-list__tooltip',
              value: $localize('DiscussionMessageCountTooltip'),
              pt: {
                text: 'request-list__tooltip-text',
                arrow: 'request-list__tooltip-arrow'
              },
              showDelay: 600
            }"
            class="fas fa-comments request-list__counts-icon"
            aria-hidden="true"
          />
          <span class="sr-only">
            {{ $localize('Messages') }}
          </span>
          <span>
            {{ opts.item.discussionMessagesCount }}
          </span>
        </div>
      </div>
    </template>

    <template #Status="opts">
      <PaginatedListLabeledField
        :label="$localize('Status')"
      >
        {{ opts.item.requestStatusForDisplay }}
      </PaginatedListLabeledField>
      <PaginatedListLabeledField
        v-if="opts.item.requestPriorityForDisplay"
        :label="$localize('Priority')"
      >
        {{ opts.item.requestPriorityForDisplay }}
      </PaginatedListLabeledField>
    </template>

    <template #AssignedTo="opts">
      <PaginatedListLabeledField
        :label="$localize('AssignedToPersonaOrGroup')"
      >
        <div
          v-if="opts.item.assignedPersonaName"
        >
          <i
            class="fas fa-user request-list__inline-text-icon"
            :title="$localize('User')"
          />
          <span>{{ opts.item.assignedPersonaName }}</span>
        </div>
        <div
          v-if="opts.item.assignedGroupName"
        >
          <i
            class="fas fa-users request-list__inline-text-icon"
            :title="$localize('Group')"
          />
          <span>{{ opts.item.assignedGroupName }}</span>
        </div>
        <div
          v-if="!opts.item.assignedGroupName && !opts.item.assignedPersonaName"
          class="request-list__assigned-to--empty"
        >
          {{ $localize('Unassigned') }}
        </div>
      </PaginatedListLabeledField>
    </template>

    <template #CreatedBy="opts">
      <PaginatedListLabeledField
        :label="$localize('CreatedBy')"
      >
        <div>{{ opts.item.investigatorDisplayName }}</div>
        <div class="request-list__secondary-value">
          {{ opts.item.agencyName }}
        </div>
        <div class="request-list__secondary-value">
          <span>
            {{ moment(opts.item.requestDateTime).format('LLL') }}
          </span>
        </div>
      </PaginatedListLabeledField>
    </template>

    <template #RequiredBy="opts">
      <PaginatedListLabeledField
        v-if="opts.item.responseTimestamp"
        :label="$localize('RespondedOn')"
      >
        <div>
          {{ moment(opts.item.responseTimestamp).format('LL') }}
        </div>
        <div
          class="request-list__secondary-value"
        >
          {{ moment(opts.item.responseTimestamp).format('LT') }}
        </div>
      </PaginatedListLabeledField>
      <PaginatedListLabeledField
        v-else
        :label="$localize('RequiredBy')"
      >
        <div>
          {{ moment(opts.item.requiredByDate).format('LL') }}
        </div>
        <RequiredBySummary
          v-if="!opts.item.hasResponse && opts.item.requiredByDate"
          :required-by="convertStringToDate(opts.item.requiredByDate)"
        />
      </PaginatedListLabeledField>
      <div
        v-if="opts.item.caseNameClaim && opts.item.caseNameClaim.displayName"
        class="heading mt-3"
      >
        {{ opts.item.caseNameClaim.displayName }}
      </div>
      <div
        v-else-if="opts.item.caseNameClaim && !opts.item.caseNameClaim.displayName"
        class="heading mt-3"
      >
        {{ opts.item.caseNameClaim.key }}
      </div>
      <div
        v-if="opts.item.caseNameClaim"
      >
        {{ opts.item.caseNameClaim.value }}
      </div>
    </template>

    <template #SentTo="opts">
      <PaginatedListLabeledField
        :label="$localize('SentToRecipientList')"
      >
        <CollapsedList
          :max-collapsed-items-count="3"
          :options="opts.item.sentToRecipientList"
          :no-items-string="resourceHelper.getString('CollapsibleListNoRecipients')"
        />
      </PaginatedListLabeledField>
      <div
        v-if="opts.item.rmsIdClaim && opts.item.rmsIdClaim.displayName"
        class="heading mt-3"
      >
        {{ opts.item.rmsIdClaim.displayName }}
      </div>
      <div
        v-else-if="opts.item.rmsIdClaim && !opts.item.rmsIdClaim.displayName"
        class="heading mt-3"
      >
        {{ opts.item.rmsIdClaim.key }}
      </div>
      <div
        v-if="opts.item.rmsIdClaim"
      >
        {{ opts.item.rmsIdClaim.value }}
      </div>
    </template>
  </PaginatedList>

  <CreateReportModal
    v-if="report.showModal"
    :is-danger="false"
    :report-item-reference-number="report.itemReferenceNumber"
    :report-item-id="report.itemId"
    :item-type="CreateReportModalTypes.request"
    @close="onCloseCreateReport"
  />
</template>
<script lang="ts" setup>
import PaginatedList from '@/VueComponents/SharedComponents/PaginatedList/PaginatedList.vue';
import resourceHelper from '@/Utils/resourceHelper';
import PaginatedListLinkField from '@/VueComponents/SharedComponents/PaginatedList/PaginatedListLinkField.vue';
import PaginatedListLabeledField from '@/VueComponents/SharedComponents/PaginatedList/PaginatedListLabeledField.vue';
import { computed, reactive } from 'vue';
import moment from 'moment';
import CollapsedList from '@/VueComponents/SharedComponents/CollapsedList/CollapsedList.vue';
import CreateReportModal from '@/VueComponents/Modals/CreateReportModal.vue';
import { CreateReportModalTypes } from '@/Types/Enums/createReportModalTypes';
import RequiredBySummary from '@/VueComponents/Requests/RequestList/RequiredBySummary/RequiredBySummary.vue';
import { RequestListActionTypes } from '@/VueComponents/Requests/RequestList/Enums/requestListActionTypes';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps<{
  requests: any[],
  lastAccessedRequestId?: string
}>();

const report = reactive({
  showModal: false,
  itemReferenceNumber: '',
  itemId: ''
});

const highlightedIndex = computed(() => {
  for (let i = 0; i < props.requests.length; i++) {
    if (props.requests[i].requestId === props.lastAccessedRequestId) {
      return i;
    }
  }

  return -1;
});

function onActionClicked(actionName: string, payload: any) {
  switch (actionName) {
    case RequestListActionTypes.viewRequest:
      viewRequestClicked(payload);
      break;
    case RequestListActionTypes.createReport:
      createReportClicked(payload);
      break;
  }
}

function viewRequestClicked(payload) {
  router.push('/request/' + payload.item.requestId);
}

function createReportClicked(payload) {
  report.itemReferenceNumber = payload.item.referenceNumber;
  report.itemId = payload.item.requestId;
  report.showModal = true;
}

function onCloseCreateReport() {
  report.showModal = false;
}

function convertStringToDate(str) {
  // convert string in ISO_8601 format YYYY-MM-DDTHH:mm:ss
  const date = moment(str, moment.ISO_8601, true).toDate();
  return date;
}
</script>