<template>
  <div
    v-if="availableLanguages.length > 1"
    class="dropdown"
  >
    <a
      href="#"
      class="dropdown-toggle"
      data-toggle="dropdown"
      :aria-label="anchorAriaLabel"
      aria-haspopup="true"
      aria-expanded="false"
      aria-controls="dropdown-language-menu"
      role="button"
    >
      <img
        id="language-image"
        :src="flagIcon"
        width="20"
        height="15"
        alt=""
      >
      <span
        class="hidden-xs ml-1"
        aria-hidden="true"
      >{{ $localize(currentLanguageResourceKey) }}</span>
      <span class="sr-only">{{ $localize(currentLanguageResourceKey) }}</span>
      <span class="caret" />
    </a>

    <ul
      id="dropdown-language-menu"
      role="menu"
      class="dropdown-menu"
    >
      <li
        v-for="language in availableLanguages"
        :key="language.languageCode"
      >
        <a
          href="#"
          rel="alternate"
          role="menuitem"
          :lang="language.languageCode"
          :hreflang="language.languageCode"
          :aria-current="language.languageCode === languageCode"
          @click.prevent="changeLanguage(language)"
        >
          {{ $localize(language.resourceKey) }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import localStorageHelper from '@/Utils/localStorageHelper';
import redirectHelper from '@/Utils/redirectHelper';
import languageHelper from '@/Utils/languageHelper';
import resourceHelper from '@/Utils/resourceHelper';
import SupportedLanguage from '@/Types/supportedLanguage';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';

const contextData = useContextDataStore();
const availableLanguages = ref(contextData.availableLanguages);
const currentLanguageResourceKey = ref(languageHelper.getCurrentLanguageResourceKey());
const flagIcon = ref(contextData.flagIcon);
const languageCode = ref(contextData.languageCode);

const anchorAriaLabel = computed(() =>
  `${resourceHelper.getString(currentLanguageResourceKey.value)} ${resourceHelper.getString('SelectYourLanguage')}`
);

function changeLanguage(languageData: SupportedLanguage) {
  if (languageCode.value === languageData.languageCode) {
    return;
  }

  localStorageHelper.setLanguageDetails(languageData.languageCode);
  redirectHelper.reload();
}</script>