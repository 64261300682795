<template>
  <FileSelector
    id="cctv"
    :title="$localize('CCTVExport')"
    :description="$localize('CCTVFolderExportPrompt')"
    :select-files-button-label="$localize('SelectZipFile')"
    :select-folder-button-label="$localize('SelectFolderToZip')"
    :default-buttons-style="true"
    image-link="Content/images/CCTVExportFolder.svg"
    accept=".zip"
    @add-files="onFilesAdded"
    @add-folders="onFoldersAdded"
  />

  <SelectedNonZipFilesWarningModal
    v-if="isModalShowing"
    v-bind="modalProps"
    @cancel="closeModal"
    @continue="onModalContinue"
  />
</template>

<script lang="ts" setup>
import FileSelector from '@/VueComponents/FileUploadSelector/FileSelector.vue';
import FileModel from '@/VueComponents/FileUploadSelector/models/fileModel';
import FolderModel from '@/VueComponents/FileUploadSelector/models/folderModel';
import logger from '@/Utils/logger';
import UploadSelectorUtils from '@/VueComponents/FileUploadSelector/utils/uploadSelectorUtils';
import useModalController from '@/VueCore/utils/useModalController';
import SelectedNonZipFilesWarningModal
  from '@/VueComponents/FileUploadSelector/modals/SelectedNonZipFilesWarningModal.vue';
import FileUploadModel from '@/VueComponents/FileUploadSelector/models/fileUploadModel';
import { FileUploadType } from '@/VueComponents/FileUploadSelector/enums/fileUploadType';
import { FolderUploadType } from '@/VueComponents/FileUploadSelector/enums/folderUploadType';
import FolderUploadModel from '@/VueComponents/FileUploadSelector/models/folderUploadModel';

const emit = defineEmits<{
  addFiles: [files: FileUploadModel[]],
  addFolders: [files: FolderUploadModel[]],
}>();

const { isShowing: isModalShowing, show: showModal, close: closeModal, props: modalProps } =
  useModalController<{ filesCount: number }>();

// Stores zip files to initiate uploading after continuing from the modal dialog
let selectedFiles: FileUploadModel[] = [];

function onFilesAdded(files: FileModel[]) {
  const { zipFiles, nonZipFiles } = UploadSelectorUtils.splitRegularAndZipFiles(files);
  handleFileAdding(zipFiles, nonZipFiles);
}

function onFoldersAdded(folders: FolderModel[]) {
  const folderModels = folders.map(f => new FolderUploadModel(f, FolderUploadType.cctv));
  emit('addFolders', folderModels);
}

function handleFileAdding(zipFiles: FileModel[], nonZipFiles: FileModel[]) {
  const hasOnlyNonZips = nonZipFiles.length && !zipFiles.length;
  const hasAnyNonZips = !!nonZipFiles.length;

  // When only non-zip files selected - show the warning toaster
  if (hasOnlyNonZips) {
    logger.warning('NoZipUploadWarningMessage');
    return;
  }

  const fileModels = zipFiles.map(f => new FileUploadModel(f, FileUploadType.zipCctv));

  // When files or folders contains non-zip files - show the warning modal
  if (hasAnyNonZips) {
    selectedFiles = fileModels;
    showModal({ filesCount: nonZipFiles.length });
    return;
  }

  emit('addFiles', fileModels);
}

function onModalContinue() {
  closeModal();
  emit('addFiles', selectedFiles);
}
</script>
