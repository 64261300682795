<template>
  <div class="content-container">
    <div class="action-buttons-header">
      <button
        class="btn btn-primary btn-outline"
        @click="showSelfResponseModal = true"
      >
        {{ $localize('AddNewUploadFolder') }}
      </button>
    </div>
    <h2>
      <span>{{ $localize('UploadFolders') }}</span>
      <span
        v-if="!loading || !!paginationOptions.total"
        class="font-size-smaller"
      > ({{ paginationOptions.total }})</span>
    </h2>
    <p class="lead">
      {{ $localize('ViewAndManageUploadFolders') }}
    </p>
    <div class="requests">
      <!-- Left area for the filter panel -->
      <div class="requests__filter-panel">
        <div class="filter-panel__heading-container">
          <div class="filter-panel__heading">
            {{ $localize('RequestFilterTitle') }}
          </div>
          <a
            href="#"
            @click.prevent.stop="resetFilterCriteriaToDefault"
          >{{ $localize('ResetAll') }}</a>
        </div>
        <vue-filter-panel
          ref="filter"
          :initial-filter="initialFilter"
          :refine-by-tooltip="$localize('SelfResponseRefineByTooltip')"
          :local-storage-key="LocalStorageKeyType.UploadFoldersFilterCriteria"
          @filter-change-handler="filterChangeHandler"
        />
      </div>
      <!-- Right area for the request list -->
      <div
        class="requests__main-content"
      >
        <UploadFoldersList
          v-model:selected-page="paginationOptions.currentPage"
          v-model:page-size="paginationOptions.pageSize"
          :upload-folders="folders"
          :last-accessed-response-id="lastAccessedResponseId"
          :loading-error-message="$localize('FailedToRetrieveUploadFolders')"
          :loading="loading"
          :display-loading-error="displayLoadingError"
          :pagination-total-items="paginationOptions.total"
          :sorting-options="sortingOptions.options"
          :sorting-default-order="sortingOptions.sortOrder"
          :sorting-default-value="sortingOptions.sortField"
          @on-sort="sortingOptions.onSort($event)"
          @page-changed="paginationOptions.onPageChanged()"
          @page-size-changed="paginationOptions.onPageSizeChanged()"
        />
      </div>
    </div>

    <SelfResponseModal
      v-if="showSelfResponseModal"
      @close="showSelfResponseModal = false"
    />
  </div>
</template>
<script lang="ts" setup>
import { nextTick, reactive, ref } from 'vue';
import { InitialFilterCriteriaDto } from '@/Components/filterPanel/Models/initialFilterCriteriaDto';
import UploadFoldersListModel from '@/VueComponents/UploadFolders/UploadFoldersList/Models/UploadFoldersListModel';
import logger from '@/Utils/logger';
import { LocalStorageKeyType } from '@/Types/Enums/localStorageKeyType';
import UploadFoldersList from '@/VueComponents/UploadFolders/UploadFoldersList/UploadFoldersList.vue';
import { SelfResponseSummary } from '@/Models/selfResponseSummary';
import SelfResponseModal from '@/VueComponents/Modals/CreateSelfResponse.vue';
import VueFilterPanel from '@/VueComponents/Filter/VueFilterPanel.vue';
import moment from 'moment';
import { load } from '@/VueComponents/UploadFolders/utils/UploadFolderSummaryLoader';
import resourceHelper from '@/Utils/resourceHelper';
import SortOptionsManager from '@/VueComponents/SharedComponents/SortDropdown/utils/sortOptionsManager';
import PaginationOptionsManager
  from '@/VueComponents/PaginatedTable/TablePageNavigation/utils/paginationOptionsManager';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';

const contextData = useContextDataStore();
const props = defineProps<{
  initialFilter: InitialFilterCriteriaDto,
  lastAccessedResponseId: string | null,
  usePreviousViewSettings: boolean
}>();

const loading = ref(false);
const displayLoadingError = ref(false);
let folders: UploadFoldersListModel[] = reactive([]);
const showSelfResponseModal = ref(false);

const sortingOptions = reactive(new SortOptionsManager(
    [{ field: 'CreatedTimestamp', label: resourceHelper.getString('DateCreated') }],
    LocalStorageKeyType.UploadFoldersSorting,
    () => {
      paginationOptions.currentPage = 1;
      updateResults();
    },
    'Desc'
));

const paginationOptions = reactive(new PaginationOptionsManager(
    contextData.portalSettings.paginationPageSizeOptions,
    LocalStorageKeyType.UploadFoldersPage,
    LocalStorageKeyType.PaginationUploadFoldersPageSize,
    () => {
      updateResults();
    },
    () => {
      if (paginationOptions.pageSize > paginationOptions.total && folders.length === paginationOptions.total) {
        return;
      }

      updateResults();
    },
    { page: props.usePreviousViewSettings, pageSize: true }
));

const filter = ref(null);

const updateResults = () => {

  const filterCriteria = filter.value.getSelectedFilterCriteria();

  loading.value = true;

  load(filterCriteria,
      sortingOptions.sortField,
      sortingOptions.sortOrder,
      paginationOptions.currentPage,
      paginationOptions.pageSize,
      pageResult => {

        loading.value = false;
        folders = pageResult.results.map(mapToUploadFolderListModel);

        filter.value?.updateFilterCriteria(pageResult.filterCriteria);
        paginationOptions.total = pageResult.total;

        // reload the last available page if current page does not contain any elements
        if (paginationOptions.total && !pageResult.results.length) {
          paginationOptions.currentPage = Math.ceil(paginationOptions.total / paginationOptions.pageSize);

          updateResults();
        }
      },
      jqXhr => {
        loading.value = false;
        displayLoadingError.value = true;

        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnexpectedErrorWhileRetrievingUploadFolders', null, jqXhr);
        }
      }
  );
};

const filterChangeHandler = () => {
  paginationOptions.currentPage = 1;

  updateResults();
};

const resetFilterCriteriaToDefault = () => {
  filter.value.applyDefaultFilterCriteria();
  filterChangeHandler();
};

nextTick(() => {
  updateResults();
});

function mapToUploadFolderListModel(response: SelfResponseSummary): UploadFoldersListModel {
  return {
    assignedTo: response.assignedPersonaDisplayName || response.assignedGroupDisplayName,
    createBy: response.createdByPersonaDisplayName,
    createdOn: moment(response.createdDateTime).format('LL'),
    id: response.id,
    internalReferenceNumber: response.internalReferenceNumber,
    referenceNumber: response.referenceNumber,
    statusName: response.selfResponseStatusForDisplay,
    discussionMessagesCount: response.discussionMessagesCount,
    priorityName: response.selfResponsePriorityForDisplay,
    rmsId: response.rmsId,
    cadIds: response.cadIds
  };
}
</script>
<style scoped>
</style>