<template>
  <div class="dropdown">
    <a
      href="#"
      @click.prevent="showHelp"
    >
      <span class="sr-only">{{ $localize('ScreenReaderHelpLink') }}</span>
      <span
        v-tooltip.bottom="{
          class: 'custom-tooltip-container',
          value: $localize('OpenHelpInNewWindow'),
          pt: {
            text: 'custom-tooltip-text',
            arrow: 'custom-tooltip-arrow'
          },
          showDelay: 600
        }"
        aria-hidden="true"
        class="fas fa-circle-question help-icon"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import redirectHelper from '@/Utils/redirectHelper';

const props = withDefaults(defineProps<{
        helpId?: string | null;
    }>(), {
  helpId: null
});

const route = useRoute();
const showHelp = () => {
  if (props.helpId) {
    redirectHelper.openHelpWindow(props.helpId);
  } else {
    const helpId = route.meta.helpId;
    redirectHelper.openHelpWindow(helpId);
  }
};
</script>
