<template>
  <div class="form-container">
    <div class="action-buttons-header action-buttons-header--edit-screen">
      <a
        href="#groups/new"
        class="btn btn-primary btn-outline"
        role="button"
      >
        {{ $localize('AddNewGroupButton') }}
      </a>
    </div>

    <h2>
      {{ $localize('GroupsTitle') }}
      <span class="font-size-smaller">({{ totalCount }})</span>
    </h2>

    <p class="lead">
      {{ $localize(groupsTabInfoRowText) }}
    </p>

    <div class="row">
      <div class="col-sm-4">
        <GroupFilterArea
          v-model="selectedItemId"
          :groups="allGroupsLocal"
          @selected-item-id-updated="updateSelectedItemId"
        />
      </div>

      <div class="col-sm-8 no-sm-x-padding">
        <div v-if="isItemSelected">
          <GroupDetails
            :selected-item-id="selectedItemId"
            :all-personas="allGroupsLocal"
            :page-mode="constants.pageMode.edit"
            :validation-rules="validationRules"
            @update-item="updateItem"
            @delete-item="deleteItem"
          />
        </div>

        <div
          v-else
          class="blank-area blank-area-height-admin-groups"
        >
          <i
            class="fas fa-users"
            aria-hidden="true"
          />
          <h4 v-if="allGroups.length !== 0">
            {{ $localize('GroupsTabBlankAreaInfo') }}
          </h4>
          <h4 v-if="allGroups.length === 0">
            {{ $localize('GroupsTabNoUsersAreaInfo') }}
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import GroupFilterArea from '@/VueComponents/GroupFilterArea/GroupFilterArea.vue';
import GroupDetails from '@/VueComponents/Groups/GroupDetails.vue';
import { GroupMemberDto } from '@/Models/groupManagement/groupMemberDto';
import { GroupDetailsDto } from '@/Models/groupManagement/groupDetailsDto';
import AccountSpecificLabelsProvider from '@/Utils/accountSpecificLabelsProvider';
import { GroupSummaryDto } from '@/Models/groupManagement/groupSummaryDto';
import constants from '@/constants';
import { GroupDto } from '@/Models/groupManagement/groupDto';

const props = defineProps<{
  allGroups: GroupSummaryDto[];
  validationRules: object
}>();

const allGroupsLocal = ref(props.allGroups);
const selectedItemId = ref('');

const totalCount = computed(() => allGroupsLocal.value.length);
const isItemSelected = computed(() => selectedItemId.value !== '');

const groupsTabInfoRowText = AccountSpecificLabelsProvider.getAccountLabelKey('GroupsTabInfoRowText');

const updateSelectedItemId = (newValue: string) => {
  selectedItemId.value = newValue;
};

const updateItem = (itemSummaryDto: GroupDto) => {
  const items = allGroupsLocal.value;
  const updateIndex = items.findIndex(x => x.groupId === itemSummaryDto.groupId);
  let itemToUpdate = items[updateIndex];
  const newGroupMembers = itemSummaryDto.assignedGroupMembers
      .map(x => new GroupMemberDto(x.memberPersona.personaId, x.memberPersona.personaDisplayName, x.isGroupPrimaryContact));
  itemToUpdate.groupMembers(newGroupMembers);

  const newGroupDetails = createNewGroupDetails(itemSummaryDto);

  itemToUpdate = {
    ...itemToUpdate,
    groupDetails: newGroupDetails
  };

  allGroupsLocal.value.splice(updateIndex, 1, itemToUpdate);
  allGroupsLocal.value = items;
};

const deleteItem = (itemId: string) => {
  const deleteIndex = allGroupsLocal.value.findIndex(x => x.groupId === itemId);
  if (deleteIndex !== -1) {
    allGroupsLocal.value.splice(deleteIndex, 1);
    selectedItemId.value = '';
  }
};

const createNewGroupDetails = (itemSummaryDto: GroupDto) => {
  return new GroupDetailsDto(
      itemSummaryDto.groupDetails.groupName,
      itemSummaryDto.groupDetails.address,
      itemSummaryDto.groupDetails.isLocationAssigned,
      itemSummaryDto.groupDetails.coordinate
  );
};
</script>