<template>
  <div
    class="form-group"
    :class="{ 'has-error': !!validationMessage, 'position-relative': !!icon }"
  >
    <label
      v-if="label"
      :for="id"
      class="control-label"
      :class="{ 'required': required, 'sr-only': labelHidded }"
    >{{ label }}</label>
    <span
      v-if="tooltip"
      class="sr-only"
    >{{ tooltip }}</span>
    <div
      v-if="tooltip"
      class="input-group"
    >
      <div
        :class="{ 'has-icon': !!icon }"
      >
        <slot />
      </div>
      <div
        v-tooltip.bottom="{
          class: 'custom-tooltip-container',
          value: tooltip,
          pt: {
            text: 'custom-tooltip-text',
            arrow: 'custom-tooltip-arrow'
          },
          showDelay: 600
        }"
        aria-hidden="true"
        class="input-group-addon"
      >
        <i class="fas fa-circle-info" />
      </div>
    </div>
    <div
      :class="{ 'has-icon': !!icon }"
    >
      <slot
        v-if="!tooltip"
      />
    </div>
    <i
      v-if="icon"
      class="form-icon"
      :class="icon"
    />
    <span
      v-if="validationMessage"
      class="help-block"
    >
      <small :id="validationSpanId">
        {{ validationMessage }}
      </small>
    </span>
    <span v-if="disclaimer">
      <small class="text-muted">{{ disclaimer }}</small>
    </span>
  </div>
</template>

<script lang="ts" setup>
import InputFieldElementIdGenerator from '@/VueComponents/SharedComponents/InputFields/utils/inputFieldElementIdGenerator';

const props = withDefaults(defineProps<{
  id: string,
  label?: string,
  labelHidded?: boolean,
  validationMessage?: string,
  tooltip?: string,
  required?: boolean,
  errorId?: string,
  disclaimer?: string,
  icon?: string
}>(), { required: false, label: undefined, validationMessage: undefined, tooltip: undefined,
  disclaimer: undefined, icon: undefined, errorId: undefined });

const validationSpanId = props.errorId ?? InputFieldElementIdGenerator.getValidationMessageId(props.id);
</script>

<style scoped lang="scss">
.form-icon {
  position: absolute;
  top: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.has-icon > * {
  padding-left: 34px;
}

.position-relative {
  position: relative;
}
</style>
