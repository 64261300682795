import FileModel from '@/VueComponents/FileUploadSelector/models/fileModel';

export default class FolderModel {
  name: string;
  files: FileModel[];

  constructor(name: string, files: FileModel[]) {
    this.name = name;
    this.files = files;
  }

  static fromFiles(files: File[]): FolderModel {
    const folderName = files[0].webkitRelativePath ? files[0].webkitRelativePath.split('/')[0] : '';
    return {
      name: folderName,
      files: files.map(FileModel.fromFile)
    };
  }
}