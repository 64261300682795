<template>
  <div class="form-container">
    <component
      :is="contentSections[state.contentToDisplay]"
      v-model="state.contentToDisplay"
    />
  </div>
</template>

<script setup lang="ts">
import { reactive, onBeforeMount } from 'vue';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import { RegistrationChecksStatus } from '@/Types/Enums/registrationChecksStatus';

import RegistrationComplete from '@/VueComponents/PageComponents/Registration/ContentSections/RegistrationComplete.vue';
import RegistrationError from '@/VueComponents/PageComponents/Registration/ContentSections/RegistrationError.vue';
import RegistrationEmailValidationError from '@/VueComponents/PageComponents/Registration/ContentSections/RegistrationEmailValidationError.vue';
import RegistrationForm from '@/VueComponents/PageComponents/Registration/ContentSections/RegistrationForm.vue';
import RegistrationUnavailable from '@/VueComponents/PageComponents/Registration/ContentSections/RegistrationUnavailable.vue';
import { ContentSections } from '@/VueComponents/PageComponents/Registration/ContentSections/contentSections';

const contextData = useContextDataStore();

const contentSections = {
  RegistrationUnavailable,
  RegistrationError,
  RegistrationEmailValidationError,
  RegistrationComplete,
  RegistrationForm
};

const state = reactive({
  contentToDisplay: ContentSections.RegistrationUnavailable
});

onBeforeMount(()=>{
  switch (contextData.registrationData.registrationChecksStatus) {
    case RegistrationChecksStatus.RegistrationAllowed:
      state.contentToDisplay = ContentSections.RegistrationForm;
      break;
    case RegistrationChecksStatus.IdentityEmailMismatch:
      state.contentToDisplay = ContentSections.RegistrationEmailValidationError;
      break;
    default:
      state.contentToDisplay = ContentSections.RegistrationUnavailable;
      break;
  }
});

</script>

<style scoped lang="scss">
</style>