import FileModel from '@/VueComponents/FileUploadSelector/models/fileModel';
import { FileUploadType } from '@/VueComponents/FileUploadSelector/enums/fileUploadType';

export default class FileUploadModel extends FileModel {
  type: FileUploadType;

  constructor(file: FileModel, type: FileUploadType) {
    super(file.name, file.path, file.file);
    this.type = type;
  }

  static fromFileWithType(file: File, type: FileUploadType): FileUploadModel {
    const fileModel = FileModel.fromFile(file);
    return new FileUploadModel(fileModel, type);
  }
}