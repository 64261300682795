<template>
  <ModalDialog
    :title="$localize('GroupDeleteModalTitle')"
    :is-wide="false"
    :is-danger="true"
    @close="emit('cancel')"
  >
    <template
      #default
    >
      <div>
        {{ $localize('GroupDeleteModalMessage') }}
      </div>
      <br>
      <div>
        {{ $localize('GroupDeleteModalQuestion') }}
      </div>
    </template>
    <template #footer>
      <button
        ref="cancelButton"
        class="btn btn-default"
        @click="emit('cancel')"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        ref="continueButton"
        class="btn btn-danger"
        @click="emit('continue')"
      >
        {{ $localize('Continue') }}
      </button>
    </template>
  </ModalDialog>
</template>

<script  setup lang="ts">
import ModalDialog from '@/VueComponents/Modals/ModalDialog';

const emit = defineEmits<{ 'cancel', 'continue' }>();

</script>