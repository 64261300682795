<template>
  <div
    v-if="userDisplayName"
    class="dropdown"
  >
    <a
      href="#"
      class="dropdown-toggle"
      aria-haspopup="true"
      aria-expanded="false"
      @click.prevent="toggleDropdown"
    >
      <span class="fas fa-user" />
      <span class="ml-3">{{ userDisplayName }}</span> <b class="caret" />
    </a>
    <ul
      class="dropdown-menu"
      :class="{ show: dropdownVisible }"
    >
      <li>
        <a
          href="#"
          @click.prevent="logout"
        >{{ $localize('Logout') }}</a>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import redirectHelper from '@/Utils/redirectHelper';

const contextData = useContextDataStore();
const dropdownVisible = ref(false);
const userDisplayName = ref('');
// TODO: to abandon Ko model for user data in context data
if (contextData.userData && typeof (contextData.userData.displayName) === 'function') {
  userDisplayName.value = contextData.userData.displayName();
  contextData.userData.displayName.subscribe(() => {
    userDisplayName.value = contextData.userData.displayName();
  });
}

const toggleDropdown = () => {
  dropdownVisible.value = !dropdownVisible.value;
};

const logout = () => {
  redirectHelper.logoutRedirect();
};
</script>

