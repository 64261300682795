<template>
  <div>
    <div
      class="business-user-details-form"
      :class="isAddNewUserMode ? 'form-container' : ''"
      :disabled="state.isLoadingFormData"
    >
      <div v-if="isAddNewUserMode">
        <h2>
          {{ $localize("AddUserTitle") }}
        </h2>
        <p>
          {{ $localize("BusinessUserDetailsAddNewUserDescription") }}
        </p>
      </div>

      <div class="row">
        <div class="col-md-6">
          <h4 class="form-title">
            {{ $localize("BusinessUserDetailsPersonalDetails") }}
          </h4>
          <TextInputField
            id="givenName"
            ref="givenNameInput"
            v-model="state.givenName"
            :label="$localize('GivenName')"
            :validation-rules="validationRules.givenName"
          />

          <TextInputField
            id="familyName"
            v-model="state.familyName"
            :label="$localize('FamilyName')"
            :validation-rules="validationRules.familyName"
          />

          <TextInputField
            id="emailAddress"
            v-model="state.emailAddress"
            :label="$localize('EmailAddress')"
            :validation-rules="validationRules.emailAddress"
            :disabled="!isAddNewUserMode"
            :disclaimer="!isAddNewUserMode ? $localize('BusinessUserDetailsEmailDescription') : undefined"
          />

          <TextInputField
            id="phoneNumber"
            v-model="state.phoneNumber"
            :label="$localize('PhoneNumber')"
            :validation-rules="validationRules.phoneNumber"
            type="tel"
          />

          <hr class="horizontal-line">

          <GroupCheckboxLabel
            v-model="state.isBusinessAdminUser"
            label-key="BusinessUserDetailsIsAdminLabel"
            icon-class="fas fa-user-cog fa-fw"
            :tooltip-text="isAdminTooltip"
          />

          <hr class="horizontal-line">

          <GroupCheckboxLabel
            v-model="state.isBusinessPrimaryContact"
            :label-key="AccountSpecificLabelsProvider.getAccountLabelKey('BusinessUserDetailsPrimaryContactLabel')"
            icon-class="far fa-envelope fa-fw"
            :tooltip-text="primaryContactTooltip"
          />

          <hr class="horizontal-line">
        </div>

        <div class="col-md-6">
          <h4 class="form-title">
            {{ $localize('BusinessUserDetailsGroups') }}
          </h4>

          <ul class="group-ul-list">
            <li
              v-for="group in state.userGroupSelections"
              :key="group.groupId"
            >
              <label class="font-weight-normal">
                <input
                  v-model="group.groupIsSelected"
                  type="checkbox"
                >
                <span class="text-wrap-break-word--inline-block ml-1 mr-2">{{ group.name }}</span>
                <i
                  v-if="group.groupIsEmpty"
                  v-tooltip.top="{
                    class: 'custom-tooltip-container',
                    value: emptyGroupTooltip,
                    pt: {
                      text: 'custom-tooltip-text',
                      arrow: 'custom-tooltip-arrow'
                    },
                    showDelay: 600
                  }"
                  class="fas fa-users-slash"
                  aria-hidden="true"
                />
                <i
                  v-else
                  v-tooltip.top="{
                    class: 'custom-tooltip-container',
                    value: groupWithUsersTooltip,
                    pt: {
                      text: 'custom-tooltip-text',
                      arrow: 'custom-tooltip-arrow'
                    },
                    showDelay: 600
                  }"
                  class="fas fa-users"
                  aria-hidden="true"
                />
              </label>
            </li>
          </ul>

          <div v-if="state.userGroupSelections.length === 0">
            <span class="text-pre-wrap">{{ $localize('BusinessUserDetailsGroupsEmptyText') }}</span>
          </div>
        </div>
      </div>

      <ServerErrorList :errors="state.serverErrors" />

      <!--Action buttons-->
      <div class="text-right">
        <span v-if="!isAddNewUserMode">
          <VueButton
            :additional-button-class="'action-button-right-margin'"
            :type="VueButtonTypes.default"
            :loading="state.userDeleteSubmitted"
            @click="deleteUserClick"
          >
            {{ $localize('DeleteUser') }}
          </VueButton>

          <VueButton
            :type="VueButtonTypes.primary"
            :loading="state.userEditSubmitted"
            :aria-describedby="buttonAriaDescribedby"
            @click="updateUserClick"
          >
            {{ $localize('SaveChanges') }}
          </VueButton>
        </span>

        <span v-if="isAddNewUserMode">
          <VueButton
            :type="VueButtonTypes.primary"
            :loading="state.userEditSubmitted"
            :aria-describedby="buttonAriaDescribedby"
            @click="createUserClick"
          >
            {{ $localize('AddUserTitle') }}
          </VueButton>
        </span>
      </div>
    </div>

    <delete-user-confirmation-modal
      v-if="state.showDeletePrompt"
      :is-authenticated-user-selected="selectedUserId === contextData.userData.personaId"
      @delete="deleteUserConfirmed"
      @close="state.showDeletePrompt = false"
    />

    <self-remove-admin-rights-confirmation-dialog
      v-if="state.showAdminSelfRemovePrompt"
      @update="updateUserConfirmed"
      @close="state.showAdminSelfRemovePrompt = false"
    />

    <group-save-warning-modal
      v-if="state.showGroupSaveWarningModal"
      :pre-registered-users-assigned-count="1"
      @save="saveGroupsConfirmed"
      @close="state.showGroupSaveWarningModal=false"
    />
  </div>
</template>

<script lang="ts" setup>
import { reactive, onMounted, watch, ref } from 'vue';
import { useForm } from 'vee-validate';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import logger from '@/Utils/logger';
import redirectHelper from '@/Utils/redirectHelper';
import resourceHelper from '@/Utils/resourceHelper';
import constants from '@/constants';
import usersRepository from '@/Repositories/usersRepository';
import groupsRepository from '@/Repositories/groupsRepository';
import AccountSpecificLabelsProvider from '@/Utils/accountSpecificLabelsProvider';

import UserGroupSelectionModel from '@/VueComponents/PageComponents/Users/Models/userGroupSelectionModel';
import UserDetailsServerModel from '@/VueComponents/PageComponents/Users/Models/userDetailsServerModel';
import UpdateUserDetailsModel from '@/VueComponents/PageComponents/Users/Models/updateUserDetailsModel';
import CreateUserDetailsModel from '@/VueComponents/PageComponents/Users/Models/createUserDetailsModel';
import UserDetailsValidationRules from '@/VueComponents/PageComponents/Users/Models/userDetailsValidationRules';

import TextInputField from '@/VueComponents/SharedComponents/InputFields/TextInputField.vue';
import GroupSaveWarningModal from '@/VueComponents/Modals/GroupSaveWarningModal.vue';
import DeleteUserConfirmationModal from '@/VueComponents/Modals/DeleteUserConfirmationModal.vue';
import SelfRemoveAdminRightsConfirmationDialog from '@/VueComponents/Modals/SelfRemoveAdminRightsConfirmationDialog.vue';
import VueButton from '@/VueComponents/SharedComponents/Buttons/VueButton.vue';
import ServerErrorList from '@/VueComponents/SharedComponents/ErrorList/ServerErrorList.vue';
import GroupCheckboxLabel from '@/VueComponents/PageComponents/Users/Components/GroupCheckboxLabel.vue';
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps<{
  pageMode: string;
  selectedUserId?: string | undefined,
  validationRules: UserDetailsValidationRules
}>();

const emit = defineEmits<{(e: 'updated', user: UserDetailsServerModel): void,
(e: 'deleted', userId: string): void
}>();

const state: {
  givenName: string;
  familyName: string;
  emailAddress: string;
  phoneNumber: string;
  isBusinessAdminUser: boolean;
  isBusinessPrimaryContact: boolean;
  isPreregistered: boolean;
  userGroupSelections: UserGroupSelectionModel[],
  isLoadingFormData: boolean,
  showAdminSelfRemovePrompt: boolean,
  showDeletePrompt: boolean,
  showGroupSaveWarningModal: boolean,
  userEditSubmitted: boolean,
  userDeleteSubmitted: boolean,
  serverErrors: string[]
} = reactive({
  givenName: '',
  familyName: '',
  emailAddress: '',
  phoneNumber: '',
  isBusinessAdminUser: false,
  isBusinessPrimaryContact: false,
  isPreregistered: false,
  userGroupSelections: [],
  isLoadingFormData: false,
  showAdminSelfRemovePrompt: false,
  showDeletePrompt: false,
  showGroupSaveWarningModal: false,
  userEditSubmitted: false,
  userDeleteSubmitted: false,
  serverErrors: []
});

const contextData = useContextDataStore();

const form = useForm({
  initialValues: {
    'givenName': state.givenName,
    'familyName': state.familyName,
    'emailAddress': state.emailAddress,
    'phoneNumber': state.phoneNumber
  }
});

const primaryContactTooltip = resourceHelper.getString(
    AccountSpecificLabelsProvider.getAccountLabelKey('BusinessUserDetailsIsPrimaryContactCheckboxExplanatoryText'));
const emptyGroupTooltip = resourceHelper.getString('EmptyGroupTitle');
const groupWithUsersTooltip = resourceHelper.getString('GroupWithUsers');
const isAdminTooltip = resourceHelper.getString(
    AccountSpecificLabelsProvider.getAccountLabelKey('BusinessUserDetailsIsAdminCheckboxExplanatoryText'));

const businessId: string = contextData.userData.business.businessId;
const isAddNewUserMode = props.pageMode === constants.pageMode.add;
const buttonAriaDescribedby = 'givenNameValidationMessage familyNameValidationMessage emailAddressValidationMessage'+
                'phoneNumberValidationMessage serverSideValidationMessages';

const givenNameInput = ref<HTMLElement>();

watch(() => props.selectedUserId, newValue => {
  if (newValue) {
    loadUserDetails(newValue);
  }
});

onMounted(async () => {
  if (!isAddNewUserMode) {
    if (props.selectedUserId) {
      await loadUserDetails(props.selectedUserId);
    }
  } else {
    await loadUserGroups(businessId);
    givenNameInput.value.focus();
  }
});

async function loadUserDetails(userId: string) {
  state.serverErrors = [];
  state.isLoadingFormData = true;
  try {
    const result = await usersRepository.getUserDetails(businessId, userId);
    if (props.selectedUserId !== userId) {
      // Dto from server is outdated as selection already changed, ignore this dto
      return;
    }
    mapUserDetails(result);
    givenNameInput.value.focus();
  } catch (jqXhr: any) {
    handleServerError(jqXhr, 'UnexpectedErrorWhileGettingUserDetails');
  } finally {
    state.isLoadingFormData = false;
  }
}

async function loadUserGroups(businessId: string) {
  state.serverErrors = [];
  try {
    const result = await groupsRepository.getAllGroupNames(businessId);
    state.userGroupSelections = result
        .map(x => {
          return new UserGroupSelectionModel(x.groupId, x.name, false, x.groupIsEmpty);
        })
        .sort((a, b) => a.name.localeCompare(b.name));
  } catch (jqXhr: any) {
    handleServerError(jqXhr, 'UnexpectedErrorWhileGettingUserGroups');
  }
}

function isAuthenticatedUserAdminRightsUncheckedSelected() {
  return props.selectedUserId === contextData.userData.personaId && !state.isBusinessAdminUser;
}

function buildUpdateUserDto(): UpdateUserDetailsModel {
  return new UpdateUserDetailsModel(state.givenName,
      state.familyName,
      state.phoneNumber,
      state.isBusinessAdminUser,
      state.isBusinessPrimaryContact,
      state.userGroupSelections
          .filter((x: UserGroupSelectionModel) => x.groupIsSelected)
          .map((x: UserGroupSelectionModel) => x.toShortModel())
  );
}

function buildCreateUserDto(): CreateUserDetailsModel {
  return new CreateUserDetailsModel(state.emailAddress,
      state.givenName,
      state.familyName,
      state.phoneNumber,
      state.isBusinessAdminUser,
      state.isBusinessPrimaryContact,
      state.userGroupSelections
          .filter((x: UserGroupSelectionModel) => x.groupIsSelected)
          .map((x: UserGroupSelectionModel) => x.toShortModel())
  );
}

function updateCurrentUserContextData(serverUserDetailsDto: UserDetailsServerModel) {
  if (contextData.userData.personaId !== serverUserDetailsDto.personaId) {
    return;
  }

  contextData.userData.givenName(serverUserDetailsDto.givenName);
  contextData.userData.familyName(serverUserDetailsDto.familyName);
  contextData.userData.displayName(serverUserDetailsDto.givenName + ' ' + serverUserDetailsDto.familyName);
  contextData.userData.phoneNumber(serverUserDetailsDto.phoneNumber);
  contextData.userData.isBusinessAdminUser = serverUserDetailsDto.isBusinessAdminUser;
}

function mapUserDetails(serverModel: UserDetailsServerModel) {
  state.givenName = serverModel.givenName;
  state.familyName = serverModel.familyName;
  state.emailAddress = serverModel.emailAddress;
  state.phoneNumber = serverModel.phoneNumber;
  state.isBusinessAdminUser = serverModel.isBusinessAdminUser;
  state.isBusinessPrimaryContact = serverModel.isBusinessPrimaryContact;
  state.isPreregistered = serverModel.isPreregistered;
  state.userGroupSelections = serverModel.businessUserGroupSelections
      .map(x => new UserGroupSelectionModel(x.groupId, x.name, x.groupIsSelected, x.groupIsEmpty))
      .sort((a, b) => a.name.localeCompare(b.name));
}

async function saveGroupsConfirmed() {
  state.showGroupSaveWarningModal = false;
  if (isAuthenticatedUserAdminRightsUncheckedSelected()) {
    state.showAdminSelfRemovePrompt = true;
    return;
  }

  await updateUserConfirmed();
}

async function createUserClick() {
  const isValid = (await form.validate()).valid;
  if (!isValid) {
    return;
  }

  state.serverErrors = [];
  state.userEditSubmitted = true;
  try {
    await usersRepository.createBusinessUser(businessId, buildCreateUserDto());
    logger.success('BusinessUserCreatedSummary');
    router.push('/users');
  } catch (jqXhr: any) {
    handleServerError(jqXhr, 'UnexpectedErrorWhileCreatingNewUser');
  } finally {
    state.userEditSubmitted = false;
  }
}

async function updateUserClick() {
  const isValid = (await form.validate()).valid;
  if (!isValid) {
    return;
  }

  if (state.userGroupSelections.length > 0) {
    const assignedToEmptyGroup = state.userGroupSelections.some(x => x.groupIsSelected && x.groupIsEmpty);
    if (assignedToEmptyGroup && state.isPreregistered) {
      state.showGroupSaveWarningModal = true;
      return;
    }
  }

  if (isAuthenticatedUserAdminRightsUncheckedSelected()) {
    state.showAdminSelfRemovePrompt = true;
    return;
  }

  updateUserConfirmed();
}

async function updateUserConfirmed() {
  state.serverErrors = [];
  state.showAdminSelfRemovePrompt = false;
  state.userEditSubmitted = true;

  try {
    const result = await usersRepository.updateBusinessUserDetails(businessId, props.selectedUserId, buildUpdateUserDto());
    mapUserDetails(result);
    updateCurrentUserContextData(result);
    emit('updated', result);
    // Show success toaster:
    logger.success('BusinessUserUpdatedSummary');
    // Refresh and reset the page if current user is not admin anymore:
    if (props.selectedUserId === contextData.userData.personaId && !state.isBusinessAdminUser) {
      router.push('/requests');
    }
  } catch (jqXhr: any) {
    handleServerError(jqXhr, 'UnexpectedErrorWhileUpdatingUserDetails');
  } finally {
    state.userEditSubmitted = false;
  }
}

function deleteUserClick() {
  state.showDeletePrompt = true;
}

async function deleteUserConfirmed() {
  state.serverErrors = [];
  state.showDeletePrompt = false;
  state.userDeleteSubmitted = true;

  try {
    await usersRepository.deleteBusinessUser(businessId, props.selectedUserId);
    emit('deleted', props.selectedUserId);

    logger.success('BusinessUserDeletedSummary');

    if (props.selectedUserId === contextData.userData.personaId) {
      redirectHelper.logoutRedirect();
    }
  } catch (jqXhr: any) {
    handleServerError(jqXhr, 'UnexpectedErrorWhileDeletingUser');
  } finally {
    state.userDeleteSubmitted = false;
  }
}

function handleServerError(jqXhr: any, message: string) {
  if (jqXhr.serverErrorMessages) {
    state.serverErrors = jqXhr.serverErrorMessages;
    return;
  }
  // Log generic error:
  if (!jqXhr.errorHasBeenLogged) {
    logger.error(message, null, jqXhr);
  }
}
</script>

<style scoped lang="scss">
@import "sass/bootstrap-overrides/_variables.scss";
@import "sass/site/_business-user-details.scss";

div[disabled="true"]{
  pointer-events: none;
  opacity: 0.7
}
</style>
