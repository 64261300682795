import resourceHelper from '@/Utils/resourceHelper';
import localStorageHelper from '@/Utils/localStorageHelper';
import { LocalStorageKeyType } from '@/Types/Enums/localStorageKeyType';

export default class UploadsTableConfiguration {
  tableFields: { name: string, label: string }[];
  sortOptions: { field: string, label: string }[];
  pageSize: number;
  pageSizeStorageKey: LocalStorageKeyType;

  constructor(pageSizeStorageKey: LocalStorageKeyType) {
    this.pageSizeStorageKey = pageSizeStorageKey;
    this.tableFields = [
      {
        name: 'select',
        label: ''
      },
      {
        name: 'fileName',
        label: resourceHelper.getString('FileName')
      },
      {
        name: 'status',
        label: resourceHelper.getString('Status')
      },
      {
        name: 'size',
        label: resourceHelper.getString('Size')
      },
      {
        name: 'dateAdded',
        label: resourceHelper.getString('DateCreated')
      },
      {
        name: 'addedBy',
        label: resourceHelper.getString('CreatedBy')
      },
      {
        name: 'path',
        label: resourceHelper.getString('Path')
      }
    ];
    this.sortOptions = [
      {
        field: 'addedTimestamp',
        label: resourceHelper.getString('DateCreated')
      },
      {
        field: 'fileName',
        label: resourceHelper.getString('FileName')
      },
      {
        field: 'size',
        label: resourceHelper.getString('Size')
      },
      {
        field: 'statusName',
        label: resourceHelper.getString('Status')
      },
      {
        field: 'userName',
        label: resourceHelper.getString('CreatedBy')
      },
      {
        field: 'path',
        label: resourceHelper.getString('Path')
      }
    ];

    this.pageSize = this.getPageSize();
  }

  getPageSize() {
    const defaultPageSize = 10;

    if (!this.pageSizeStorageKey) {
      return defaultPageSize;
    }

    const pageSize = localStorageHelper.getSessionValue(this.pageSizeStorageKey);
    return pageSize ? Number(pageSize) : defaultPageSize;
  }
}