<template>
  <PaginatedList
    v-bind="$attrs"
    :columns="[
      { name: 'ReferenceNumber', title: resourceHelper.getString('UploadFolderReferenceNumber') },
      { name: 'Status', title: resourceHelper.getString('StatusAndPriority') },
      { name: 'InternalReference', title: resourceHelper.getString('InternalReference') },
      { name: 'RmsId', title: resourceHelper.getString('RmsId') },
      { name: 'AssignedTo', title: resourceHelper.getString('AssignedTo') },
      { name: 'CreatedBy', title: resourceHelper.getString('CreatedBy') },
      { name: 'CreatedOn', title: resourceHelper.getString('CreatedOn') }]"
    :items="uploadFolders"
    :highlighted-index="highlightedIndex"
    :actions="[
      { title: resourceHelper.getString('ViewUploadFolder'), icon: 'far fa-eye',
        eventName: UploadFolderListActionTypes.viewFolder },
      { title: resourceHelper.getString('CreateReport'), icon: 'far fa-file request-list__ellipsis-dropdown-icon',
        eventName: UploadFolderListActionTypes.createReport }]"
    @action-clicked="onActionClicked"
  >
    <template #ReferenceNumber="opts">
      <div class="request-list__reference-container">
        <PaginatedListLinkField
          :text="opts.item.referenceNumber"
          :href="'/uploadfolderdetails/' + opts.item.id"
        />
        <div
          v-if="opts.item.discussionMessagesCount"
          class="request-list__counts-container mt-3"
        >
          <i
            v-tooltip.top="{
              class: 'request-list__tooltip',
              value: $localize('DiscussionMessageCountTooltipForUploadFolder'),
              pt: {
                text: 'request-list__tooltip-text',
                arrow: 'request-list__tooltip-arrow'
              },
              showDelay: 600
            }"
            class="fas fa-comments request-list__counts-icon"
            aria-hidden="true"
          />
          <span
            class="sr-only"
          >{{ $localize('Messages') }}</span>
          <span>{{ opts.item.discussionMessagesCount }}</span>
        </div>
      </div>
    </template>

    <template #Status="opts">
      <PaginatedListLabeledField
        :label="$localize('Status')"
      >
        {{ opts.item.statusName }}
      </PaginatedListLabeledField>
      <PaginatedListLabeledField
        v-if="opts.item.priorityName"
        :label="$localize('Priority')"
      >
        {{ opts.item.priorityName }}
      </PaginatedListLabeledField>
    </template>

    <template #InternalReference="opts">
      <PaginatedListLabeledField
        :label="$localize('InternalReference')"
      >
        <span v-if="opts.item.internalReferenceNumber">{{ opts.item.internalReferenceNumber }}</span>
        <span
          v-else
          class="text-muted"
        >{{ $localize('NotApplicable') }}</span>
      </PaginatedListLabeledField>
    </template>

    <template #RmsId="opts">
      <PaginatedListLabeledField
        :label="$localize('RmsId')"
      >
        <span v-if="opts.item.rmsId">{{ opts.item.rmsId }}</span>
        <span
          v-else
          class="text-muted"
        >{{ $localize('NotApplicable') }}</span>
      </PaginatedListLabeledField>
      <PaginatedListLabeledField
        :label="$localize('CadIds')"
      >
        <CollapsedList
          v-if="hasCadIds(opts.item)"
          :options="opts.item.cadIds"
          :max-collapsed-items-count="3"
        />
        <span
          v-else
          class="text-muted"
        >{{ $localize('NotApplicable') }}</span>
      </PaginatedListLabeledField>
    </template>

    <template #AssignedTo="opts">
      <PaginatedListLabeledField
        :label="$localize('AssignedTo')"
      >
        <span v-if="!!opts.item.assignedTo">
          {{ opts.item.assignedTo }}
        </span>
        <span
          v-else
          class="text-muted"
        >
          {{ $localize('Unassigned') }}
        </span>
      </PaginatedListLabeledField>
    </template>

    <template #CreatedBy="opts">
      <PaginatedListLabeledField
        :label="$localize('CreatedBy')"
      >
        <span>
          {{ opts.item.createBy }}
        </span>
      </PaginatedListLabeledField>
    </template>

    <template #CreatedOn="opts">
      <PaginatedListLabeledField
        :label="$localize('DateCreated')"
      >
        <span>
          {{ opts.item.createdOn }}
        </span>
      </PaginatedListLabeledField>
    </template>
  </PaginatedList>

  <CreateReportModal
    v-if="reportModalVisible"
    :is-danger="false"
    :report-item-reference-number="reportItemReferenceNumber"
    :report-item-id="reportItemId"
    :item-type="CreateReportModalTypes.uploadFolder"
    @close="createReportModalCloseFunc"
  />
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import CreateReportModal from '@/VueComponents/Modals/CreateReportModal.vue';
import CollapsedList from '@/VueComponents/SharedComponents/CollapsedList/CollapsedList.vue';
import UploadFoldersListModel from '@/VueComponents/UploadFolders/UploadFoldersList/Models/UploadFoldersListModel';
import PaginatedList from '@/VueComponents/SharedComponents/PaginatedList/PaginatedList.vue';
import PaginatedListLinkField from '@/VueComponents/SharedComponents/PaginatedList/PaginatedListLinkField.vue';
import PaginatedListLabeledField from '@/VueComponents/SharedComponents/PaginatedList/PaginatedListLabeledField.vue';
import resourceHelper from '@/Utils/resourceHelper';
import { CreateReportModalTypes } from '@/Types/Enums/createReportModalTypes';
import { UploadFolderListActionTypes } from '@/VueComponents/UploadFolders/UploadFoldersList/Enums/uploadFolderListActionTypes';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps({
  uploadFolders: { type: Array<UploadFoldersListModel>, required: false, default: () => [] },
  lastAccessedResponseId: { type: String, required: false, default: () => null }
});

const highlightedIndex = computed(() => {
  for (let i = 0; i < props.uploadFolders.length; i++) {
    if (props.uploadFolders[i].id === props.lastAccessedResponseId) {
      return i;
    }
  }

  return -1;
});

const reportModalVisible = ref(false);
const reportItemReferenceNumber = ref(null);
const reportItemId = ref(null);

function hasCadIds(uploadFolder: UploadFoldersListModel) {
  return uploadFolder.cadIds && uploadFolder.cadIds.length;
}

function showReportModal(itemReferenceNumber: string, itemId: string) {
  reportItemReferenceNumber.value = itemReferenceNumber;
  reportItemId.value = itemId;
  reportModalVisible.value = true;
}

async function createReportModalCloseFunc() {
  reportModalVisible.value = false;
}

function onActionClicked(actionName: string, payload: any) {
  switch (actionName) {
    case UploadFolderListActionTypes.viewFolder:
      viewFolderClicked(payload);
      break;
    case UploadFolderListActionTypes.createReport:
      createReportClicked(payload);
      break;
  }
}

function viewFolderClicked(payload) {
  router.push('/uploadfolderdetails/' + payload.item.id);
}

function createReportClicked(payload) {
  showReportModal(payload.item.referenceNumber, payload.item.id);
}
</script>