import FolderModel from '@/VueComponents/FileUploadSelector/models/folderModel';
import FileUploadModel from '@/VueComponents/FileUploadSelector/models/fileUploadModel';
import { FolderUploadType } from '@/VueComponents/FileUploadSelector/enums/folderUploadType';
import { FileUploadType } from '@/VueComponents/FileUploadSelector/enums/fileUploadType';

export default class FolderUploadModel extends FolderModel {
  type: FolderUploadType;

  constructor(folder: FolderModel, type: FolderUploadType) {
    super(folder.name, folder.files);
    this.type = type;
  }

  public generateFileUploadModels(): FileUploadModel[] {
    return this.files.map(f => new FileUploadModel(f, this.getFilesType()));
  }

  private getFilesType(): FileUploadType {
    switch (this.type) {
      case FolderUploadType.cctv:
        return FileUploadType.cctvFile;
      case FolderUploadType.deviceBackup:
        return FileUploadType.deviceBackupFile;
      default:
        return FileUploadType.generalContentFile;
    }
  }
}