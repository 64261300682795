<template>
  <FileSelector
    @add-files="onFilesAdded"
    @add-folders="onFoldersAdded"
  />

  <SelectedZipFilesWarningModal
    v-if="isModalShowing"
    v-bind="modalProps"
    @cancel="closeModal"
    @continue="onModalContinue"
  />
</template>

<script lang="ts" setup>
import FileSelector from '@/VueComponents/FileUploadSelector/FileSelector.vue';
import FileModel from '@/VueComponents/FileUploadSelector/models/fileModel';
import logger from '@/Utils/logger';
import FolderModel from '@/VueComponents/FileUploadSelector/models/folderModel';
import SelectedZipFilesWarningModal from '@/VueComponents/FileUploadSelector/modals/SelectedZipFilesWarningModal.vue';
import useModalController from '@/VueCore/utils/useModalController';
import UploadSelectorUtils from '@/VueComponents/FileUploadSelector/utils/uploadSelectorUtils';
import FileUploadModel from '@/VueComponents/FileUploadSelector/models/fileUploadModel';
import { FileUploadType } from '@/VueComponents/FileUploadSelector/enums/fileUploadType';

const emit = defineEmits<{addFiles: [files: FileUploadModel[]]}>();

const { isShowing: isModalShowing, show: showModal, close: closeModal, props: modalProps } =
  useModalController<{ filesCount: number }>();

// Stores non-zip files to initiate uploading after continuing from the modal dialog
let selectedFiles: FileUploadModel[] = [];

function onFilesAdded(files: FileModel[]) {
  const { zipFiles, nonZipFiles } = UploadSelectorUtils.splitRegularAndZipFiles(files);
  handleFileAdding(zipFiles, nonZipFiles, FileUploadType.file);
}

function onFoldersAdded(folders: FolderModel[]) {
  const { zipFiles, nonZipFiles } = UploadSelectorUtils.splitRegularAndZipFiles(folders.flatMap(f => f.files));
  handleFileAdding(zipFiles, nonZipFiles, FileUploadType.folderFile);
}

function handleFileAdding(zipFiles: FileModel[], nonZipFiles: FileModel[], type: FileUploadType) {
  const hasOnlyZips = zipFiles.length && !nonZipFiles.length;
  const hasAnyZips = !!zipFiles.length;

  // When only zip files selected - show the warning toaster
  if (hasOnlyZips) {
    logger.warning('ZipUploadWarningMessage');
    return;
  }

  const fileModels = nonZipFiles.map(f => new FileUploadModel(f, type));

  // When files or folders contains zip files - show the warning modal
  if (hasAnyZips) {
    selectedFiles = fileModels;
    showModal({ filesCount: zipFiles.length });
    return;
  }

  emit('addFiles', fileModels);
}

function onModalContinue() {
  closeModal();
  emit('addFiles', selectedFiles);
}
</script>