<template>
  <div class="location-tab">
    <div>
      <input
        id="AssignLocation"
        v-model="isLocationAssigned"
        class="item-checkbox mt-0"
        type="checkbox"
        :checked="isLocationAssigned"
        @change="checkboxClickHandler"
      >
      <label
        for="AssignLocation"
        class="ml-1"
      >{{ $localize('AssignGroupLocation') }}</label>
    </div>
    <div
      v-if="!isLocationAssigned"
      class="blank-area blank-area-height-group-details mt-6"
    >
      <img
        src="/Content/images/no-location.svg"
        class="mt-6 mb-6"
        alt=""
      >
      <h4>{{ $localize('LocationNotAssignedHeader') }}</h4>
      <p>{{ $localize('LocationNotAssignedText') }}</p>
    </div>
    <div
      v-else
      class="row mt-3"
    >
      <div class="col-md-12">
        <div class="form-group">
          <TextInputField
            id="groupAddress"
            v-model="groupAddress"
            :label="$localize('GroupAddress')"
            :validation-rules="computedValidationRules"
            :error-id="'groupAddressValidationMessage'"
            :required="isLocationAssigned"
            @input="validateGroupAddress"
            @change="emit('groupAddressUpdated', groupAddress)"
          />
        </div>
      </div>
      <a
        href="#"
        @click.prevent="updateMarkerFromAddress"
      >{{ $localize('BusinessUpdateMarkerFromAddress') }}</a> <strong>{{ $localize('BusinessDragMarker') }}</strong>

      <div :class="{ 'is-disabled': searching }">
        <GroupLocationMap
          v-model:coordinate="vueMapProps.coordinate"
          v-model:group-coordinate="vueMapProps.groupCoordinate"
          v-model:zoom="vueMapProps.zoom"
        />
      </div>
      <span>{{ $localize('RequiredFieldInfo') }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, reactive, computed } from 'vue';
import { useForm } from 'vee-validate';

import addressLookupRepository from '@/Repositories/addressLookupRepository';
import { ApplicationError } from '@/VueCore/services/clients/applicationError';
import GroupLocationMap from '@/VueComponents/Groups/GroupLocationMap.vue';
import logger from '@/Utils/loggerVue';
import MapCoordinate from '@/Types/mapCoordinate';
import TextInputField from '@/VueComponents/SharedComponents/InputFields/TextInputField.vue';

const props = defineProps<{
  address: string;
  coordinate: MapCoordinate;
  isLocationAssigned: boolean;
  initialCoordinate: MapCoordinate;
  validationRules: object;
}>();

const emit = defineEmits<{(e: 'isLocationAssignedUpdated', isLocationAssigned: boolean): void,
  (e: 'groupAddressUpdated', groupAddress: string): void,
  (e: 'mapDetailsSaved', mapDetails: MapCoordinate): void,
}>();

const groupAddress = ref(props.address);
const coordinate = ref(props.coordinate);
const isLocationAssigned = ref(props.isLocationAssigned);
const mapCenterCoordinate = ref(props.initialCoordinate);
const searching = ref(false);
const savedMapDetails = reactive({});
const defaultZoomLevel = 17;

const vueMapProps = reactive({
  coordinate: mapCenterCoordinate.value,
  groupCoordinate: coordinate.value,
  zoom: defaultZoomLevel
});

// Dynamic validation rules based on checkbox state
const computedValidationRules = computed(() => {
  if (isLocationAssigned.value) {
    return {
      ...props.validationRules.address,
      required: { errorMessage: 'GroupNoAddress' }
    };
  }
  return props.validationRules.address;
});

const form = useForm({
  initialValues: {
    'address': groupAddress.value
  }
});

defineExpose({
  isValid
});

async function isValid() {
  return (await form.validate()).valid;
}

const validateGroupAddress = async () => {
  if (isLocationAssigned.value) {
    const result = await isValid();
    if (!result) {
      return;
    }
  }
};

const updateMarkerFromAddress = () => {

  if (!groupAddress.value || !groupAddress.value.trim()) {
    return;
  }

  searching.value = true;

  addressLookupRepository.lookupAddress(groupAddress.value, false)
      .then(addressSearchResults => {
        searching.value = false;
        if (!addressSearchResults.length) {
          logger.info('AddressSearchNoResultsMessage');
          return;
        }

        const newCoordinate = addressSearchResults[0].coordinate;
        coordinate.value = newCoordinate;
        mapCenterCoordinate.value = newCoordinate;
        vueMapProps.coordinate = newCoordinate;
        vueMapProps.groupCoordinate = newCoordinate;
        vueMapProps.zoom = defaultZoomLevel;
        emit('mapDetailsSaved', newCoordinate);
        emit('isLocationAssignedUpdated', isLocationAssigned.value);
      })
      .catch((error: ApplicationError) => {
        searching.value = false;
        logger.error('UnexpectedErrorWhileRetrievingAddressLookup', null, error);
      });
};

const checkboxClickHandler = () => {
  if (!isLocationAssigned.value) {
    saveMapDetails();
  } else {
    restoreMapDetails();
  }
  emit('isLocationAssignedUpdated', isLocationAssigned.value);
};

const saveMapDetails = () => {
  savedMapDetails.coordinate = vueMapProps.groupCoordinate;
  savedMapDetails.groupAddress = groupAddress.value;
  coordinate.value = savedMapDetails.coordinate;
  groupAddress.value = savedMapDetails.groupAddress;
  mapCenterCoordinate.value = savedMapDetails.coordinate;

  vueMapProps.coordinate = savedMapDetails.coordinate;
  vueMapProps.groupCoordinate = savedMapDetails.coordinate;
};

const restoreMapDetails = () => {
  if (savedMapDetails.groupAddress) {
    coordinate.value = savedMapDetails.coordinate;
    groupAddress.value = savedMapDetails.groupAddress;
    mapCenterCoordinate.value = savedMapDetails.coordinate;

    vueMapProps.coordinate = savedMapDetails.coordinate;
    vueMapProps.groupCoordinate = savedMapDetails.coordinate;
  }
  savedMapDetails.value = {};
};

watch(coordinate, newCoordinate => {
  vueMapProps.groupCoordinate = newCoordinate;
});

watch(mapCenterCoordinate, newCoordinate => {
  vueMapProps.coordinate = newCoordinate;
});

watch(() => props.address, newValue => {
  groupAddress.value = newValue;
});
</script>