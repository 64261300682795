<template>
  <div
    ref="mapRef"
    class="map"
  >
    <slot v-if="olMap" />
  </div>
</template>
<script lang="ts" setup>
import { onUnmounted, provide, Ref, ref, watch } from 'vue';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import MapCoordinate from '@/Types/mapCoordinate';
import MapPopup from '@/VueComponents/SharedComponents/Map/Models/mapPopup';
import { MapInjectionKeys } from '@/VueComponents/SharedComponents/Map/Enums/mapInjectionKeys';

const contextData = useContextDataStore();

const coordinate = defineModel<MapCoordinate>('coordinate', {
  required: false,
  default: { latitude: 51.5, longitude: -0.1 } // London
});
const zoom = defineModel<number>('zoom', {
  required: false,
  default: 2
});

const props = withDefaults(defineProps<{
  id?: string,
  popUpEnabled?: boolean
}>(), { popUpEnabled: false, id: undefined });

const mapRef = ref();
const olMap = ref();
const popup: Ref<MapPopup | undefined> = ref();

provide(MapInjectionKeys.map, olMap);
provide(MapInjectionKeys.popup, popup);

const minZoomLevel = contextData.portalSettings.mapViewMinZoomLevel;
const maxZoomLevel = contextData.portalSettings.mapViewMaxZoomLevel;

const unmountCallbacks: (() => void)[] = [];

onUnmounted(() => {
  for (const callback of unmountCallbacks) {
    callback();
  }
});

import(/* webpackChunkName: "open-layers" */ '@/Utils/openLayersDynamicModule')
    .then(olModule => {
      const Map = olModule.Map;
      const TileLayer = olModule.TileLayer;
      const View = olModule.View;
      const OSM = olModule.OSM;
      const toLonLat = olModule.toLonLat;
      const fromLonLat = olModule.fromLonLat;

      olMap.value = new Map({
        target: mapRef.value,
        layers: [
          new TileLayer({
            source: new OSM()
          })
        ],
        view: new View({
          center: fromLonLat([coordinate.value.longitude, coordinate.value.latitude]),
          zoom: zoom.value,
          minZoom: minZoomLevel,
          maxZoom: maxZoomLevel
        })
      });

      const coordinateWatcher = watch(coordinate, newValue => {
        const lonLat = [newValue.longitude, newValue.latitude];
        olMap.value.getView().setCenter(fromLonLat(lonLat));
      });

      const zoomWatcher = watch(zoom, newValue => {
        olMap.value.getView().setZoom(newValue);
      });

      const moveEndEventKey = olMap.value.on('moveend', function () {

        const centerLonLat = toLonLat(olMap.value.getView().getCenter());
        const mapZoom = olMap.value.getView().getZoom();

        coordinate.value = { latitude: centerLonLat[1], longitude: centerLonLat[0] };

        zoom.value = mapZoom!;
      });

      if (props.popUpEnabled) {
        popup.value = createPopUp(olModule, olMap.value);
      }

      unmountCallbacks.push(() => {
        olMap.value.un('moveend', moveEndEventKey);
        olMap.value.setTarget(undefined);

        // Disable vue watchers
        coordinateWatcher();
        zoomWatcher();
      });
    });

function createPopupContainer(containerId: string) {
  const container = document.createElement('div');
  container.classList.add('map-popup');
  container.id = containerId;

  const content = document.createElement('div');

  const closer = document.createElement('a');
  closer.classList.add('map-popup-closer');

  container.appendChild(content);
  container.appendChild(closer);

  document.body.appendChild(container);

  return container;
}

function createPopUp(olModule: any, map: any) {
  const Overlay = olModule.Overlay;

  const containerId = props.id ? `${props.id}-popup-container` : 'popup-container';

  let containerElement = document.getElementById(containerId);

  if (!containerElement) {
    containerElement = createPopupContainer(containerId);
  }

  const contentElement = containerElement.firstElementChild;
  const closeElement = containerElement.lastElementChild;

  const overlay = new Overlay({
    element: containerElement
  });

  map.addOverlay(overlay);

  const closeHandler = () => {
    overlay.setPosition(undefined);
    contentElement.innerHTML = '';
  };

  closeElement.addEventListener('click', closeHandler);

  unmountCallbacks.push(() => {
    closeElement?.removeEventListener('click', closeHandler);
  });

  const mapPopup = {
    show: function (feature, text) {

      const coordinate = feature.getGeometry().getCoordinates();
      contentElement.innerHTML = text;
      overlay.setPosition(coordinate);
    }
  };

  return mapPopup;
}
</script>