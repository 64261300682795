<template>
  <div class="form-group checkbox-group">
    <label class="checkbox-label">
      <i
        :class="iconClass"
        aria-hidden="true"
      />
      <span class="checkbox-label-text">{{ $localize(labelKey) }}</span>
      <span class="pull-right icon-span_container">
        <i
          v-tooltip.top="{
            class: 'custom-tooltip-container',
            value: tooltipText,
            pt: {
              text: 'custom-tooltip-text',
              arrow: 'custom-tooltip-arrow'
            },
            showDelay: 600
          }"
          class="fas fa-question-circle checkbox-label-icon"
          aria-hidden="true"
        />
        <input
          v-model="model"
          type="checkbox"
        >
      </span>
    </label>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  iconClass: string;
  labelKey: string;
  tooltipText: string;
}>();

const model = defineModel<boolean>();
</script>

<style scoped lang="scss">
.icon-span_container {
    display: flex;
    align-items: center;
}

.fa-question-circle {
    margin-top: 5px;
}
</style>
