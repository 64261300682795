<template>
  <div
    :class="{ 'drop-zone-active': dropZoneActive }"
    class="drop-zone-default"
    @dragover.stop.prevent
    @dragenter.stop.prevent="onDragEnter"
    @dragleave.stop.prevent="onDragLeave"
    @drop.stop.prevent="onDrop"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import dropZoneUploadProvider from '@/Bindings/helpers/dropZoneUploadProvider';
import logger from '@/Utils/logger';
import FileSelectorMapper from '@/VueComponents/FileUploadSelector/utils/fileSelectorMapper';

const emit = defineEmits(['addFiles', 'addFolders']);

const dropZoneActive = ref(false);
let counter = 0;

function onDragEnter() {
  counter++;
  dropZoneActive.value = true;
}

function onDragLeave() {
  counter--;

  if (counter === 0) {
    dropZoneActive.value = false;
  }
}

async function onDrop(event: DragEvent) {
  counter = 0;
  dropZoneActive.value = false;

  const uploadEntries = dropZoneUploadProvider.parseFilesToEntries(event.dataTransfer.items);
  const { files, directories } = dropZoneUploadProvider.splitFilesAndDirectories(uploadEntries);

  if (files.length && directories.length) {
    logger.warning('SelectFilesOrFolders');
    return;
  }

  if (files.length) {
    const fileModels = await Promise.all(files.map(f => FileSelectorMapper.mapFileEntryToFileModel(f)));
    emit('addFiles', fileModels);
  }

  if (directories.length) {
    const folderModels = await Promise.all(directories.map(d => FileSelectorMapper.mapDirectoryEntryToFolderModel(d)));
    emit('addFolders', folderModels);
  }
}
</script>