<template>
  <div class="row">
    <div class="col-md-8">
      <ServerErrorList :errors="serverErrors" />
      <TextInputField
        id="givenName"
        ref="givenNameInput"
        v-model="givenName"
        :label="$localize('GivenName')"
        :validation-rules="validationRules?.givenName"
      />
      <TextInputField
        id="familyName"
        v-model="familyName"
        :label="$localize('FamilyName')"
        :validation-rules="validationRules?.familyName"
      />
      <TextInputField
        id="phoneNumber"
        v-model="phoneNumber"
        :label="$localize('PhoneNumber')"
        :validation-rules="validationRules?.phoneNumber"
      />

      <span>{{ $localize('RequiredFieldInfo') }}</span>

      <div class="text-right">
        <VueButton
          :type="VueButtonTypes.primary"
          :loading="saving"
          aria-describedby="givenNameValidationMessage familyNameValidationMessage phoneNumberValidationMessage serverErrorValidationMessages"
          @click="save"
        >
          {{ $localize('SaveChanges') }}
        </VueButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import { useForm } from 'vee-validate';

import UserDetailsValidationRules from '@/VueComponents/AccountDetails/Models/userDetailsValidationRules';
import ServerErrorList from '@/VueComponents/SharedComponents/ErrorList/ServerErrorList.vue';
import TextInputField from '@/VueComponents/SharedComponents/InputFields/TextInputField.vue';
import VueButton from '@/VueComponents/SharedComponents/Buttons/VueButton.vue';
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import { ApplicationError } from '@/VueCore/services/clients/applicationError';
import currentUserService from '@/VueCore/services/currentUserService';
import logger from '@/Utils/loggerVue';

defineProps<{
  validationRules: UserDetailsValidationRules
}>();

const contextData = useContextDataStore();
const givenName = ref(contextData.userData.givenName());
const familyName = ref(contextData.userData.familyName());
const phoneNumber = ref(contextData.userData.phoneNumber());

const givenNameInput = ref<HTMLElement>();
onMounted(() => {
  givenNameInput.value?.focus();
});

const form = useForm({
  initialValues: {
    givenName: givenName.value,
    familyName: familyName.value,
    phoneNumber: phoneNumber.value
  }
});

const serverErrors = ref<string[]>([]);
const saving = ref<boolean>(false);

async function save() {
  const validationResult = await form.validate();
  if (!validationResult.valid) {
    return;
  }

  saving.value = true;
  serverErrors.value = [];

  const currentUserData = {
    givenName: givenName.value,
    familyName: familyName.value,
    phoneNumber: phoneNumber.value
  };

  currentUserService.updateUserData(currentUserData)
      .then(function (newUserData) {

        // Once the updated user details have been saved and the new user details returned from the repository
        // then update the fields on the context data.
        contextData.userData.givenName(newUserData.givenName);
        contextData.userData.familyName(newUserData.familyName);
        contextData.userData.displayName(newUserData.displayName);
        contextData.userData.phoneNumber(newUserData.phoneNumber);
        contextData.userData.emailAddress(newUserData.emailAddress);

        logger.success('CurrentUserUpdatedSummary');
      })
      .catch((error: ApplicationError) => {
        if (error.errorData?.errorMessages?.length) {
          serverErrors.value = error.errorData.errorMessages;
          return;
        }

        if (!error.isLogged) {
          logger.error('UnexpectedErrorWhileUpdatingUserDetails', null, error);
        }
      })
      .finally(() => {
        saving.value = false;
      });
}
</script>
