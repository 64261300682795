<template>
  <ModalDialog
    :title="$localize('CctvExportFolderUploadModalTitle')"
    @close="onCancel"
  >
    <template #default>
      <div>
        {{ $localize(folders?.length ? 'UploadCctvFolderPrompt' : 'UploadCctvZipPrompt') }}
      </div>
      <br>

      <label
        class="checkbox-inline"
        for="cctv-upload-acknowledge"
      >
        <input
          id="cctv-upload-acknowledge"
          v-model="cctvConfirmed"
          class="item-checkbox"
          type="checkbox"
          required
          aria-describedby="cctvUploadConfirmationValidationMessage"
        >
        <span>
          {{ $localize('CctvExportFolderUploadModalConfirmation') }}
        </span>
      </label>
    </template>
    <template #footer>
      <VueButton
        :type="VueButtonTypes.default"
        @click="onCancel"
      >
        {{ $localize('Cancel') }}
      </VueButton>
      <VueButton
        :type="VueButtonTypes.primary"
        :disabled="!cctvConfirmed"
        @click="onConfirm"
      >
        {{ $localize('Continue') }}
      </VueButton>
    </template>
  </ModalDialog>
</template>

<script setup lang="ts">
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
import { ref } from 'vue';
import FileUploadModel from '@/VueComponents/FileUploadSelector/models/fileUploadModel';
import FolderUploadModel from '@/VueComponents/FileUploadSelector/models/folderUploadModel';
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';
import VueButton from '@/VueComponents/SharedComponents/Buttons/VueButton.vue';

const props = defineProps<{
  files?: FileUploadModel[],
  folders?: FolderUploadModel[]
}>();

const emit = defineEmits<{
  'confirm': [FileUploadModel[], FolderUploadModel[]],
  'cancel'
}>();

const cctvConfirmed = ref(false);

function onConfirm() {
  emit('confirm', props.files, props.folders);
}

function onCancel() {
  emit('cancel');
}
</script>
