<script setup lang="ts">
import { ref, computed } from 'vue';
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
import logger from '@/Utils/logger';
import resourceHelper from '@/Utils/resourceHelper';
import reportRepository from '@/Repositories/reportRepository';
import reportDownloader from '@/Utils/reportDownloader';
import { CreateReportModalTypes } from '@/Types/Enums/createReportModalTypes';
import TextInputField from '@/VueComponents/SharedComponents/InputFields/TextInputField.vue';
import { useForm } from 'vee-validate';

const emit = defineEmits < { 'close' }>();

const props = defineProps<{
  reportItemReferenceNumber: string,
  reportItemId: string,
  itemType: CreateReportModalTypes
}>();

const modalTitle = computed(() => {
  if (props.itemType === CreateReportModalTypes.request) {
    return resourceHelper.getString('CreateRequestReportModalTitle');
  }
  if (props.itemType === CreateReportModalTypes.uploadFolder) {
    return resourceHelper.getString('CreateUploadFolderReportModalTitle');
  }
  return '';
});

const reportTitle = ref(reportDownloader.getDefaultReportTitle(props.reportItemReferenceNumber));
const isCreatingReport = ref(false);

const form = useForm({
  initialValues: {
    reportTitle: reportTitle
  }
});

function onModalClose() {
  emit('close');
}

async function onModalCreateReport() {
  const validationResult = await form.validate();
  if (validationResult.valid) {
    await createReport(reportTitle.value, props.reportItemId);
  }
}

async function createReport(reportTitle: string, reportItemId: string) {
  switch (props.itemType) {
    case CreateReportModalTypes.request:
      await createRequestReport(reportTitle, reportItemId);
      break;
    case CreateReportModalTypes.uploadFolder:
      await createUploadFolderReport(reportTitle, reportItemId);
      break;
  }
}

function createUploadFolderReport(reportTitle: string, reportItemId: string) {
  isCreatingReport.value = true;
  reportRepository.getUploadFolderReport(getReportParams(reportTitle, reportItemId))
      .then(data => {
        reportDownloader.downloadReport(data, reportTitle);
        logger.success('UploadFolderReportCreated');
      })
      .catch(jqXhr => {
        if (jqXhr?.serverErrorMessages?.length) {
          logger.untranslatedWarning(jqXhr.serverErrorMessages[0]);
        } else {
          logger.error('UploadFolderReportFailed');
        }
      })
      .finally(() => {
        isCreatingReport.value = false;
        onModalClose();
      });
}

async function createRequestReport(reportTitle: string, reportItemId: string) {
  isCreatingReport.value = true;

  reportRepository.getRequestReport(getReportParams(reportTitle, reportItemId))
      .then(data => {
        reportDownloader.downloadReport(data, reportTitle);
        logger.success('RequestReportCreated');
      })
      .catch(jqXhr => {
        if (jqXhr?.serverErrorMessages?.length) {
          logger.untranslatedWarning(jqXhr.serverErrorMessages[0]);
        } else {
          logger.error('FailedToGenerateRequestReport', null, jqXhr);
        }
      })
      .finally(() => {
        isCreatingReport.value = false;
        onModalClose();
      });
}

function getReportParams(reportTitle: string, itemId: string) {
  const result: any = {
    title: reportTitle,
    clientTimezoneOffset: new Date().getTimezoneOffset().toString(),
    clientTimezoneName: new Date().toTimeString().slice(9)
  };

  if (props.itemType === CreateReportModalTypes.request) {
    result.requestId = itemId;
  } else if (props.itemType === CreateReportModalTypes.uploadFolder) {
    result.selfResponseId = itemId;
  }

  return result;
}

</script>

<template>
  <ModalDialog
    :title="modalTitle"
    @close="onModalClose"
  >
    <template #default>
      <div>
        <TextInputField
          id="reportTitleInput"
          v-model="reportTitle"
          maxlength="200"
          :label="$localize('Title')"
          :validation-rules="{ required: { message: $localize('TitleRequired') } }"
        />
        <div
          class="py-3"
        >
          {{ $localize('CreateReportModalText') }}
        </div>
      </div>
    </template>
    <template #footer>
      <button
        class="btn btn-default"
        @click="onModalClose"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        class="btn btn-primary"
        :disabled="isCreatingReport"
        @click="onModalCreateReport"
      >
        <span
          v-if="isCreatingReport"
          class="far fa-circle-notch fa-spin mr-2"
          aria-hidden="true"
        />
        <span>
          {{ $localize('Download') }}
        </span>
      </button>
    </template>
  </ModalDialog>
</template>
