<template>
  <FilterArea
    v-model="selectedGroupId"
    :entities="groups"
    filter-area-class="filter-area-height-admin-groups"
    :filter-fields="filterFields"
    :item-type="FilterAreaType.group"
    @change="selectGroup"
  >
    <template #option-content="{ slotProps }">
      <div
        :id="slotProps.option.groupId"
        :group-id="slotProps.option.groupId"
        class="filter-area-list-item ellipsis"
        :class="{ 'active': slotProps.option.groupId === selectedGroupId }"
      >
        <b :title="getGroupLabel(slotProps.option)">
          {{ getGroupLabel(slotProps.option) }}
        </b><br>
        <small
          :title="slotProps.option.membersSummary()"
          class="heading filter-item-heading ellipsis"
        >
          {{ slotProps.option.membersSummary() }}
        </small>
      </div>
    </template>
  </FilterArea>
</template>

<script setup lang="ts">
import FilterArea from '@/VueComponents/SharedComponents/FilterArea/FilterArea.vue';
import { FilterAreaType } from '@/VueComponents/SharedComponents/FilterArea/Enums/filterAreaType';
import { GroupSummaryDto } from '@/Models/groupManagement/groupSummaryDto';

defineProps<{
    groups: GroupSummaryDto[]
}>();

const selectedGroupId = defineModel<string | null>();
const selectGroup = (group: GroupSummaryDto) => {
  selectedGroupId.value = group.groupId;
};

const filterFields = ['groupDetails.groupName'];
const getGroupLabel = (group: GroupSummaryDto) => {
  return group.groupDetails.groupName;
};

</script>