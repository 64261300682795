<template>
  <div class="row-data-container">
    <div class="status-icon-container">
      <component
        :is="upload.isFolder ? 'button' : 'span'"
        class="status-icon"
        :aria-label="upload.isFolder ? $localize('ToggleFilesListVisibility') : undefined"
        @click="onStatusClick"
      >
        <i
          class="far"
          :class="{
            'fa-long-arrow-up text-primary': upload.statusName === UploadStatus.Uploading,
            'fa-check text-success': upload.statusName === UploadStatus.Uploaded,
            'fa-circle-notch fa-spin text-not-uploaded': upload.statusName === UploadStatus.Authorising,
            'fa-long-arrow-up text-not-uploaded': upload.statusName === UploadStatus.Pending ||
              upload.statusName === UploadStatus.Cancelled || upload.statusName === UploadStatus.Failed ||
              upload.statusName === UploadStatus.FailedAuthorisation,
          }"
          aria-hidden="true"
        />
      </component>
    </div>
    <div class="file-info-container row-data-container">
      <div class="row-data-container">
        <div class="file-info ellipsis">
          <span v-if="!upload.isFolder">{{ upload.fileName }}</span>
          <span v-else>
            {{ $localize('FolderNameWithFilesCountTemplate', {
              folderName: upload.fileName,
              filesCount: upload.getUploadsLength()
            }) }}
          </span>
        </div>
        <div class="file-actions">
          <VueButton
            v-if="!isReadOnly"
            :type="VueButtonTypes.default"
            class="btn-sm pull-right"
            :loading="isRemoving(upload)"
            :disabled="upload.isAuthorising"
            :aria-label="$localize('RemoveUploadButtonTitle', { uploadName: upload.fileName })"
            icon="far fa-trash-alt"
            @click="onRemoveClick"
          >
            <span
              class="hidden-xs"
              aria-hidden="true"
            >
              {{ $localize('Remove') }}
            </span>
          </VueButton>
        </div>
      </div>
      <div class="row-data-container">
        <ProgressBar
          :label="upload.fileName"
          :progress="upload.progress"
          :status="progressBarStatus"
        />
      </div>
      <div class="row-data-container file-sub-info-parent">
        <div class="file-sub-info">
          <span>{{ $localize(upload.statusName) }}</span>
          <span
            v-if="upload.userIsCurrentlyUploading"
          >({{ upload.progress }}%)</span>
        </div>
        <div
          v-if="itemCaption"
          class="file-sub-info text-center hidden-xs"
        >
          <span>{{ itemCaption }}</span>
        </div>
        <div class="file-sub-info">
          <div class="pull-right">
            <span>
              {{ fileSizeFormatter.getFormattedFileSize(upload.statusName === UploadStatus.Uploaded ? upload.size :
                upload.bytesUploaded) }}
            </span>
            /
            <span>
              {{ fileSizeFormatter.getFormattedFileSize(upload.size) }}
            </span>
          </div>
        </div>
      </div>
      <br>
    </div>
  </div>
  <div v-if="upload.isFolder && showFileList">
    <div
      v-for="(file, index) in upload.getFolderUploads()"
      :key="index"
      class="row-data-container"
    >
      <div class="status-icon-container" />
      <div class="row-data-container">
        <UploadListItem
          :is-read-only="isReadOnly"
          :upload="file"
          @remove="onChildRemoveClick"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import PartnerUploadModel from '@/Models/partnerUploadModel';
import { computed, inject, ref } from 'vue';
import VueButton from '@/VueComponents/SharedComponents/Buttons/VueButton.vue';
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';
import fileSizeFormatter from '@/Utils/fileSizeFormatter';
import ProgressBar from '@/VueComponents/SharedComponents/ProgressBar/ProgressBar.vue';
import { ProgressBarStatus } from '@/VueComponents/SharedComponents/ProgressBar/enums/ProgressBarStatus';
import { UploadStatus } from '@/Types/Enums/uploadStatus';
import BasePartnerUploadDataModel from '@/Models/basePartnerUploadDataModel';
import UploadTypeResolver from '@/Utils/uploadTypeResolver';

const props = defineProps<{
  upload: BasePartnerUploadDataModel,
  isReadOnly: boolean
}>();

const isRemoving = inject('isRemoving', upload => false);

const emit = defineEmits(['remove']);

const showFileList = ref(false);

const progressBarStatus = computed(() => {
  if (props.upload.statusName === UploadStatus.Uploaded) {
    return ProgressBarStatus.completed;
  }
  if (props.upload.statusName === UploadStatus.Uploading) {
    return ProgressBarStatus.processing;
  }

  return ProgressBarStatus.default;
});

function onStatusClick() {
  if (!props.upload.isFolder) {
    return;
  }

  toggleFileList();
}

function onRemoveClick() {
  emit('remove', props.upload);
}

function onChildRemoveClick(upload: PartnerUploadModel) {
  emit('remove', upload);
}

function toggleFileList() {
  showFileList.value = !showFileList.value;
}

const itemCaption = computed(() => {
  return UploadTypeResolver.localizeUploadType(props.upload.type, props.upload.isFolder);
});
</script>
