import BasePartnerUploadDataModel from '@/Models/basePartnerUploadDataModel';
import FileUploadModel from '@/VueComponents/FileUploadSelector/models/fileUploadModel';
import resourceHelper from '@/Utils/resourceHelper';
import FileModel from '@/VueComponents/FileUploadSelector/models/fileModel';
import FileSizeFormatter from '@/Utils/fileSizeFormatter';
import UploadTypeResolver from '@/Utils/uploadTypeResolver';
import FolderUploadModel from '@/VueComponents/FileUploadSelector/models/folderUploadModel';

export default function useUploadValidator(uploads: BasePartnerUploadDataModel[],
  maxFileSize: number,
  maxFolderSize: number,
  maximumUploads: number) {

  function validateFolders(folders: FolderUploadModel[]): ValidationResult {
    if (folders.some(f => isFolderDuplicated(f, uploads))) {
      return createValidationResult(false, resourceHelper.getString('UploadFolderAlreadyExists'));
    }

    if (folders.some(f => isFolderExceedsMaximumSize(f, maxFolderSize))) {
      const formattedFolderSize = FileSizeFormatter.getFormattedFileSize(maxFolderSize);
      return createValidationResult(false,
        resourceHelper.getString('MaxFolderSizeExcess', { maxFolderSize: formattedFolderSize }));
    }

    for (const folder of folders) {
      const files = folder.generateFileUploadModels();
      const validationResult = validateFiles(files);

      if (!validationResult.isValid) {
        return validationResult;
      }
    }

    return createValidationResult(true);
  }

  function validateFiles(files: FileUploadModel[]) : ValidationResult {

    if (files.length === 0) {
      return createValidationResult(false, resourceHelper.getString('UploadNoFilesFound'));
    }

    if (containsZeroSizeItems(files)) {
      return createValidationResult(false, resourceHelper.getString('EmptyFoldersAndFilesNotAllowed'));
    }

    if (anyFileExceedsAllowedSize(files, maxFileSize)) {
      const formattedFileSize = FileSizeFormatter.getFormattedFileSize(maxFileSize);
      return createValidationResult(false,
        resourceHelper.getString('MaxFileSizeExcess', { maxFileSize: formattedFileSize }));
    }

    return createValidationResult(true);
  }

  function isUploadLimitExceeded(files: (FileUploadModel)[] | FileList): boolean {
    const uploadsNumber = getTotalNumberOfUploads();

    return (uploadsNumber + files.length) > maximumUploads;
  }

  function getTotalNumberOfUploads() {
    return uploads.reduce((accumulator: number, currentValue: BasePartnerUploadDataModel) => {
      return accumulator + currentValue.getUploadsLength();
    }, 0);
  }

  function getFileDuplicates(files: FileUploadModel[]):
  { duplicates: FileUploadModel[], notDuplicates: FileUploadModel[] } {
    const duplicates = [];
    const notDuplicates = [];

    for (const file of files) {
      const isDuplicate = uploads.some(existedUpload => {
        if (existedUpload.type !== UploadTypeResolver.getFromFileUploadType(file.type)) {
          return false;
        }

        if (file.path) {
          return existedUpload.getUploadModels().some(u => u.fileRelativePath === file.path);
        }

        return existedUpload.fileName === file.name;
      });

      if (isDuplicate) {
        duplicates.push(file);
      } else {
        notDuplicates.push(file);
      }
    }

    return {
      duplicates,
      notDuplicates
    };
  }

  return { validateFiles, isUploadLimitExceeded, getTotalNumberOfUploads, getFileDuplicates, validateFolders };
}

type ValidationResult = { isValid: boolean, errorMessage?: string };

function createValidationResult(isValid: boolean, errorMessage?: string): ValidationResult {
  return { isValid, errorMessage };
}

function containsZeroSizeItems(files: FileModel[]) {
  for (let i = 0; i < files.length; i++) {
    if (!files[i].file.size) {
      return true;
    }
  }

  return false;
}

function anyFileExceedsAllowedSize(files: FileUploadModel[], maxFileSize): boolean {
  return files.some(fileModel => {
    return fileModel.file.size > maxFileSize;
  });
}

function isFolderDuplicated(folder: FolderUploadModel, uploads: BasePartnerUploadDataModel[]): boolean {
  return uploads.some(u => u.isFolder && u.fileName === folder.name);
}

function isFolderExceedsMaximumSize(folder: FolderUploadModel, maximumAllowedSize: number): boolean {
  const files = folder.files.map(f => f.file);
  let folderSize = 0;
  for (const file of files) {
    folderSize += file.size;
    if (folderSize > maximumAllowedSize) {
      return true;
    }
  }

  return false;
}
