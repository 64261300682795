import FileUploadModel from '@/VueComponents/FileUploadSelector/models/fileUploadModel';
import FolderUploadModel from '@/VueComponents/FileUploadSelector/models/folderUploadModel';
import uploadRepository from '@/Repositories/uploadRepository';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import UploadModel from '@/Models/uploadModel';
import BasePartnerUploadDataModel from '@/Models/basePartnerUploadDataModel';
import { getSingleFileUploads, getFolderUploads } from '@/VueComponents/Uploads/utils/uploadsUtils';

export default function useFileUploader(uploads: BasePartnerUploadDataModel[],
  requestId: string,
  requestType: UploadParentItemType) {

  // TODO: Remove when partner request upload file selector is reworked to use new generic FileSelector components
  function uploadFilesObsolete(fileList: FileList | File[], errorCallback: (e:any) => void) {
    const newUploads = uploadRepository.addUploadsAsFiles(fileList, requestId, requestType, errorCallback);

    populateWithNewUploadModels(uploads, newUploads);
  }

  function uploadFiles(files: FileUploadModel[], folders: FolderUploadModel[], errorCallback: (e:any) => void) {
    files = files || [];
    folders = folders || [];

    const fileList = [...files, ...folders.flatMap(f => f.generateFileUploadModels())];
    const newUploads = uploadRepository.addUploads(fileList, requestId, requestType, errorCallback);

    populateWithNewUploadModels(uploads, newUploads);
  }

  return { uploadFiles, uploadFilesObsolete };
}

function populateWithNewUploadModels(uploads: BasePartnerUploadDataModel[], newUploads: UploadModel[]) {
  const contextData = useContextDataStore();
  newUploads.forEach(u => {
    // Upload manager creates upload model before it's actually created on the backend so
    // some data can be missing. Put personaDisplayName manually for the correct displaying uploads in table.
    if (!u.personaDisplayName) {
      u.personaDisplayName = contextData.userData.displayName();
    }
  });

  const folderUploads = getFolderUploads(newUploads);
  const fileUploads = getSingleFileUploads(newUploads);

  if (folderUploads.length > 0) {
    uploads.unshift(...folderUploads);
  }
  if (fileUploads.length > 0) {
    uploads.unshift(...fileUploads);
  }
}