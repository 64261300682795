<template>
  <div class="form-container">
    <h2>{{ $localize('MyAccount') }}</h2>
    <p
      class="lead"
    >
      {{ $localize(AccountSpecificLabelsProvider.getAccountLabelKey('MyAccountLeadText')) }}
    </p>
    <div class="row">
      <div class="col-md-3">
        <VerticalTabMenu
          v-model="selectedTab"
          :items="[
            { text: $localize('UserDetails'), name: myAccountTabs.userDetailsTab },
            { text: $localize('ChangePassword'), name: myAccountTabs.changePasswordTab },
            { text: $localize(AccountSpecificLabelsProvider.getAccountLabelKey('AccountDetails')),
              name: myAccountTabs.accountDetailsTab },
          ]"
          :aria-label="$localize('MyAccount')"
        />
      </div>
      <div class="col-md-8 col-md-offset-1">
        <component
          :is="tabManager.resolveComponent(selectedTab)"
          :validation-rules="tabManager.resolveValidationRules(selectedTab)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AccountSpecificLabelsProvider from '@/Utils/accountSpecificLabelsProvider';
import VerticalTabMenu from '@/VueComponents/VerticalTabMenu/VerticalTabMenu.vue';
import UserDetailsTab from '@/VueComponents/AccountDetails/UserDetailsTab.vue';
import AccountDetailsTab from '@/VueComponents/AccountDetails/AccountDetailsTab.vue';
import ChangePasswordTab from '@/VueComponents/AccountDetails/ChangePasswordTab.vue';
import { ref } from 'vue';
import { MyAccountPageTabManager } from '@/VueComponents/PageComponents/MyAccount/myAccountPageTabManager';
import UserDetailsValidationRules from '@/VueComponents/AccountDetails/Models/userDetailsValidationRules';
import AccountDetailsValidationRules from '@/VueComponents/AccountDetails/Models/accountDetailsValidationRules';

const myAccountTabs = {
  userDetailsTab: 'UserDetailsTab',
  changePasswordTab: 'ChangePasswordTab',
  accountDetailsTab: 'AccountDetailsTab'
};

const props = defineProps<{
  userDetailsValidation: UserDetailsValidationRules,
  accountDetailsValidation: AccountDetailsValidationRules
}>();

const selectedTab = ref(myAccountTabs.userDetailsTab);

const tabManager = new MyAccountPageTabManager([
  { tabName: myAccountTabs.userDetailsTab, component: UserDetailsTab, validationRules: props.userDetailsValidation },
  { tabName: myAccountTabs.changePasswordTab, component: ChangePasswordTab, validationRules: undefined },
  { tabName: myAccountTabs.accountDetailsTab, component: AccountDetailsTab, validationRules: props.accountDetailsValidation }
]);
</script>
