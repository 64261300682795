<template>
  <div class="content-container">
    <div class="action-buttons-header">
      <router-link
        to="/cameras/new"
        class="btn btn-primary btn-outline"
        role="button"
      >
        {{ $localize('AddNewCamera') }}
      </router-link>
    </div>

    <h2>{{ $localize('CamerasTitle') }}</h2>
    <p class="lead">
      {{ $localize('CamerasLeadText') }}
    </p>

    <div class="row">
      <div class="col-md-6">
        <CameraList
          v-model:selected-page="paginationOptionsManager.currentPage"
          v-model:page-size="paginationOptionsManager.pageSize"
          :cameras="cameras"
          :loading="loading"
          :loading-error="displayLoadingError"

          :sorting-options="sortingOptionsManager.options"
          :sorting-default-value="sortingOptionsManager.sortField"
          :sorting-default-order="sortingOptionsManager.sortOrder"

          :pagination-total-items="paginationOptionsManager.total"
          :page-size-options="paginationOptionsManager.pageSizeOptions"

          @on-sort="sortingOptionsManager.onSort($event)"

          @page-changed="paginationOptionsManager.onPageChanged()"
          @page-size-changed="paginationOptionsManager.onPageSizeChanged()"
        />
      </div>

      <div class="col-md-6">
        <CamerasMap
          v-model:coordinate="coordinate"
          v-model:zoom="zoom"
          :cameras="mapCameras"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>

import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import MapCoordinate from '@/Types/mapCoordinate';
import SortOptionsManager from '@/VueComponents/SharedComponents/SortDropdown/utils/sortOptionsManager';
import resourceHelper from '@/Utils/resourceHelper';
import { LocalStorageKeyType } from '@/Types/Enums/localStorageKeyType';
import PaginationOptionsManager
  from '@/VueComponents/PaginatedTable/TablePageNavigation/utils/paginationOptionsManager';
import bindingHelper from '@/Bindings/helpers/bindingHelper';
import businessRepository from '@/Repositories/businessRepository';
import logger from '@/Utils/loggerVue';
import { reactive, Ref, ref } from 'vue';
import CameraList from '@/VueComponents/Cameras/CamerasList/CameraList.vue';
import CamerasMap from '@/VueComponents/Cameras/CamerasList/CamerasMap.vue';
import MapCameraPoint from '@/VueComponents/Cameras/Models/mapCameraPoint';
import PageResult from '@/Types/pageResult';
import { PreviousRoute } from '@/VueCore/routing/previousRoute';
import CameraSummary from '@/Types/cameraSummary';
import { ApplicationError } from '@/VueCore/services/clients/applicationError';

const contextData = useContextDataStore();


const props = defineProps<{
  allCameras: PageResult<CameraSummary>,
    previousRoute: PreviousRoute,
    usePreviousViewSettings: boolean
}>();

const previousCameraId = props.previousRoute?.id;
let initialCoordinate = contextData.portalSettings.defaultMapView.coordinate;
let initialZoom = contextData.portalSettings.defaultMapView.zoom;

if (contextData.userData?.business?.coordinate) {
  initialCoordinate = contextData.userData.business.coordinate;
  initialZoom = 17;
}

const coordinate: Ref<MapCoordinate> = ref(initialCoordinate);
const zoom: Ref<number> = ref(initialZoom);
const mapCameras: Ref<MapCameraPoint[]> = ref(mapCamerasToCameraPoints(props.allCameras.results));

const cameras = ref([]);

const loading = ref(false);
const displayLoadingError = ref(false);

const sortingOptionsManager = reactive(new SortOptionsManager(
    [
      { field: 'Name', label: resourceHelper.getString('CameraName') },
      { field: 'AddedTimestamp', label: resourceHelper.getString('DateCreated') }
    ],
    LocalStorageKeyType.CamerasSorting,
    () => {
      paginationOptionsManager.currentPage = 1;
      updateResults();
    }
));

const paginationOptionsManager = reactive(new PaginationOptionsManager(
    contextData.portalSettings.paginationPageSizeOptions,
    LocalStorageKeyType.CamerasPage,
    LocalStorageKeyType.PaginationCamerasPageSize,
    () => {
      updateResults();
    },
    () => {
      updateResults();
    },
    { page: props.usePreviousViewSettings, pageSize: true }
));

updateResults();


function mapCamerasToCameraPoints(cameras: CameraSummary[]): MapCameraPoint[] {
  const result: MapCameraPoint[] = [];

  for (const camera of cameras) {
    result.push({
      id: camera.cameraId,
      name: camera.cameraName,
      addedDateTime: bindingHelper.getMomentDate(camera.addedDateTime).format('llll'),
      coordinate: camera.coordinate
    });
  }

  return result;
}

function updateResults() {

  const sortField = sortingOptionsManager.sortField;
  const sortOrder = sortingOptionsManager.sortOrder;
  const page = paginationOptionsManager.currentPage;
  const pageSize = paginationOptionsManager.pageSize;

  loading.value = true;
  displayLoadingError.value = false;

  businessRepository.getCameras(sortField, sortOrder, page, previousCameraId, pageSize)
      .then(function (pageResult) {

        // If the current page does not contain any cameras then load the previous one.
        // It happens when the user deletes the last camera on a page.
        if (pageResult.results.length === 0 && pageResult.total > 0) {
          const currentPage = paginationOptionsManager.currentPage;
          paginationOptionsManager.currentPage = currentPage > 1 ? currentPage - 1 : 1;
          updateResults();
          return;
        }

        cameras.value = pageResult.results;
        paginationOptionsManager.total = pageResult.total;
      })
      .catch((error:ApplicationError) => {

        displayLoadingError.value = true;

        if (!error.isLogged) {
          logger.error('UnexpectedErrorWhileRetrievingCameras', null, error);
        }
      })
      .finally(() => {
        loading.value = false;
      });
}
</script>