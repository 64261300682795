import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import localStorageHelper from '@/Utils/localStorageHelper';
import { LocalStorageKeyType } from '@/Types/Enums/localStorageKeyType';
import NavigationStorageKeys from '@/VueComponents/PaginatedTable/TablePageNavigation/types/navigationStorageKeys';

const defaultPageSize = 10;

export default class PaginationOptionsManager {
  private _currentPage: number;
  private _pageSize: number;

  total: number;
  pageSizeOptions: number[];
  useSavedOptions: { page: boolean, pageSize: boolean };

  get currentPage() {
    return this._currentPage;
  }
  set currentPage(value) {
    if (this.storageKeys.savedPageKey) {
      localStorageHelper.setSessionValue(this.storageKeys.savedPageKey, value.toString());
    }
    this._currentPage = value;
  }

  get pageSize() {
    return this._pageSize;
  }
  set pageSize(value) {
    if (this.storageKeys.savedPageSizeKey) {
      localStorageHelper.setSessionValue(this.storageKeys.savedPageSizeKey, value.toString());
    }
    this._pageSize = value;
  }

  private readonly pageChangedCallback: ((page: number | undefined) => void) | undefined;
  private readonly pageSizeChangedCallback: (() => void) | undefined;

  private storageKeys: NavigationStorageKeys;

  constructor(
    pageSizeOptions: number[] | undefined,
    pageStorageKey: LocalStorageKeyType | undefined,
    pageSizeStorageKey: LocalStorageKeyType | undefined,
    onPageChangedCallback: ((page: number | undefined) => void) | undefined,
    onPageSizeChangedCallback: (() => void) | undefined,
    useSavedOptions: { page: boolean, pageSize: boolean } = { page: false, pageSize: false }) {

    this.storageKeys = {
      savedPageKey: pageStorageKey,
      savedPageSizeKey: pageSizeStorageKey
    };
    this.useSavedOptions = useSavedOptions;

    const contextData = useContextDataStore();
    this.pageSizeOptions = pageSizeOptions ? pageSizeOptions : contextData.portalSettings.paginationPageSizeOptions;
    this.pageSize = this.getDefaultPageSize();
    this.currentPage = this.getDefaultCurrentPage();
    this.total = 0;

    this.pageChangedCallback = onPageChangedCallback;
    this.pageSizeChangedCallback = onPageSizeChangedCallback;
  }

  private getDefaultPageSize() {
    if (!this.useSavedOptions.pageSize) {
      return defaultPageSize;
    }

    const savedPageSize = this.storageKeys.savedPageSizeKey ? localStorageHelper.getSessionValue(this.storageKeys.savedPageSizeKey) : undefined;
    if (!savedPageSize) {
      return defaultPageSize;
    }
    return Number(savedPageSize);
  }

  private getDefaultCurrentPage() {
    if (!this.useSavedOptions.page) {
      return 1;
    }

    const savedPage = this.storageKeys.savedPageKey ? localStorageHelper.getSessionValue(this.storageKeys.savedPageKey) : undefined;
    if (!savedPage) {
      return 1;
    }
    return Number(savedPage);
  }

  public onPageChanged(page?: number | undefined) {
    if (page) {
      this.currentPage = page;
    }

    if (this.pageChangedCallback) {
      this.pageChangedCallback(page);
    }
  }

  public onPageSizeChanged(pageSize?: number | undefined) {
    if (pageSize) {
      this.pageSize = pageSize;
    }

    if (this.pageSizeChangedCallback) {
      this.pageSizeChangedCallback();
    }
  }
}