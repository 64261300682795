<template>
  <InputFieldForm
    :id="id"
    :label="label"
    :class="formClass"
    :required="isRequired"
    :validation-message="errors[0]"
    :label-hidded="labelHidden"
    :icon="icon"
  >
    <Textarea
      v-if="!readonly"
      :id="id"
      ref="textAreaElement"
      v-model="model"
      class="form-control"
      :aria-invalid="errors[0] ? errors[0] : undefined"
      :aria-describedby="validationSpanId"
      v-bind="$attrs"
    />
    <span
      v-else
      class="form-control form-control-readonly"
    >
      {{ model }}
    </span>
  </InputFieldForm>
</template>

<script lang="ts" setup>
import ValidationRules from '@/Types/Validation/validationRules';
import Textarea from 'primevue/textarea';
import { computed, ref } from 'vue';
import InputFieldElementIdGenerator
  from '@/VueComponents/SharedComponents/InputFields/utils/inputFieldElementIdGenerator';
import { useField } from 'vee-validate';
import InputFieldForm from '@/VueComponents/SharedComponents/InputFields/InputFieldForm.vue';

defineOptions({
  inheritAttrs: false
});

const props = withDefaults(defineProps<{
  id: string,
  label?: string,
  formClass?: string,
  labelHidden?: boolean,
  readonly?: boolean,
  icon?: string,
  validationRules?: ValidationRules
}>(), { label: undefined, validationMessage: undefined, validationRules: undefined,
  icon: undefined, formClass: undefined });

if (!props.id) {
  console.error(`Unique 'id' should be provided.`);
}

const isRequired = computed(() => {
  return !!props.validationRules?.required;
});

const textAreaElement = ref<HTMLElement>();

const validationSpanId = InputFieldElementIdGenerator.getValidationMessageId(props.id);

const model = defineModel<string>();
const { errors, validate } = useField(props.id, props.validationRules, { syncVModel: true });

async function isValid() {
  const result = await validate();
  return result.valid;
}

function focus() {
  textAreaElement.value?.focus();
}

defineExpose({
  isValid,
  focus
});
</script>

<style lang="scss" scoped>
@import "sass/site/_colours.scss";

:deep(.p-inputtextarea) {
  color: $input-text-color;
}

.form-control-readonly {
  color: $input-readonly-text-color;
}
</style>
