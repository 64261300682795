<template>
  <ModalDialog
    :title="title"
    @close="emit('cancel')"
  >
    <template #default>
      <div>
        {{ paragraph1 }}
      </div>
      <br>
      <div>
        {{ paragraph2 }}
      </div>
    </template>
    <template #footer>
      <button
        class="btn btn-default"
        @click="emit('cancel')"
      >
        {{ $localize('Cancel') }}
      </button>
      <button
        class="btn btn-primary"
        @click="emit('continue')"
      >
        {{ $localize('Continue') }}
      </button>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
import { computed } from 'vue';
import resourceHelper from '@/Utils/resourceHelper';

const props = defineProps<{ filesCount: number }>();

const emit = defineEmits(['cancel', 'continue']);

const title = computed(() => {
  if (props.filesCount > 1) {
    return resourceHelper.getString('ZipFilesWarningTitle', { filesCount: props.filesCount });
  }

  return resourceHelper.getString('ZipFileWarningTitle');
});

const paragraph1 = computed(() => {
  if (props.filesCount > 1) {
    return resourceHelper.getString('ZipFilesWarningPara1', { filesCount: props.filesCount });
  }

  return resourceHelper.getString('ZipFileWarningPara1');
});

const paragraph2 = computed(() => {
  if (props.filesCount > 1) {
    return resourceHelper.getString('ZipFilesWarningPara2', { filesCount: props.filesCount });
  }

  return resourceHelper.getString('ZipFileWarningPara2');
});
</script>
