<template>
  <div
    class="progress"
    role="progressbar"
    aria-valuemin="0"
    aria-valuemax="100"
    :aria-valuenow="progress"
    :aria-label="label"
  >
    <div
      class="progress-bar current-progress"
      :class="{
        'bg-success': status === ProgressBarStatus.completed,
        'bg-primary': status === ProgressBarStatus.processing
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { ProgressBarStatus } from '@/VueComponents/SharedComponents/ProgressBar/enums/ProgressBarStatus';

const props = defineProps<{
  label: string,
  progress: number,
  status: ProgressBarStatus
}>();

const progressBarWidth = computed(() => {
  if (props.status === ProgressBarStatus.completed) {
    return '100%';
  }

  return props.progress + '%';
});
</script>
<style lang="scss">
  .current-progress {
    width: v-bind(progressBarWidth) !important;
  }
</style>
