<template>
  <GenericFileDropZone
    @add-files="onFileDropped"
    @add-folders="onFolderDropped"
  >
    <div class="drop-zone-title-container">
      <div class="drop-zone-title hidden-sm hidden-lg hidden-md">
        <span>{{ title ?? defaultStrings.title }}</span>
      </div>
      <div class="drop-zone-image">
        <img
          :src="imageLink"
          class="drop-zone-icon"
          alt=""
        >
        <div class="hidden-xs">
          <span class="drop-zone-title">{{ title ?? defaultStrings.title }}</span>
          <p class="drop-zone-prompt">
            {{ description ?? defaultStrings.description }}
          </p>
        </div>
      </div>
    </div>

    <div class="drop-zone-button-container">
      <label
        :for="id + 'FileInput'"
        class="mr-1"
      >
        <span
          class="btn"
          :class="{ 'btn-primary': !defaultButtonsStyle && folderSelectionAllowed,
                    'btn-default': defaultButtonsStyle || !folderSelectionAllowed }"
          role="button"
          tabindex="0"
          @keydown="triggerClickOnEnter($event, fileInput)"
        >{{ selectFilesButtonLabel ?? defaultStrings.selectFilesButtonLabel }}</span>
        <input
          :id="id + 'FileInput'"
          ref="fileInput"
          :accept="accept"
          type="file"
          name="files[]"
          multiple="multiple"
          @change="onFileInputChange"
        >
      </label>
      <label
        v-if="folderSelectionAllowed"
        :for="id + 'FolderInput'"
        class="ml-1"
      >
        <span
          class="btn btn-default"
          role="button"
          tabindex="0"
          @keydown="triggerClickOnEnter($event, folderInput)"
        >{{ selectFolderButtonLabel ?? defaultStrings.selectFolderButtonLabel }}</span>
        <input
          :id="id + 'FolderInput'"
          ref="folderInput"
          type="file"
          name="files[]"
          directory="directory"
          webkitdirectory="webkitdirectory"
          @change="onFolderInputChange"
        >
      </label>
    </div>
  </GenericFileDropZone>
</template>

<script lang="ts" setup>
import GenericFileDropZone from '@/VueComponents/FileUploadSelector/GenericFileDropZone.vue';
import FileSelectorMapper from '@/VueComponents/FileUploadSelector/utils/fileSelectorMapper';
import resourceHelper from '@/Utils/resourceHelper';
import { computed, ref } from 'vue';
import FileModel from '@/VueComponents/FileUploadSelector/models/fileModel';
import FolderModel from '@/VueComponents/FileUploadSelector/models/folderModel';

const props = withDefaults(defineProps<{
  id?: string,
  title?: string,
  description?: string,
  selectFilesButtonLabel?: string,
  selectFolderButtonLabel?: string,
  imageLink?: string,
  accept?: string,
  disableFolderSelection?: boolean,
  defaultButtonsStyle?: boolean
}>(), {
  id: undefined,
  title: undefined,
  description: undefined,
  selectFilesButtonLabel: undefined,
  selectFolderButtonLabel: undefined,
  imageLink: '/Content/images/individualFiles.svg',
  accept: undefined,
  disableFolderSelection: false,
  defaultButtonsStyle: false
});

const emit = defineEmits<{
  addFiles: [files: FileModel[]],
  addFolders: [files: FolderModel[]]
}>();

const fileInput = ref<HTMLElement>();
const folderInput = ref<HTMLElement>();
const isDirectorySupported = directorySupported();

const folderSelectionAllowed = computed(() => {
  return !props.disableFolderSelection && isDirectorySupported;
});

const defaultStrings = {
  title: resourceHelper.getString('FilesAndFoldersTitle'),
  description: resourceHelper.getString('FilesUploadPrompt'),
  selectFilesButtonLabel: resourceHelper.getString('SelectFiles'),
  selectFolderButtonLabel: resourceHelper.getString('SelectFolder')
};

function triggerClickOnEnter(event: KeyboardEvent, targetElement: HTMLElement) {
  const key = event.key;
  if (key === 'Enter' || key === ' ') {
    event.preventDefault(); // Pressing the "space" key, if propagated will cause some browsers to scroll down
    targetElement.click();
  }
}

function onFileInputChange(event: Event) {
  const inputElement = event.target as HTMLInputElement;

  // Allow for browsers that handle the value reset / empty string as a change
  if (inputElement.value === '') {
    return;
  }

  const files = Array.from(inputElement.files).map(f => FileSelectorMapper.mapFileToFileModel(f));
  inputElement.value = '';

  emit('addFiles', files);
}

function onFolderInputChange(event: Event) {
  const inputElement = event.target as HTMLInputElement;

  const folder = FileSelectorMapper.mapFilesToFolderModel(Array.from(inputElement.files));
  inputElement.value = '';

  emit('addFolders', [folder]);
}

function onFileDropped(files: FileModel[]) {
  emit('addFiles', files);
}

function onFolderDropped(folders: FolderModel[]) {
  if (!folderSelectionAllowed.value) {
    return;
  }
  emit('addFolders', folders);
}

function directorySupported() {
  const tmpInput = document.createElement('input');
  return 'webkitdirectory' in tmpInput ||
    'mozdirectory' in tmpInput ||
    'odirectory' in tmpInput ||
    'msdirectory' in tmpInput ||
    'directory' in tmpInput;
}
</script>
