import { inject, reactive } from 'vue';
import logger from '@/Utils/logger';
import BasePartnerUploadDataModel from '@/Models/basePartnerUploadDataModel';

export default function useUploadRemoving(uploads: BasePartnerUploadDataModel[] | undefined) {
  const removingUploads = reactive<Set<string>>(new Set<string>());

  const removeUploadsAsync: ((uploads: BasePartnerUploadDataModel[]) => Promise<void>) | undefined =
    inject('removeUploadsAsync', undefined);

  function isRemoving(upload: BasePartnerUploadDataModel): boolean {
    return upload.getIds().some(id => removingUploads.has(id));
  }

  function isRemovingAny() {
    return removingUploads.size > 0;
  }

  async function remove(uploadsToDelete: BasePartnerUploadDataModel[]): Promise<void> {
    const ids = uploadsToDelete.flatMap(u => u.getIds());
    ids.forEach(id => removingUploads.add(id));

    try {
      if (removeUploadsAsync) {
        await removeUploadsAsync(uploadsToDelete);
      }
      logger.success('UploadsSuccessfullyRemoved');
      for (const id of ids) {
        removeIndividualItemFromTheUploadsArrayById(id, uploads);
      }
    } catch {
      logger.error('FailedToRemoveUploads');
    } finally {
      ids.forEach(id => removingUploads.delete(id));
    }
  }

  return { isRemoving, isRemovingAny, remove };
}

export function removeIndividualItemFromTheUploadsArrayById(id: string,
  uploads: BasePartnerUploadDataModel[] | undefined): boolean {
  if (!uploads?.length) {
    return false;
  }

  for (let i = 0; i < uploads.length; i++) {
    const upload = uploads[i];

    if (!upload.isFolder && upload.uploadId === id) {
      uploads.splice(i, 1);
      return true;
    }

    if (upload.isFolder) {
      // Try to find upload files in the folder
      const isFoundAndDeleted = upload.tryRemoveFromTheFolderById(id);

      // If the folder has no more files remove it from the list
      if (upload.getUploadsLength() === 0) {
        uploads.splice(i, 1);
      }

      if (isFoundAndDeleted) {
        return true;
      }
    }
  }
}
