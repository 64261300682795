<template>
  <ModalDialog
    :title="$localize('ConfirmRejectionModalTitle')"
    :is-danger="true"
    @close="emit('close')"
  >
    <template #default>
      <TextAreaField
        id="rejectionReason"
        ref="rejectionReasonField"
        v-model="reason"
        :label="$localize('ConfirmRejectionReasonPrompt')"
        rows="3"
        :validation-rules="{
          required: { message: $localize('ReasonForRejectionRequired') },
          max: { message: $localize('ReasonForRejectionMaxLength'), length: 1000 }}"
      />
    </template>
    <template #footer>
      <VueButton
        :type="VueButtonTypes.default"
        @click="emit('close')"
      >
        {{ $localize('Cancel') }}
      </VueButton>
      <VueButton
        :type="VueButtonTypes.danger"
        aria-describedby="rejectionReasonValidationMessage"
        @click="onContinue"
      >
        {{ $localize('Continue') }}
      </VueButton>
    </template>
  </ModalDialog>
</template>

<script lang="ts" setup>
import ModalDialog from '@/VueComponents/Modals/ModalDialog.vue';
import VueButton from '@/VueComponents/SharedComponents/Buttons/VueButton.vue';
import { VueButtonTypes } from '@/VueComponents/SharedComponents/Buttons/Enums/VueButtonTypes';
import TextAreaField from '@/VueComponents/SharedComponents/InputFields/TextAreaField.vue';
import { onMounted, ref } from 'vue';
import { useForm } from 'vee-validate';
const emit = defineEmits(['close', 'continue']);

const reason = ref('');
const rejectionReasonField = ref();

const form = useForm({
  initialValues: {
    rejectionReason: reason.value
  }
});

onMounted(() => {
  rejectionReasonField.value.focus();
});

async function onContinue() {
  const result = await form.validate();

  if (result.valid) {
    emit('continue', reason.value);
  }
}
</script>

<style scoped>

</style>