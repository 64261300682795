import UpdateUserDetailsModel from '@/VueComponents/PageComponents/Users/Models/updateUserDetailsModel';
import UserGroupSelectionModel from '@/VueComponents/PageComponents/Users/Models/userGroupSelectionModel';

export default class CreateUserDetailsModel extends UpdateUserDetailsModel {
  constructor(
    public emailAddress: string,
    public givenName: string,
    public familyName: string,
    public phoneNumber: string,
    public isBusinessAdminUser: boolean,
    public isBusinessPrimaryContact: boolean,
    public businessUserGroupSelections: UserGroupSelectionModel[]
  ) {
    super(givenName, familyName, phoneNumber, isBusinessAdminUser, isBusinessPrimaryContact, businessUserGroupSelections);
  }
}