<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';
import ConfigurableField from '@/Types/configurableField';
import moment from 'moment';
import resourceHelper from '@/Utils/resourceHelper';
import { CalendarBlurEvent } from 'primevue/calendar';
import Calendar from 'primevue/calendar';
import ValidationResultManager from '@/VueComponents/ConfigurableFields/validationResultManager';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import constants from '@/constants';
import { Field } from 'vee-validate';

const contextData = useContextDataStore();
const props = defineProps<{
  configurableField: ConfigurableField,
  fieldValue: string,
  disabled: boolean,
  showMultiplePlaceholder: boolean
}>();
const emit = defineEmits<{(e: 'updated', fieldId: string, value: string, isValid: boolean, invalidReason: string): void}>();

const dateTimeFormat = contextData.calendarDateTimeFormat;
const internalCalendarDate = ref(null);
const internalFieldValue = ref('');
if (props.fieldValue) {
  const initialDateTime = moment(props.fieldValue, [dateTimeFormat, constants.dateTimeFormats.serverDateTimeFormat], true);
  if (initialDateTime.isValid()) {
    setInternalValue(initialDateTime);
  }
}

const date = ref(null);
const calendar = ref(null);

watch(() => props.fieldValue, newValue => {
  if (!newValue) {
    setInternalValue(null);
    return;
  }

  const externalMomentDate = moment(newValue, [dateTimeFormat, constants.dateTimeFormats.serverDateTimeFormat], true);
  if (externalMomentDate.isValid()) {
    const internalMomentDate = moment(internalFieldValue.value, dateTimeFormat, true);
    if (!internalMomentDate.isValid() || externalMomentDate.toDate().getTime() !== internalMomentDate.toDate().getTime()) {
      setInternalValue(externalMomentDate);
    }
  } else {
    // it's not expected to receive invalid data => set date to empty
    setInternalValue(null);
  }
});

// do not show errors if multiple placeholder is shown
// means field serves multiple items with different value
// therefore shown empty value for the field but actually it's not empty
watch(() => props.showMultiplePlaceholder, newValue => {
  if (newValue) {
    date?.value?.reset();
  } else {
    validate();
  }
});

async function validate(): Promise<Object> {
  return await date?.value?.validate();
}

async function handleDateChange(value: Date) {
  const validationResult = await validate();
  const formattedDateTime = value === null ? '' : moment(value).format(dateTimeFormat);
  const { isValid, error } = ValidationResultManager.parseValidationResult(validationResult);
  internalFieldValue.value = formattedDateTime;
  emit('updated', props.configurableField.id, formattedDateTime, isValid, error);
}

function handleInputChange(event: InputEvent) {
  validate();
}

function handleBlurEvent(event: CalendarBlurEvent) {
  // back correct date on blur
  if (internalCalendarDate.value) {
    internalFieldValue.value = moment(internalCalendarDate.value).format(dateTimeFormat);
  }
}

function getPlaceholder() {
  if (props.showMultiplePlaceholder) {
    return resourceHelper.getString('MultipleValue');
  }

  if (props.disabled) {
    return '';
  }

  return resourceHelper.getString('SelectOrEnterDate');
}

function setInternalValue(momentDate: moment.Moment | null) {
  if (momentDate) {
    internalFieldValue.value = momentDate.format(dateTimeFormat);
    internalCalendarDate.value = momentDate.toDate();
  } else {
    internalFieldValue.value = '';
    internalCalendarDate.value = null;
  }
}

onMounted(async () => {
  await validate();
});
</script>

<template>
  <Field
    ref="date"
    v-slot="{ errors, field }"
    v-model="internalFieldValue"
    :name="'date-' + configurableField.name"
    :rules="{
      required: configurableField.isMandatory,
      dateFormat: {
        format: dateTimeFormat
      }
    }"
    :validate-on-change="false"
    :validate-on-input="true"
    :validate-on-blur="true"
    :validate-on-model-update="true"
  >
    <div
      :class="{ 'has-error': errors.length > 0 && !showMultiplePlaceholder }"
    >
      <label
        :for="'date-' + configurableField.name"
        :class="{ 'required': configurableField.isMandatory }"
      >
        {{ configurableField.displayName }}:
      </label>
      <div>
        <Calendar
          ref="calendar"
          v-model="internalCalendarDate"
          v-bind="field"
          :class="{ 'has-error': errors.length > 0 && !showMultiplePlaceholder }"
          :disabled="disabled"
          show-time
          hour-format="24"
          :show-seconds="true"
          :manual-input="true"
          :show-icon="true"
          icon-display="input"
          :placeholder="getPlaceholder()"
          :input-class="'form-control'"
          @update:model-value="handleDateChange"
          @input="handleInputChange"
          @blur="handleBlurEvent"
        />
      </div>
      <div class="help-block">
        <small>{{ showMultiplePlaceholder ? '' : errors[0] }}</small>
      </div>
    </div>
  </Field>
</template>