<template>
  <div class="row">
    <div class="col-lg-10">
      <h3 class="mt-1">
        {{ $localize('ChangePassword') }}
      </h3>
      <p>{{ $localize('ChangePasswordInstructions') }}</p>
    </div>
  </div>
</template>
