<script setup lang="ts">
import { onMounted } from 'vue';
import { useContextDataStore } from '@/VueCore/stores/contextDataStore';
import redirectHelper from '@/Utils/redirectHelper';

const contextData = useContextDataStore();
onMounted(async () => {
  // remove binding failure fallback loader/error divs
  const fallbackScreensElement = document.getElementById('fallbackScreens');
  fallbackScreensElement?.parentNode.removeChild(fallbackScreensElement);
});

const logout = () => {
  redirectHelper.logoutRedirect();
};
</script>
<template>
  <div class="col-md-10 col-md-offset-1">
    <div v-if="contextData.portalStartupError === 'AuthorisationError'">
      <h2>Authentication error</h2>
      <p class="lead">
        An error occurred while checking your authentication details. Please try again later.
      </p>
    </div>
    <div v-if="contextData.portalStartupError === 'BusinessDisabled'">
      <h2>Organization account disabled</h2>
      <p class="lead">
        The organization account you are associated with is disabled. Please try again later.
      </p>
    </div>
    <div v-if="contextData.portalStartupError === 'UnknownErrorGettingUserData'">
      <h2>An error occurred</h2>
      <p class="lead">
        Access to the Portal is not available at this time. Please try again later.
      </p>
    </div>
    <div v-if="contextData.portalStartupError === 'UnknownUser'">
      <h2>Unknown user</h2>
      <p class="lead">
        Your user account is not registered with the Portal therefore access is denied.
      </p>
      <p>
        Access to the Portal is via an invite only.
        If you have been sent a registration email, please follow the link in this email to register.
      </p>
    </div>
    <div v-if="contextData.portalStartupError === 'UnknownError'">
      <h2>There was a problem loading the site</h2>
      <p class="lead">
        Please try again later
      </p>
    </div>
    <p v-if="contextData.portalStartupError !== 'UnknownError'">
      <button
        class="btn btn-primary mt-3"
        @click="logout"
      >
        Logout
      </button>
    </p>
  </div>
</template>