<template>
  <nav>
    <ul
      data-bind="foreach: items"
      class="nav nav-pills nav-stacked left-menu"
    >
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="{ 'active': item.name === model }"
      >
        <a
          href="#"
          :class="{ 'child': item.isChild }"
          :data-name="item.name"
          @click.prevent="onMenuItemClicked(item.name)"
        >
          <span>{{ item.text }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import VerticalTabMenuItem from '@/VueComponents/VerticalTabMenu/Models/verticalTabMenuItem';

const props = defineProps<{
  items: VerticalTabMenuItem[]
}>();

const model = defineModel({ type: String });

if (!model.value) {
  model.value = props.items[0]?.name;
}

function onMenuItemClicked(itemName: string) {
  model.value = itemName;
}
</script>
