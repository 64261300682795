<template>
  <slot />
</template>
<script lang="ts" setup>
import mapStylingHelper from '@/Utils/mapStylingHelper';
import MapCoordinate from '@/Types/mapCoordinate';
import { inject, onUnmounted, Ref, watch } from 'vue';
import { MapMarkerStyle } from '@/VueComponents/SharedComponents/Map/Models/mapMarkerStyle';
import { MapInjectionKeys } from '@/VueComponents/SharedComponents/Map/Enums/mapInjectionKeys';

const coordinate = defineModel<MapCoordinate>('coordinate', { required: true });
const props = defineProps<{
  markerStyle: MapMarkerStyle
}>();
const emit = defineEmits(['markerMove']);

const map: Ref<any> | undefined = inject(MapInjectionKeys.map);
if (!map) {
  throw new Error('Markers should be used within a VueMap component');
}

if (!props.markerStyle) {
  throw new Error('MarkerStyle is not provided');
}

const unmountCallbacks: (() => void)[] = [];

onUnmounted(() => {
  for (const callback of unmountCallbacks) {
    callback();
  }
});

import(/* webpackChunkName: "open-layers" */ '@/Utils/openLayersDynamicModule') // Dynamically import large OpenLayers dependencies
    .then(olModule => {
      initMarker(olModule, map.value);
    });

function initMarker(olModule: any, map: any) {
  const Collection = olModule.Collection;
  const Feature = olModule.Feature;
  const Point = olModule.Point;
  const VectorLayer = olModule.VectorLayer;
  const VectorSource = olModule.VectorSource;
  const toLonLat = olModule.toLonLat;
  const fromLonLat = olModule.fromLonLat;
  const Translate = olModule.Translate;

  const vectorLayer = new VectorLayer({
    source: new VectorSource()
  });

  let lastMapLngLat = [0, 0];

  map.addLayer(vectorLayer);

  const style = mapStylingHelper.createDraggableMarkerStyle(props.markerStyle);

  const markerPoint = new Point(fromLonLat([coordinate.value.longitude, coordinate.value.latitude]));
  const markerFeature = new Feature(markerPoint);

  markerFeature.setStyle(style);

  vectorLayer.getSource().addFeature(markerFeature);

  const collection = new Collection([markerFeature]);
  const drag = new Translate({
    features: collection
  });
  map.addInteraction(drag);

  const coordinateWatcher = watch(coordinate, newValue => {

    const observableLonLat = fromLonLat([newValue.longitude, newValue.latitude]);

    const mapLonLat = markerFeature.getGeometry().getCoordinates();

    const deltaX = observableLonLat[0] - mapLonLat[0];
    const deltaY = observableLonLat[1] - mapLonLat[1];

    markerFeature.getGeometry().translate(deltaX, deltaY);
  });

  markerFeature.on('change', onMarkerChange, markerFeature);

  unmountCallbacks.push(() => {
    coordinateWatcher();

    // Remove map feature change event listener
    markerFeature.un('change', onMarkerChange);
  });

  function onMarkerChange(event) {

    const currentLonLat = toLonLat(event.target.getGeometry().getCoordinates());

    // If the marker hasn't moved then don't update model otherwise subscription will
    // execute which will update the map and then fire this event again causing an infinite loop.
    if (currentLonLat[0] === lastMapLngLat[0] && currentLonLat[1] === lastMapLngLat[1]) {
      return;
    }

    lastMapLngLat = currentLonLat;

    coordinate.value = { latitude: currentLonLat[1], longitude: currentLonLat[0] };

    emit('markerMove', { latitude: currentLonLat[1], longitude: currentLonLat[0] });
  }
}
</script>