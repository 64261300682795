import { reactive, ref } from 'vue';
import uploadRepository from '@/Repositories/uploadRepository';
import logger from '@/Utils/logger';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';
import BasePartnerUploadDataModel from '@/Models/basePartnerUploadDataModel';
import { getSingleFileUploads, getFolderUploads } from '@/VueComponents/Uploads/utils/uploadsUtils';

export default function useUploadLoader(requestId: string,
  requestType: UploadParentItemType,
  uploadsArray?: BasePartnerUploadDataModel[]) {
  const uploads = reactive<BasePartnerUploadDataModel[]>(uploadsArray ?? []);
  const loading = ref<boolean>(false);
  const loadingFailed = ref<boolean>(false);

  function load() {
    loading.value = true;
    loadingFailed.value = false;

    // TODO: getUploadsByResponseId and getUploadsByRequestId use knockout and should be retired and reworked
    // once upload manager is migrated.
    const loadUploadsFunction = requestType === UploadParentItemType.selfResponse ?
      uploadRepository.getUploadsByResponseId : uploadRepository.getUploadsByRequestId;

    loadUploadsFunction(requestId).then(data => {
      uploads.length = 0;

      const folders = getFolderUploads(data);
      const files = getSingleFileUploads(data);

      if (folders.length > 0) {
        uploads.push(...folders);
      }
      if (files.length > 0) {
        uploads.push(...files);
      }
    }).catch(e => {
      loadingFailed.value = true;
      logger.error('FailedToRetrieveUploads');
    }).finally(() => {
      loading.value = false;
    });

    return { load, loading, loadingFailed, uploads };
  }

  return { load, uploads, loading, loadingFailed };
}
