<template>
  <div class="row dual-listbox-filter-title">
    <div class="col-md-4 col-xs-12" />
    <div class="col-md-4 col-xs-12 text-right">
      <label
        class="control-label"
        for="filterFieldId"
      >{{ $localize('GroupUserDuallistBoxFilterUserLists') }}</label>
    </div>
    <div class="col-md-4 col-xs-12">
      <input
        id="filterFieldId"
        v-model="state.filterCriteria"
        class="form-control filter-input"
        :disabled="availableItems.length == 0 && assignedItems.length == 0"
      >
    </div>
  </div>

  <PickList
    v-model="items"
    breakpoint="767px"
    data-key="id"
    :show-source-controls="false"
    :show-target-controls="false"
    @update:model-value="onItemsUpdate"
  >
    <template #item="{ item }">
      <span :title="item.name">{{ item.name }}</span>
      <div
        class="icons-container pull-right"
      >
        <i
          v-if="item.iconDetails"
          v-tooltip.top="{
            class: 'custom-tooltip-container',
            value: $localize(item.iconDetails.titleKey),
            pt: {
              text: 'custom-tooltip-text',
              arrow: 'custom-tooltip-arrow'
            },
            showDelay: 600
          }"
          :class="['fas', item.iconDetails.iconClass]"
          aria-hidden="true"
        />
        <i
          v-if="isItemInAssignedItemsList(item)"
          v-tooltip.top="{
            class: 'custom-tooltip-container',
            value: $localize('GroupPrimaryContactCheckboxTooltipKey'),
            pt: {
              text: 'custom-tooltip-text',
              arrow: 'custom-tooltip-arrow'
            },
            showDelay: 600
          }"
          class="far fa-envelope fa-fw"
          aria-hidden="true"
        />
        <input
          v-if="isItemInAssignedItemsList(item)"
          type="checkbox"
          class="item-checkbox"
          :checked="item.isChecked"
          @change="onPrimaryContactChange($event, item)"
          @keydown="onKeyDown($event, item)"
        >
      </div>
    </template>
  </PickList>
</template>

<script setup lang="ts">
import { reactive, computed, watch } from 'vue';
import PickList from 'primevue/picklist';
import DualListBoxItem from '@/VueComponents/DualListBox/types/dualListBoxItem';

const props = defineProps<{ selectedItemId: string, loading: boolean }>();
const availableItems = defineModel<DualListBoxItem[]>('availableItems', { required: true });
const assignedItems = defineModel<DualListBoxItem[]>('assignedItems', { required: true });
const emit = defineEmits<{(e: 'isCheckedUpdated', itemId: string, isChecked: boolean): void}>();

const state: {
  filterCriteria: string;
} = reactive({
  filterCriteria: ''
});

const items = computed(() => {
  if (props.loading) {
    return [[], []];
  }
  let available = availableItems.value;
  let assigned = assignedItems.value;
  const filterCriteria = state.filterCriteria.trim().toLowerCase();
  if (filterCriteria) {
    available = available.filter(item => item.name.toLowerCase().includes(filterCriteria));
    assigned = assigned.filter(item => item.name.toLowerCase().includes(filterCriteria));
  }
  return [available.sort(sortItems), assigned.sort(sortItems)];
});

watch(() => props.selectedItemId, () => {
  state.filterCriteria = '';
});

function onPrimaryContactChange(event: Event, item: DualListBoxItem) {
  emit('isCheckedUpdated', item.id, event.target?.checked);
}

function onKeyDown(event: KeyboardEvent, item: DualListBoxItem) {
  // prevent PickList from handling checkbox ticks/unticks
  event.stopPropagation();
  onPrimaryContactChange(event, item);
}

function onItemsUpdate(newValue: DualListBoxItem[][]) {
  const [filteredAvailableItems, filteredAssignedItems] = newValue;

  // Update availableItems by adding new filtered items and removing any that are moved to assignedItems
  const updatedAvailableItems = [
    ...availableItems.value
        .filter(item => !filteredAssignedItems.some(filteredItem => filteredItem.id === item.id)),
    ...filteredAvailableItems
  ];

  // Update assignedItems by adding new filtered items and removing any that are moved to availableItems
  const updatedAssignedItems = [
    ...assignedItems.value.filter(item => !filteredAvailableItems.some(filteredItem => filteredItem.id === item.id)),
    ...filteredAssignedItems
  ];

  // Remove duplicates
  const uniqueAvailableItems = mergeItems(updatedAvailableItems, filteredAvailableItems);
  const uniqueAssignedItems = mergeItems(updatedAssignedItems, filteredAssignedItems);
  uniqueAvailableItems.forEach(item => item.isChecked = false);

  availableItems.value = uniqueAvailableItems;
  assignedItems.value = uniqueAssignedItems;
}

// Helper function to merge arrays and remove duplicates
function mergeItems(originalItems: DualListBoxItem[], newItems: DualListBoxItem[]): DualListBoxItem[] {
  const combined = [...originalItems, ...newItems];
  const unique = combined.reduce((acc, item) => {
    if (!acc.some(existingItem => existingItem.id === item.id)) {
      acc.push(item);
    }
    return acc;
  }, [] as DualListBoxItem[]);
  return unique;
}

// Check if item is in assignedItems to show icons
function isItemInAssignedItemsList(item: DualListBoxItem) {
  return assignedItems.value.some(i => i.id === item.id);
}

// Sort items
function sortItems(left: DualListBoxItem, right: DualListBoxItem): number {
  return left.name.localeCompare(right.name);
}
</script>

<style scoped lang="scss">
@import "sass/site/_colours.scss";
.dual-listbox-filter-title {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.p-picklist-item {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icons-container {
    transform: translateY(3px);
    display: flex;
    align-items: center;
    gap: 3px;
  }

  .item-checkbox {
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
  }
}
</style>