<template>
  <div
    :key="componentKey"
    ref="dynamicFormInput"
    class="text-break"
  />
</template>

<script setup lang="ts">
import { FormDataChangedDetails } from '@publicsafety/dynamic-forms';
import DynamicFormFactory from '@publicsafety/dynamic-forms';
import { ref, watch, onMounted } from 'vue';

const emit = defineEmits(['formDataBound', 'update:modelValue']);
defineExpose({
  validate
});
const dynamicFormInput = ref(null);
let dynamicFormInstance: Object | null = null;
const props = defineProps({
  formDefinition: { type: Array, default: () => [] },
  isReadOnly: { type: Boolean },
  i18n: { type: Object, default: () => undefined },
  language: { type: String, default: () => undefined },
  modelValue: { type: Object, required: false, default: () => null },
  disableValidation: { type: Boolean }
});

const isReadOnly = ref(props.isReadOnly);

// Required properties
watch(isReadOnly, value => {
  forceRerender();
});

let componentKey: number = 0;
let formDataBound: boolean = false;

onMounted(renderForm);

function renderForm() {
  const options = {
    containerElement: dynamicFormInput.value,
    components: props.formDefinition,
    data: props.modelValue,
    isReadOnly: isReadOnly.value,
    isViewAsHtml: isReadOnly.value,
    language: props.language,
    i18n: props.i18n,
    dataChangedCallback: onDataChanged
  };

  DynamicFormFactory.renderForm(options)
      .then((instance: any) => {
        dynamicFormInstance = instance;
      });
}

async function onDataChanged(dataChangedDetails: FormDataChangedDetails): Promise<void> {
  // Validate form manually to highlight validation issues
  let isFormValid = true;

  if (!props.disableValidation) {
    isFormValid = await dynamicFormInstance.validate();
  }

  emit('update:modelValue', dataChangedDetails.data, isFormValid);

  // Raise an event once the model has been bound. When a new form is rendered without any initial data,
  // the model used is an empty object. After the form has loaded, the onDataChanged callback is executed
  // and the model will now be populated with a property (with a default value) for each form field.
  if (!formDataBound) {
    formDataBound = true;
    emit('formDataBound');
  }
}

function forceRerender(): void {
  // DynamicForm component doesn't provide an API to rerender component as a readonly therefore the following workaround is used.
  // https://michaelnthiessen.com/force-re-render/
  componentKey += 1;
}

async function validate() {
  return await dynamicFormInstance.validate();
}
</script>

<style lang="scss" scoped>
</style>