<template>
  <div class="file-upload-selector">
    <UploadFilesSelector
      @add-files="onFilesAdded"
    />
    <UploadCctvExportSelector
      @add-files="onFilesAdded"
      @add-folders="onFoldersAdded"
    />
    <UploadZipSelector
      @add-files="onFilesAdded"
      @add-folders="onFoldersAdded"
    />
  </div>
</template>

<script lang="ts" setup>
import UploadFilesSelector from '@/VueComponents/FileUploadSelector/UploadFilesSelector';
import UploadCctvExportSelector from '@/VueComponents/FileUploadSelector/UploadCctvExportSelector';
import UploadZipSelector from '@/VueComponents/FileUploadSelector/UploadZipSelector';
import FileUploadModel from '@/VueComponents/FileUploadSelector/models/fileUploadModel';
import FolderUploadModel from '@/VueComponents/FileUploadSelector/models/folderUploadModel';

const emit = defineEmits<{
  addFiles: [files: FileUploadModel[]],
  addFolders: [folders: FolderUploadModel[]]
}>();

function onFilesAdded(files: FileUploadModel[]) {
  emit('addFiles', files);
}

function onFoldersAdded(folders: FolderUploadModel[]) {
  emit('addFolders', folders);
}
</script>
