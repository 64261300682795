<template>
  <div>
    <div class="file-upload-selector mb-3">
      <FileDropZone
        v-if="isDirectorySupported"
        class="drop-zone-large"
        :upload-parent-item-is-completing="uploadParentItemIsCompleting"
        :upload-parent-item-type="uploadParentItemType"
        :upload-parent-item-is-discarding="uploadParentItemIsDiscarding"
        @added-file-uploads="onFileDropped"
        @add-to-zip-uploads="onFolderDropped"
      >
        <div class="drop-zone-title-container">
          <div class="drop-zone-title hidden-sm hidden-lg hidden-md">
            <span>{{ $localize('FilesAndFoldersTitle') }}</span>
          </div>
          <div class="drop-zone-image">
            <img
              src="/Content/images/individualFiles.svg"
              class="drop-zone-icon"
              alt=""
            >
            <div class="hidden-xs">
              <span class="drop-zone-title">{{ $localize('FilesAndFoldersTitle') }}</span>
              <p class="drop-zone-prompt">
                {{ $localize('FilesUploadPrompt') }}
              </p>
            </div>
          </div>
        </div>

        <div class="drop-zone-button-container">
          <label
            for="fileUploadInput"
            class="mr-1"
          >
            <span
              class="btn btn-primary"
              role="button"
              tabindex="0"
              @keydown="triggerClickOnEnter($event, fileUploadInput)"
            >{{ $localize('SelectFiles') }}</span>
            <input
              id="fileUploadInput"
              ref="fileUploadInput"
              type="file"
              name="files[]"
              multiple="multiple"
              @change="onAddedFileUploads($event, 'SelectFiles')"
            >
          </label>
          <label
            for="folderUploadInput"
            class="ml-1"
          >
            <span
              class="btn btn-default"
              role="button"
              tabindex="0"
              @keydown="triggerClickOnEnter($event, folderUploadInput)"
            >{{ $localize('SelectFolder') }}</span>
            <input
              id="folderUploadInput"
              ref="folderUploadInput"
              type="file"
              name="files[]"
              directory="directory"
              webkitdirectory="webkitdirectory"
              @change="onAddedFileUploads($event, 'SelectFolder')"
            >
          </label>
        </div>
      </FileDropZone>

      <FileDropZone
        v-if="isDirectorySupported"
        :upload-option="'SelectZipFiles'"
        :upload-parent-item-is-completing="uploadParentItemIsCompleting"
        :upload-parent-item-is-discarding="uploadParentItemIsDiscarding"
        :upload-parent-item-type="uploadParentItemType"
        class="drop-zone-large"
        @added-file-uploads="onFileDropped"
      >
        <div class="drop-zone-title-container">
          <div class="drop-zone-title hidden-sm hidden-lg hidden-md">
            <span>{{ $localize('ZipFile') }}</span>
          </div>
          <div class="drop-zone-image">
            <img
              src="Content/images/zipFile.svg"
              class="drop-zone-icon"
              alt=""
            >
            <div class="hidden-xs">
              <span class="drop-zone-title">{{ $localize('ZipFile') }}</span>
              <p class="drop-zone-prompt">
                {{ $localize('ZipFileUploadPrompt') }}
              </p>
            </div>
          </div>
        </div>

        <div class="drop-zone-button-container">
          <label for="zipFileUploadInput">
            <span
              class="btn btn-default"
              role="button"
              tabindex="0"
              @keydown="triggerClickOnEnter($event, zipFileUploadInput)"
            >{{ $localize('SelectZipFile') }}</span>
            <input
              id="zipFileUploadInput"
              ref="zipFileUploadInput"
              type="file"
              name="files[]"
              accept=".zip"
              multiple="multiple"
              @change="onAddedFileUploads($event, 'SelectZipFiles')"
            >
          </label>
        </div>
      </FileDropZone>
      <FileDropZone
        v-else
        class="drop-zone-small"
        :upload-parent-item-is-completing="uploadParentItemIsCompleting"
        :upload-parent-item-is-discarding="uploadParentItemIsDiscarding"
        :upload-parent-item-type="uploadParentItemType"
        @added-file-uploads="onFileDropped"
      >
        <div class="drop-zone-title">
          <span>{{ $localize('FilesAndFoldersTitle') }}</span>
        </div>
        <div class="drop-zone-image-container">
          <img
            src="Content/images/individualFiles.svg"
            class="drop-zone-icon"
            alt=""
          >
        </div>

        <label for="fileUploadInput">
          <span
            class="btn btn-primary"
            role="button"
            tabindex="0"
            @keydown="triggerClickOnEnter($event, fileUploadInput)"
          >{{ $localize('SelectFiles') }}</span>
          <input
            id="fileUploadInput"
            ref="fileUploadInput"
            type="file"
            name="files[]"
            multiple="multiple"
            @change="onAddedFileUploads($event, 'SelectFiles')"
          >
        </label>
      </FileDropZone>
    </div>
    <UploadOptionsWarningModal
      v-if="showUploadOptionsWarningModal"
      :zip-files-count="zipFilesCount"
      :non-zip-files-count="nonZipFilesCount"
      :file-list="fileList"
      :upload-option="uploadOptionSelected"
      @cancel="closeUploadOptionsWarningModal"
      @continue="continueWithContentTypeSelectionModal"
    />
    <ContentTypeSelectionModal
      v-if="showContentTypeSelectionModal"
      :upload-option="uploadOption"
      :file-list="fileList"
      :folders-count="1"
      @cancel="closeContentSelectionModal"
      @upload="upload"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import FileDropZone from '@/VueComponents/FileUploadSelector/FileDropZone.vue';
import logger from '@/Utils/logger';
import UploadOptionsWarningModal from '@/VueComponents/Modals/UploadOptionsWarningModal.vue';
import requestUploadValidation from '@/Validation/requestUploadValidation';
import { UploadOption } from '@/Types/Enums/uploadOption';
import { UploadContentType } from '@/Types/Enums/uploadContentType';
import ContentTypeSelectionModal from '@/VueComponents/Modals/ContentTypeSelectionModal.vue';
import { UploadParentItemType } from '@/Types/Enums/UploadParentItemType';

const emit = defineEmits(['fileUploadsAdded', 'toZipUploadsAdded']);

const props = defineProps<{
  uploadParentItemIsCompleting: boolean,
  uploadParentItemType: UploadParentItemType
  uploadParentItemIsDiscarding: boolean
}>();

const isDirectorySupported = ref(directorySupported());

const fileUploadInput = ref(null);
const zipFileUploadInput = ref(null);
const folderUploadInput = ref(null);
const showUploadOptionsWarningModal = ref(false);
const showContentTypeSelectionModal = ref(false);
let uploadOption: string;
let fileList: File[];
let element: HTMLInputElement;
let zipFilesCount: number;
let nonZipFilesCount: number;

function onAddedFileUploads(event: Event, uploadOptionSelected: string) {
  element = event.target as HTMLInputElement;

  // Allow for browsers that handle the value reset / empty string as a change
  if (element.value === '') {
    return;
  }

  fileList = element.files;
  uploadOption = uploadOptionSelected;

  if (requestUploadValidation.showItemIsCompletingWarning(props.uploadParentItemIsCompleting, props.uploadParentItemType)) {
    return;
  }

  if (requestUploadValidation.showItemIsDiscardingWarning(props.uploadParentItemIsDiscarding, props.uploadParentItemType)) {
    return;
  }

  if (fileList && fileList.length > 0) {
    const zipFiles = requestUploadValidation.getZipFiles(fileList);
    const nonZipFiles = requestUploadValidation.getNonZipFiles(fileList);
    nonZipFilesCount = nonZipFiles.length;
    zipFilesCount = zipFiles.length;
    if (requestUploadValidation.isZipOptionSelected(uploadOptionSelected)) {
      handleZipFilesUpload(nonZipFiles, zipFiles);
    } else {
      handleNonZipFilesUpload(nonZipFiles, zipFiles);
    }
  } else if (fileList) {
    logger.warning('EmptyFolderUploadWarningMessage');
  }
}

function onFileDropped(files: any) {
  emit('fileUploadsAdded', files);
}

function upload(uploadContentType: UploadContentType | undefined) {
  showContentTypeSelectionModal.value = false;
  const isCctv = requestUploadValidation.isFolderOptionSelected(uploadOption) &&
        (uploadContentType === UploadContentType.cctv || uploadContentType === UploadContentType.deviceBackup);
  const isZip = requestUploadValidation.isZipOptionSelected(uploadOption);

  Array.from(fileList)
      .forEach(file => {
        Object.defineProperty(file, isCctv ? 'isCctv' : isZip ? 'isZip' : 'isFile', {
          value: true
        });
        if (uploadContentType !== undefined) {
          Object.defineProperty(file, 'uploadContentType', {
            value: uploadContentType
          });
        }
      });
  if (isCctv) {
    emit('toZipUploadsAdded', fileList);
  } else {
    emit('fileUploadsAdded', fileList);
  }

  // Set to empty string to reset and allow same file to be selected again
  element.value = '';
}

function closeContentSelectionModal() {
  showContentTypeSelectionModal.value = false;
  // Set to empty string to reset and allow same file to be selected again
  element.value = '';
}

function closeUploadOptionsWarningModal() {
  showUploadOptionsWarningModal.value = false;
  // Set to empty string to reset and allow same file to be selected again
  element.value = '';
}

function continueWithContentTypeSelectionModal() {
  showUploadOptionsWarningModal.value = false;
  if (showContentSelectionModal()) {
    showContentTypeSelectionModal.value = true;
    return;
  }
  upload(undefined);
  // Set to empty string to reset and allow same file to be selected again
  element.value = '';
}

function showContentSelectionModal() {
  if (requestUploadValidation.showContentTypeSelectionModal(uploadOption, fileList)) {
    if (requestUploadValidation.isFileOptionSelected(uploadOption)) {
      uploadOption = UploadOption.selectZipFiles;
    }
    return true;
  }
  return false;
}

function triggerClickOnEnter(event: KeyboardEvent, targetElement: HTMLElement) {
  const key = event.key;
  if (key === 'Enter' || key === ' ') {
    event.preventDefault(); // Pressing the "space" key, if propagated will cause some browsers to scroll down
    targetElement.click();
  }
}

function onFolderDropped(fileList) {
  emit('toZipUploadsAdded', fileList);
}

function directorySupported() {
  const tmpInput = document.createElement('input');
  if ('webkitdirectory' in tmpInput ||
      'mozdirectory' in tmpInput ||
      'odirectory' in tmpInput ||
      'msdirectory' in tmpInput ||
      'directory' in tmpInput) {
    return true;
  }

  return false;
}

// Handle upload for 'Zip option'. Only zip files are allowed.
function handleZipFilesUpload(nonZipFiles: File[], zipFiles: File[]) {
  if (requestUploadValidation.isZipOptionSelected(uploadOption)) {
    fileList = zipFiles;
    // When only non-zip file(s) selected - show the warning toaster
    if (fileList.length === 0) {
      logger.warning('NoZipUploadWarningMessage');
      return;
    }
    // When mix of zip and non-zip files selected - show the warning modal
    if (requestUploadValidation.showContainsNonZipWarning(uploadOption, nonZipFiles.length)) {
      showUploadOptionsWarningModal.value = true;
      return;
    } else if (showContentSelectionModal()) {
      showContentTypeSelectionModal.value = true;
      return;
    }
  }
}

// Handle upload for 'Files and folders'. All formats are allowed.
function handleNonZipFilesUpload(nonZipFiles: File[], zipFiles: File[]) {
  // When more than 1 zip file selected - show the warning toaster
  if (nonZipFiles.length === 0 && zipFiles.length > 1) {
    logger.warning('ZipUploadWarningMessage');
    return;
  }
  // When files or folders contains zip files - show the warning modal
  if (requestUploadValidation.showContainsZipWarning(uploadOption, fileList)) {
    fileList = nonZipFiles;
    showUploadOptionsWarningModal.value = true;
    return;
  // When only 1 zip file selected - proceed with Content Selection modal
  } else if (showContentSelectionModal()) {
    showContentTypeSelectionModal.value = true;
    return;
  }
  // Proceed with direct upload for files
  upload(undefined);
}
</script>

<style>
</style>