<template>
  <div
    v-if="allUploadsComplete"
    aria-live="assertive"
    class="sr-only"
  >
    <span>{{ $localize('AllUploadsComplete') }}</span>
  </div>

  <div
    v-if="uploadsCount"
    class="dropdown"
  >
    <div class="uploading-nav-item__status">
      <span
        class="far fa-circle-notch fa-spin"
        aria-hidden="true"
      />
      <span
        class="hidden-xs ml-3"
        aria-hidden="true"
      >
        {{ $localize('UploadingItems', { itemCount: uploadsCount, percent: allUploadsProgress }) }}
      </span>
      <span
        class="visible-xs-inline"
        aria-hidden="true"
      >
        {{ $localize('Uploading') }}
      </span>
      <span class="sr-only">
        {{ $localize('UploadingItems', { itemCount: uploadsCount, percent: allUploadsProgress }) }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import uploadManager from '@/Utils/uploadManager';

// TODO: to retire when Ko is retired
const uploadsCount = ref(0);
uploadManager.uploadsCount.subscribe(count => {
  uploadsCount.value = count;
});

const allUploadsProgress = ref(0);
uploadManager.allUploadsProgress.subscribe(progress => {
  allUploadsProgress.value = progress;
});


const allUploadsComplete = ref(false);
uploadManager.allUploadsComplete.subscribe(value => {
  allUploadsComplete.value = value;
});
</script>